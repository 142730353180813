<script>
  import RadioOption from "./RadioOption.svelte";
  import NickelDropdown from "../common/NickelDropdown.svelte";
  import CheckboxOption from "./CheckboxOption.svelte";

  export let ticketingSelection = "";
  export let setTicketingSelection = () => null;
  const ticketingOptions = ["Paciolan", "Ticketmaster", "Neulion", "Other"];

  export let CRMSelections = [];
  export let toggleCRMSelection = () => null;

  export let dataWarehouseSelection = "";
  export let setDataWarehouseSelection = () => null;
  const dataWarehouseOptions = ["Yes", "No", "I'm not sure 😅"];
</script>

<style>
  .form {
    margin-top: 20px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }

  .checkbox {
    margin-bottom: 24px;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    .form {
      margin: 20px 10px 0;
    }
  }
  @media only screen and (max-width: 372px) {
    .form {
      padding: 30px 10px;
    }
  }
</style>

<div class="form">
  <div class="dropdown-item">
    <div class="title">Who is your ticketing vendor?</div>
    <NickelDropdown
      value={ticketingSelection}
      options={ticketingOptions}
      onSelect={setTicketingSelection} />
  </div>
  <div class="checkbox-item">
    <div class="title">Which CRM(s) do you use in development?</div>
    <div class="checkbox">
      <CheckboxOption
        label="Paciolan"
        selections={CRMSelections}
        toggleSelection={toggleCRMSelection} />
      <CheckboxOption
        label="Salesforce"
        selections={CRMSelections}
        toggleSelection={toggleCRMSelection} />
      <CheckboxOption
        label="Blackbaud"
        selections={CRMSelections}
        toggleSelection={toggleCRMSelection} />
      <CheckboxOption
        label="Evertrue"
        selections={CRMSelections}
        toggleSelection={toggleCRMSelection} />
      <CheckboxOption
        label="Other"
        selections={CRMSelections}
        toggleSelection={toggleCRMSelection} />
    </div>
  </div>
  <div class="dropdown-item">
    <div class="title">Do you currently have a data warehouse in place?</div>
    <NickelDropdown
      value={dataWarehouseSelection}
      options={dataWarehouseOptions}
      onSelect={setDataWarehouseSelection} />
  </div>
</div>
