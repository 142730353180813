<script>
  import { Router, Route } from "svero";
  import Start from "./Start/Start.svelte";
  import ContactUs from "./ContactUs/ContactUs.svelte";
  import Legal from "./Legal/Legal.svelte";
  import Privacy from "./Privacy/Privacy.svelte";
  import Home from "./Home/Home.svelte";

  let route = window.location.pathname.replace(/^\//, "");
</script>

<style>

</style>

<Router>
  <Route path="/start" component={Start} />
  <Route path="/contact" component={ContactUs} />
  <Route path="/legal" component={Legal} />
  <Route path="/privacy" component={Privacy} />
  <Route exact path="" fallback component={Home} />
</Router>
