<script>
  import Nav from "../common/Nav.svelte";
  import Footer from "../common/Footer.svelte";

  let clientWidth;

  $: mobile = clientWidth < 882;
</script>

<style>
  .wrapper {
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fafafa;
  }

  .content {
    width: 100%;
    padding: 0 162px;
    box-sizing: border-box;
  }

  .title {
    margin-top: 80px;
    margin-bottom: 50px;
    font-size: 26px;
    line-height: 24px;
    font-weight: 700;
  }

  .section-text,
  .section-header {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .section-header {
    margin-bottom: 0;
  }

  .content div:last-child {
    margin-bottom: 200px;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 1248px) {
    .content {
      padding: 0 72px;
    }
  }
  @media only screen and (max-width: 882px) {
    .content {
      padding: 0 20px;
    }
  }
</style>

<div class="wrapper" bind:clientWidth>
  <Nav lightBackground={true} {clientWidth} />
  <div class="content">
    <h3 class="title">Nickel, Inc. Service Agreement</h3>
    <div class="section-text">
      This Service Agreement is a part of the Service Order between Nickel, Inc.
      ("Nickel") and the Subscriber named on the Service Order.
    </div>
    <div class="section-header">1. DEFINITIONS</div>
    <div class="section-text">
      1.1 "Affiliate" means any entity that, directly or indirectly, controls,
      is controlled by or is under common control with such entity (but only for
      so long as such control exists), where "control" means the ownership of
      more than 50% of the outstanding shares or securities representing the
      right to vote in the election of directors or other managing authority of
      such entity.
    </div>
    <div class="section-text">
      1.2 "Agreement" means the Service Order and this Service Agreement.
    </div>
    <div class="section-text">
      1.3 "Authorized Users" means those specified employees of Subscriber, its
      Affiliates and Permitted Third Parties for which Subscriber has created
      unique user names and passwords.
    </div>
    <div class="section-text">
      1.4 "Client Software" means software components to be installed on
      Subscriber or its Affiliate's computer systems, including but not limited
      to Nickel-licensed third party libraries.
    </div>
    <div class="section-text">
      1.5 "Confidential Information" means non-public business information,
      know-how, and trade secrets in any form, including information regarding a
      party's product plans, terms of this Agreement, and any other information
      a reasonable person should understand to be confidential, which is
      disclosed by or on behalf of either party or its Affiliates to the other
      party or its Affiliates, directly or indirectly, in writing, orally, or by
      inspection of tangible objects (including documents, prototypes, samples,
      plant, and equipment), and whether such information is disclosed before or
      after the Effective Date specified on the Service Order. Confidential
      Information includes this Agreement and its terms. "Confidential
      Information" excludes information that (a) was publicly known and made
      generally available in the public domain prior to the time of disclosure
      by the disclosing party; (b) becomes publicly known and made generally
      available after disclosure by the disclosing party to the receiving party
      through no action or inaction of the receiving party; (c) is already in
      the possession of the receiving party at the time of disclosure by the
      disclosing party, as shown by the receiving party's files and records; (d)
      is obtained by the receiving party from a third party without a breach of
      the third party's obligations of confidentiality; or (e) is independently
      developed by the receiving party without use of or reference to the
      disclosing party's Confidential Information, as shown by documents and
      other competent evidence in the receiving party's possession.
    </div>
    <div class="section-text">
      1.6 "Documentation" means Nickel-provided user documentation, in all
      forms, relating to the Service (e.g., user manuals, on-line help files).
    </div>
    <div class="section-text">
      1.7 "Permitted Third Party" means any entity under contract with
      Subscriber or its Affiliates who needs to access the Service to perform
      its obligations to Subscriber or its Affiliates and who is not a
      competitor of Nickel.
    </div>
    <div class="section-text">
      1.8 "Professional Service Fees" means the fees for Professional Services
      charged on a time and materials basis at Nickel's then-current rates,
      unless otherwise specified on the Service Order.
    </div>
    <div class="section-text">
      1.9 "Professional Services" means the professional services specified on
      the Service Order, potentially including but not limited to implementation
      services, consulting and training services.
    </div>
    <div class="section-text">
      1.10 "Scope Limitations" means any limitations on use of the Service
      specified on the Service Order.
    </div>
    <div class="section-text">
      1.11 "Sensitive Personal Information" is given the meaning under relevant
      privacy or data protection laws relating to this term or any similar term
      (such as "sensitive personal data") used in the laws, including, without
      limitation, the Gramm-Leach-Bliley Act, Health Insurance Portability and
      Accountability Act of 1996 (including Protected Health Information), US
      Children's Online Privacy Protection Act, and Family Educational Rights
      and Privacy Act. Alternatively, where no such laws apply, "Sensitive
      Personal Information" means personal financial information (including
      personal financial account information), sexual orientation, personal
      medical or health information, personal information of children under
      thirteen, personal education records, and social security, national
      identity, national insurance, and similar personal identifiers.
    </div>
    <div class="section-text">
      1.12 "Service" means the web-based service identified on the Service
      Order, as may be modified by Nickel from time to time in its sole
      discretion.
    </div>
    <div class="section-text">
      1.13 "Service Order" means the Nickel Service Order executed by Subscriber
      and Nickel.
    </div>
    <div class="section-text">
      1.14 "Subscriber" means the customer named on the Service Order.
    </div>
    <div class="section-text">
      1.15 "Subscriber Data" means any data uploaded into the Service, or
      otherwise provided for processing by the Service, by or on behalf of
      Subscriber and its Affiliates.
    </div>
    <div class="section-text">
      1.16 "Subscription Fees" means the fees for the Service specified on the
      Service Order.
    </div>
    <div class="section-text">
      1.17 "Taxes" means local, state, federal or foreign taxes (e.g.,
      value-added, sales or use taxes), fees, duties, or other governmental
      charges resulting from this Agreement, excluding taxes based on Nickel's
      net income or property.
    </div>
    <div class="section-text">
      1.18 "Technical Support Services" means Nickel's then-current technical
      support services offering, as described at
      http://nickelteam.com/support-package.html.
    </div>
    <div class="section-header">2. USE OF THE SERVICE</div>
    <div class="section-text">
      2.1 Use of the Service. Subject to the terms and conditions of this
      Agreement, Nickel grants to Subscriber and its Affiliates a limited,
      worldwide, non-exclusive, non-transferable (except as explicitly permitted
      in this Agreement) right during the term of this Agreement to use the
      Service solely in connection with its internal business operations. The
      rights of Subscriber and its Affiliates to use the Service are subject to
      the Scope Limitations and contingent upon compliance with the Scope
      Limitations and this Agreement. As part of the Service, Nickel may provide
      Subscriber and its Affiliates with Client Software, which Subscriber and
      its Affiliates may install on its computer system and use solely to upload
      data into the Service.
    </div>
    <div class="section-text">
      2.2 Use of the Documentation. Subject to the terms and conditions of this
      Agreement, Nickel grants to Subscriber and its Affiliates a limited,
      worldwide, non-exclusive, non-transferable (except as explicitly permitted
      in in this Agreement) right during the term of this Agreement to
      reproduce, without modification, and internally use a reasonable number of
      copies of the Documentation solely in connection with use of the Service
      in accordance with this Agreement.
    </div>
    <div class="section-text">
      2.3 Use Restrictions. Except as otherwise explicitly provided in this
      Agreement or as may be expressly permitted by applicable law, Subscriber
      will not, and will not permit or authorize its Affiliates or third parties
      to: (a) rent, lease, or, except as explicitly set forth in this Agreement,
      otherwise permit third parties to use the Service, Client Software or
      Documentation; (b) use the Service to provide services to third parties
      (e.g., as a service bureau); (c) circumvent or disable any security or
      other technological features or measures of the Service, or attempt to
      probe, scan or test the vulnerability of a network and or system or to
      breach security or authentication measures; (d) upload or provide for
      processing any information or materials that are illegal, defamatory,
      offensive, abusive, obscene, or that violate privacy or intellectual
      property rights; (e) use the Service to harm, threaten or harass another
      person or organization; or (f) send, store or distribute any viruses,
      worms, Trojan horses, or other malware component harmful to a network or
      system. Subscriber will not copy, reproduce, modify, translate, enhance,
      decompile, disassemble, reverse engineer or create derivative works of any
      Client Software or provide, disclose or make any Client Software available
      to any third party, except that Subscriber may make one copy of Client
      Software solely for backup and archival purposes. Subscriber will neither
      alter nor remove any trademark, copyright notice or other proprietary
      rights notices that may appear on any part of the Documentation or any
      Client Software and will include all such notices on any copies.
      Subscriber will ensure that its Affiliates, Permitted Third Parties and
      Authorized Users comply with this Agreement and will be directly and fully
      responsible to Nickel for their conduct and any breach of this Agreement
      by them.
    </div>
    <div class="section-text">
      2.4 Compliance with Laws. Each party will perform its respective rights
      and obligations under this Agreement in compliance with all applicable
      laws and regulations, including but not limited to U.S. and foreign export
      control laws and regulations.
    </div>
    <div class="section-text">
      2.5 Authorized Users Only. This Agreement restricts the use of the Service
      to Authorized Users, up to the number of users specified on the Service
      Order. An Authorized User account must not be shared among users.
      Additional Authorized Users may be added by paying the applicable fees to
      Nickel at Nickel's then-current rate or as otherwise specified on the
      Service Order. The Authorized Users who are employees of Permitted Third
      Parties may access and use the Service solely to perform the Permitted
      Third Party's contractual obligations to Subscriber.
    </div>
    <div class="section-text">
      2.6 Protection against Unauthorized Use. Subscriber will, and will ensure
      that its Affiliates, Permitted Third Parties and Authorized Users use
      reasonable efforts to prevent any unauthorized use of the Service and
      Documentation, and Subscriber will immediately notify Nickel in writing of
      any unauthorized use that comes to Subscriber's attention. If there is
      unauthorized use by anyone who obtained access to the Service, Client
      Software or Documentation directly or indirectly through Subscriber, its
      Affiliates, a Permitted Third Party or an Authorized User, Subscriber will
      take all steps reasonably necessary to terminate the unauthorized use.
      Subscriber will cooperate and assist with any actions taken by Nickel to
      prevent or terminate unauthorized use of the Service, Client Software or
      Documentation. Nickel may, at its expense and no more than once every 12
      months with reasonable notice, appoint its own personnel or an independent
      third party to verify that Subscriber's use of the Service complies with
      the terms of this Agreement.
    </div>
    <div class="section-header">
      3. SERVICE AVAILABILITY; DATA SECURITY; PROFESSIONAL AND SUPPORT SERVICES
    </div>
    <div class="section-text">
      3.1 Service Availability. Nickel performs and maintains regular database
      backups according to the retention policy appropriate for the particular
      system. Nickel incorporates database and system maintenance operations and
      processes designed to address data consistency, indexing, and integrity
      requirements that also help improve query performance. Nickel has
      implemented and will maintain commercially reasonable measures intended to
      avoid unplanned Service interruptions. Nickel will use commercially
      reasonable efforts to notify Subscriber in advance of planned Service
      interruptions. In the event of an unplanned Service interruption,
      Subscriber may contact Nickel for Technical Support Services, as described
      in this Agreement. The Service depends on the availability of the
      Subscriber Data from Subscriber and third party data providers. Subscriber
      shall make the Subscriber Data available that is necessary for Nickel to
      provide the Service, or stated timelines may not be met. Nickel is not
      responsible for, and does not undertake to verify, the accuracy or
      completeness of Subscriber Data.
    </div>
    <div class="section-text">
      3.2 Data Security. Nickel has implemented and will maintain appropriate
      physical, electronic, and managerial procedures intended to protect
      against the loss, misuse, unauthorized access, alteration or disclosure of
      Subscriber Data. These measures include encryption of Subscriber Data
      during transmission to the Service, and encryption of backups of
      Subscriber Data and authentication credentials at rest. Nickel will
      promptly notify Subscriber of any unauthorized access to, or use of,
      Subscriber Data that comes to Nickel's attention. In the event of any
      unauthorized disclosure of Subscriber Data resulting from Subscriber's use
      of the Service, Nickel and Subscriber will promptly investigate the cause
      of such unauthorized disclosure, and will work together in good faith to
      take the steps reasonably necessary to prevent any future reoccurrence and
      to comply with applicable data breach notification laws.
    </div>
    <div class="section-text">
      Subscriber acknowledges that use of the Service will involve transmission
      of Subscriber Data and other communications over the Internet and other
      networks, and that such transmissions could potentially be accessed by
      unauthorized parties when communicated across the Internet or other
      networks. Nickel is not responsible for any Subscriber Data which is
      delayed, lost, altered, intercepted or stored during transmission across
      networks not owned or operated by Nickel and its subcontractors, including
      but not limited to the Internet and Subscriber's local network. Subscriber
      shall protect its Authorized User login names and passwords from access or
      use by unauthorized parties, and is solely responsible for its failure to
      do so. Subscriber must promptly notify Nickel of any suspected security
      breach at security@Nickel.com.
    </div>
    <div class="section-text">
      3.3 Professional Services. Subscriber may contract with Nickel to perform
      Professional Services. The specific details of the Professional Services
      to be performed will be determined on a per-project basis, and the details
      for each project will be described on the Service Order. Unless otherwise
      specified in the applicable Service Order, any unused portion of the
      Professional Services will expire and may not be carried over after 12
      months from the Service Order effective date.
    </div>
    <div class="section-text">
      3.4 Changes to Professional Services. Subscriber may reasonably request in
      writing that revisions be made with respect to the Professional Services
      set forth on the Service Order. If Subscriber's requested revisions
      materially increase the scope of the Professional Services or the effort
      required to perform the Professional Services under the Service Order,
      then Nickel will deliver to Subscriber a written proposal reflecting
      Nickel's reasonable determination of the revised Professional Services,
      delivery schedule, and payment schedule, if any, that will apply to the
      requested revisions. If Subscriber approves the proposal, then the parties
      will execute the proposal as an amendment to the Service Order. Otherwise,
      the then-existing Service Order will remain in full force and effect, and
      Nickel will have no further obligation with respect to the relevant change
      requests.
    </div>
    <div class="section-text">
      3.5 Project Management. Each party will designate a project leader as a
      single point of contact within each party's organization to manage the
      Professional Services described on the Service Order. The project leaders
      will meet as necessary to manage the Professional Services to be performed
      as indicated on the Service Order. Disputes will be escalated to more
      senior executives if the project leaders are unable to resolve a problem.
    </div>
    <div class="section-text">
      3.6 Technical Support Services. Nickel will provide Subscriber with
      Technical Support Services so long as Subscriber is current in payment of
      the Subscription Fees. Subscriber is responsible for providing support to
      Permitted Third Parties.
    </div>
    <div class="section-text">
      3.7 Subscriber's Responsibilities. Subscriber will provide assistance,
      cooperation, information, equipment, data, a suitable work environment,
      and resources reasonably necessary to enable Nickel to perform the
      Professional Services and Technical Support Services. Subscriber
      acknowledges that Nickel's ability to provide Professional Services as
      described on the Service Order and Technical Support Services may be
      affected if Subscriber does not provide reasonable assistance as set forth
      above.
    </div>
    <div class="section-header">4. FEES AND PAYMENT</div>
    <div class="section-text">
      4.1 Fees and Payment Terms. Unless otherwise specified on the Service
      Order, the Subscription Fees for the initial subscription term and
      Professional Service Fees are due upon execution of the Service Order.
      After the initial subscription term, Subscription Fees will be invoiced
      annually at the then-current rate for the Service or as otherwise
      specified on the Service Order, 30 days in advance of the start of each
      renewal period. Fees for additional Service quantities and Professional
      Services will be invoiced at the time of order, unless otherwise agreed in
      writing by the parties. Subscriber will pay all undisputed amounts in full
      within 30 days after the invoice date. The charges in an invoice will be
      considered accepted by Subscriber unless Nickel is notified of a dispute
      in writing within 15 days of the date of the invoice. Any amount not paid
      when due will be subject to finance charges equal to 1.5% of the unpaid
      balance per month or the highest rate permitted by applicable usury law,
      whichever is less, determined and compounded monthly from the date due
      until the date paid. Subscriber will reimburse any costs or expenses
      (including, but not limited to, reasonable attorneys' fees) incurred by
      Nickel to collect any amount that is not paid when due. Amounts due from
      Subscriber under this Agreement may not be withheld or offset by
      Subscriber against amounts due to Subscriber for any reason. Unless
      expressly provided otherwise in a Service Order, all amounts payable under
      this Agreement are denominated in United States dollars, and Subscriber
      will pay all such amounts in United States dollars.
    </div>
    <div class="section-text">
      4.2 Taxes. The fees stated on the Service Order do not include Taxes.
      Subscriber is responsible for paying all applicable Taxes. If Nickel
      determines it has the legal obligation to pay or collect Taxes, Nickel
      will add such Taxes to the applicable invoice and Subscriber will pay such
      Taxes, unless Subscriber provides Nickel with a valid tax exemption
      certificate from the appropriate taxing authority. If a taxing authority
      subsequently pursues Nickel for unpaid Taxes for which Subscriber is
      responsible under this Agreement and which were not paid by Subscriber to
      Nickel, Nickel may invoice Subscriber and Subscriber will pay such Taxes
      to Nickel or directly to the taxing authority, plus all applicable
      interest, penalties and fees.
    </div>
    <div class="section-text">
      4.3 Payment Processing Payment processing services on Nickel are provided
      by Stripe and are subject to the Stripe Connected Account Agreement, which
      includes the Stripe Terms of Service(collectively, the “Stripe Services
      Agreement”). By agreeing to these terms or continuing to operate as a
      customer on Nickel, you agree to be bound by the Stripe Services
      Agreement, as the same may be modified by Stripe from time to time. As a
      condition of Nickel enabling payment processing services through Stripe,
      you agree to provide Nickel accurate and complete information about you
      and your business, and you authorize Nickel to share it and transaction
      information related to your use of the payment processing services
      provided by Stripe.
    </div>
    <div class="section-header">5. TERM AND TERMINATION</div>
    <div class="section-text">
      5.1 Term. This Agreement will commence upon the Effective Date specified
      on the Service Order and continue for the initial subscription term
      specified on the Service Order unless this Agreement is terminated earlier
      in accordance with the terms of this Agreement. This Agreement will
      automatically renew for additional successive one-year terms unless at
      least 30 days before the end of the then-current term either party
      provides written notice to the other party that it does not intend to
      renew.
    </div>
    <div class="section-text">
      5.2 Termination for Material Breach. Either party may terminate this
      Agreement if the other party does not cure its material breach of this
      Agreement within 30 days of receiving written notice of the material
      breach from the non-breaching party. A breach of this Agreement by an
      Affiliate of Subscriber, an Authorized User or Permitted Third Party will
      be treated as a breach by Subscriber. Termination in accordance with this
      section will take effect when the breaching party receives written notice
      of termination from the non-breaching party, which notice must not be
      delivered until the breaching party has failed to cure its material breach
      during the 30-day cure period. If Subscriber fails to timely pay any
      Subscription Fees or Professional Services Fees, Nickel may, without
      limitation to any of its other rights or remedies, suspend performance of
      the Service, Professional Services and Technical Support Services until it
      receives all amounts due, or may terminate this Agreement pursuant to this
      section.
    </div>
    <div class="section-text">
      5.3 Post-Termination Obligations. If this Agreement is terminated for any
      reason, (a) Nickel will have no obligation to provide or perform any
      Service, Professional Services or Technical Support Services after the
      effective date of the termination, (b) Subscriber will immediately pay to
      Nickel any Subscription Fees, Professional Services Fees, and other
      amounts that have accrued prior to the effective date of the termination,
      (c) any and all liabilities accrued prior to the effective date of the
      termination will survive, (d) Subscriber will provide Nickel with a
      written certification signed by an authorized Subscriber representative
      certifying that all use of the Service, Client Software and Documentation
      by Subscriber, its, Affiliates, Permitted Third Parties and Authorized
      Users has been discontinued and the Client Software has been de-installed
      from Subscriber and its Affiliate's computer systems, and (e) Sections 4,
      5.3, 6, 7.5, 9, 10 and 11 will survive termination. If this Agreement is
      terminated by Nickel for Subscriber's uncured material breach or by
      Subscriber other than as a result of a material, uncured breach by Nickel,
      Subscriber will pay to Nickel the amounts due under the applicable Service
      Order for the remainder of the then-current term. If Subscriber terminates
      this Agreement for Nickel's uncured material breach, Nickel shall provide
      Subscriber a pro-rata refund of all prepaid but unused Subscription Fees
      for the remainder of the then-current term.
    </div>
    <div class="section-header">
      6. INTELLECTUAL PROPERTY AND CONFIDENTIAL INFORMATION
    </div>
    <div class="section-text">
      6.1 Service and Documentation. Nickel retains all right, title, and
      interest in and to the Service, Client Software and Documentation and all
      related intellectual property rights, including without limitation any
      modifications, updates, customizations, cards, apps, or other add-ons (all
      of which will be considered Nickel's Confidential Information), but
      excluding Subscriber Data. Subscriber's rights to use the Service,
      Documentation, and Client Software are limited to those expressly set
      forth in this Agreement. All other rights in and to the Service, Client
      Software and Documentation are reserved by Nickel.
    </div>
    <div class="section-text">
      6.2 Subscriber Data. Subscriber Data is Subscriber's property and
      Confidential Information. Subscriber grants Nickel a non-exclusive,
      worldwide, royalty-free license to use, copy, transmit, sub-license,
      index, store, aggregate and display Subscriber Data solely as required to
      provide or perform the Service, Technical Support Services and
      Professional Services, and to publish, display, and distribute anonymous
      information derived from Subscriber Data and from Subscriber's use of the
      Service for purposes of improving Nickel's products and services, and
      developing and distributing benchmarks and similar reports, provided that
      such aggregated data is not identified or identifiable as originating with
      or associated with Subscriber or any individual person.
    </div>
    <div class="section-text">6.3 Confidential Information.</div>
    <div class="section-text">
      (a) The party receiving Confidential Information hereunder agrees to take
      reasonable steps, at least substantially equivalent to the steps it takes
      to protect its own proprietary information, but not less than reasonable
      care, to prevent the unauthorized duplication or disclosure of the
      Confidential Information to third parties without the disclosing party's
      prior written consent. The receiving party may disclose the disclosing
      party's Confidential Information to the receiving party's employees or
      agents who reasonably need to have access to such information to perform
      the receiving party's obligations under this Agreement, and who will treat
      such Confidential Information under the terms of this Agreement. Provided
      that such Permitted Third Party is bound by obligations of confidentiality
      and nonuse no less restrictive than the terms of this Agreement,
      Subscriber may disclose Nickel's Confidential Information to a Permitted
      Third Party solely to the extent required for such Permitted Third Party
      to be able to access and use the Service pursuant to this Agreement.
      Nickel may also disclose this Agreement to actual and potential investors
      and funding sources who agree to hold it in confidence.
    </div>
    <div class="section-text">
      (b) The receiving party may disclose the disclosing party's Confidential
      Information if required by law so long as the receiving party gives the
      disclosing party prompt written notice of the requirement prior to the
      disclosure and reasonable assistance in limiting disclosure or obtaining
      an order protecting the information from public disclosure.
    </div>
    <div class="section-text">
      (c) Upon written request of the disclosing party, or in any event upon any
      termination or expiration of this Agreement, the receiving party will
      return to the disclosing party or destroy all materials, in any medium, to
      the extent containing or reflecting any of the disclosing party's
      Confidential Information.
    </div>
    <div class="section-text">
      (d) Following expiration or termination of this Agreement, Nickel will
      purge the Subscriber Data and the Subscriber's Service environment from
      Nickel's systems.
    </div>
    <div class="section-text">
      (e) This section will survive for 3 years following expiration or
      termination of this Agreement, except that Confidential Information that
      constitutes a trade secret of the disclosing party will continue to be
      subject to the terms of this Agreement for as long as such information
      remains a trade secret under applicable law.
    </div>
    <div class="section-text">
      6.4 Trademarks. This Agreement does not authorize Subscriber to use
      Nickel's name or any of its trademarks or service marks. Nickel may only
      use Subscriber's name, trademarks and service marks solely to the extent
      necessary to fulfill its obligations under this Agreement or as otherwise
      explicitly authorized in this Agreement or a Service Order.
    </div>
    <div class="section-text">
      6.5 Feedback and Other Content. The Nickel Service may permit Subscriber,
      its Affiliates, Authorized Users and Permitted Third Parties to submit
      feedback, user community contributions and comments, technical support
      information, suggestions, enhancement requests, recommendations, messages,
      and other content relating to use and operation of the Service. Subscriber
      grants to Nickel a royalty-free, fully paid, non-exclusive, perpetual,
      irrevocable, worldwide, transferable license to display, use, copy,
      modify, publish, perform, translate, create derivative works from,
      sublicense, distribute and otherwise exploit such content without
      restriction.
    </div>
    <div class="section-header">7. WARRANTIES AND DISCLAIMER</div>
    <div class="section-text">
      7.1 Mutual Warranties. Each party represents and warrants to the other
      that: (a) the Service Order has been duly executed and delivered and this
      Agreement constitutes a valid and binding agreement enforceable against
      such party in accordance with its terms; (b) no authorization or approval
      from any third party is required in connection with such party's execution
      and delivery of the Service Order, or performance of this Agreement; and
      (c) the execution and delivery of the Service Order, and performance of
      this Agreement, does not violate the laws of any jurisdiction or the terms
      or conditions of any other agreement to which it is a party or by which it
      is otherwise bound.
    </div>
    <div class="section-text">
      7.2 Nickel Warranty. Nickel warrants that the Service as delivered to
      Subscriber will materially conform to the applicable Service Order, during
      the term of the Service Order. Subscriber must notify Nickel of a claim
      under this warranty within 30 days of the date on which the condition
      giving rise to the claim first appeared. Nickel further warrants that it
      will perform Professional Services in a professional and workmanlike
      manner in accordance with the Service Order. To the extent permitted by
      law, Subscriber's sole and exclusive remedy arising out of or in
      connection with a breach of the warranties in this section is limited to
      correction of the non-conforming Service or re-performance of the
      Professional Service, as applicable, or if correction or re-performance is
      not commercially reasonable, termination of the applicable Service Order
      and a refund of any prepaid unused fees for the applicable Service or
      Professional Services.
    </div>
    <div class="section-text">
      7.3 Sensitive Personal Information. Subscriber represents and warrants
      that neither it nor any Authorized User will upload into the Service, or
      otherwise provide for processing by the Service, any Sensitive Personal
      Information. Subscriber further represents and warrants that it and any
      Permitted Third Party has complied with all applicable laws, regulations,
      self-regulatory guidelines, and its privacy policy with respect to the
      collection, transfer, and use of any personal information in connection
      with the Service, including without limitation proper disclosure and
      obtaining all required consents from each individual to transfer that
      personal information to Nickel.
    </div>
    <div class="section-text">
      7.4 Third Party Products. Any third party products that are provided by
      Nickel in connection with the Service or Professional Services and that
      are identified as such in the applicable Service Order are provided
      pursuant to the terms of the applicable third party agreement, and
      Subscriber's use of any such third party product constitutes agreement to
      comply with the terms of the applicable third party agreement. Nickel
      assumes no responsibility for, and specifically disclaims any liability or
      obligation with respect to, any third party products.
    </div>
    <div class="section-text">
      7.5 Disclaimer. EXCEPT FOR THE EXPRESS REPRESENTATIONS AND WARRANTIES
      STATED IN THIS SECTION, NEITHER PARTY MAKES ANY ADDITIONAL REPRESENTATION
      OR WARRANTY OF ANY KIND WHETHER EXPRESS, IMPLIED (EITHER IN FACT OR BY
      OPERATION OF LAW), OR STATUTORY, AS TO ANY MATTER WHATSOEVER. Nickel
      EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
      A PARTICULAR PURPOSE, QUALITY, ACCURACY, TITLE, AND NON-INFRINGEMENT.
      Nickel DOES NOT WARRANT AGAINST INTERFERENCE WITH THE ENJOYMENT OF THE
      SERVICE, CLIENT SOFTWARE, OR DOCUMENTATION. Nickel DOES NOT WARRANT THAT
      THE SERVICE, CLIENT SOFTWARE OR DOCUMENTATION IS ERROR-FREE OR THAT
      OPERATION OR USE OF THE SERVICE, CLIENT SOFTWARE OR DOCUMENTATION WILL BE
      SECURE OR UNINTERRUPTED. Nickel EXERCISES NO CONTROL OVER AND EXPRESSLY
      DISCLAIMS ANY LIABILITY ARISING OUT OF OR BASED UPON THE RESULTS OF USE OF
      THE SERVICE, CLIENT SOFTWARE AND DOCUMENTATION.
    </div>
    <div class="section-text">
      7.6 High-Risk Activities. The SERVICE IS not designed or licensed for use
      in hazardous environments requiring failsafe controls, including without
      limitation operation of nuclear facilities, aircraft navigation or
      communications systems, air traffic control, and life support or weapons
      systems, in which the failure of the SERVICE OR SOFTWARE could lead to
      death, personal injury, or severe physical or environmental damage. Nickel
      specifically disclaims any express or implied warranty of fitness for such
      high-risk activities.
    </div>
    <div class="section-header">8. INTELLECTUAL PROPERTY INFRINGEMENT</div>
    <div class="section-text">
      8.1 Defense of Infringement Claims. Nickel will, at its expense, either
      defend Subscriber from or settle any claim, proceeding, or suit brought by
      a third party ("Claim") against Subscriber alleging that its use of the
      Service infringes or misappropriates any patent, copyright, trade secret,
      trademark, or other intellectual property right. Subscriber shall (a) give
      Nickel prompt written notice of the Claim; (b) grant Nickel full and
      complete control over the defense and settlement of the Claim; (c) provide
      assistance in connection with the defense and settlement of the Claim as
      Nickel may reasonably request; and (d) comply with any settlement or court
      order made in connection with the Claim (e.g., relating to the future use
      of any infringing Service). Subscriber will not defend or settle any Claim
      under this Section 8.1 without Nickel's prior written consent. Subscriber
      may participate in the defense of the Claim at its own expense and with
      counsel of its own choosing, subject to Nickel's sole control over the
      defense and settlement of the Claim as provided above.
    </div>
    <div class="section-text">
      8.2 Indemnification of Infringement Claims. Nickel will indemnify
      Subscriber and its Affiliates from and pay (a) all damages, costs, and
      attorneys' fees finally awarded against Subscriber and its Affiliates in
      any Claim under Section8.1; (b) all out-of-pocket costs (including
      reasonable attorneys' fees) reasonably incurred by Subscriber in
      connection with the defense of a Claim under Section8.1 (other than
      attorneys' fees and costs incurred without Nickel's consent after Nickel
      has accepted defense of the Claim and expenses incurred pursuant to the
      last sentence of Section 8.1); and (c) all amounts that Nickel agrees to
      pay to any third party to settle any Claim under Section 8.1.
    </div>
    <div class="section-text">
      8.3 Exclusions from Obligations. Nickel will have no obligation under this
      Section 8 for any infringement or misappropriation to the extent that it
      arises out of or is based upon (a) use of the Service in combination with
      other products or services if such infringement or misappropriation would
      not have arisen but for such combination; (b) the Service is provided to
      comply with designs, requirements, or specifications required by or
      provided by or on behalf of Subscriber, if the alleged infringement or
      misappropriation would not have arisen but for the compliance with such
      designs, requirements, or specifications; (c) use of the Service by
      Subscriber, any Authorized User, or any Permitted Third Party outside the
      scope of the rights granted in this Agreement; (d) failure of Subscriber,
      any Authorized User or any Permitted Third Party to use the Service in
      accordance with instructions provided by Nickel, if the infringement or
      misappropriation would not have occurred but for such failure; or (e) any
      modification of the Service not made or authorized in writing by Nickel
      where such infringement or misappropriation would not have occurred absent
      such modification.
    </div>
    <div class="section-text">
      8.4 Response to Claim. In the defense or settlement of any infringement
      Claim, Nickel may, at its sole option and expense, (a) procure for
      Subscriber a license to continue using the Service; (b) replace or modify
      the allegedly infringing technology to avoid the infringement; or, if the
      foregoing are not commercially feasible in Nickel's sole judgment, then
      (c) terminate Subscriber's license and access to the Service and refund
      any prepaid, unused Service fees as of the date of termination.
    </div>
    <div class="section-text">
      8.5 Limited Remedy. This Section 8 states Nickel's sole and exclusive
      liability, and Subscriber's sole and exclusive remedy, for the actual or
      alleged infringement or misappropriation of any third party intellectual
      property right by the Service.
    </div>
    <div class="section-header">9. SUBSCRIBER INDEMNIFICATION</div>
    <div class="section-text">
      9.1 Defense. Subscriber will defend Nickel and its Affiliates from any
      actual or threatened third party Claim arising out of or based upon use of
      the Service by Subscriber, its Affiliates, Permitted Third Parties, or any
      Authorized User that is not in accordance with the terms of this
      Agreement, and Nickel and its Affiliate's use in accordance with this
      Agreement of the Subscriber Data or other materials or information
      provided by or on behalf of Subscriber. Nickel shall (a) give Subscriber
      prompt written notice of the Claim; (b) grant Subscriber full and complete
      control over the defense and settlement of the Claim; (c) provide
      assistance in connection with the defense and settlement of the Claim as
      Subscriber may reasonably request; and (d) comply with any settlement or
      court order made in connection with the Claim. Nickel will not defend or
      settle any Claim under this Section 9.1 without Subscriber's prior written
      consent. Nickel will have the right to participate in the defense of the
      Claim at its own expense and with counsel of its own choosing, subject to
      Subscriber's sole control over the defense and settlement of the Claim as
      provided above.
    </div>
    <div class="section-text">
      9.2 Indemnification. Subscriber will indemnify Nickel from and pay (a) all
      damages, costs, and attorneys' fees finally awarded against Nickel in any
      Claim under Section 9.1; (b) all out-of-pocket costs (including reasonable
      attorneys' fees) reasonably incurred by Nickel in connection with the
      defense of a Claim under Section 9.1 (other than attorneys' fees and costs
      incurred without Subscriber's consent after Subscriber has accepted
      defense of the Claim); and, (c) all amounts that Subscriber agrees to pay
      to any third party to settle any Claim under Section 9.1.
    </div>
    <div class="section-header">10. LIMITATIONS OF LIABILITY</div>
    <div class="section-text">
      10.1 Disclaimer of Indirect Damages. TO THE EXTENT PERMITTED BY LAW,
      NEITHER PARTY WILL, UNDER ANY CIRCUMSTANCES, BE LIABLE TO THE OTHER PARTY
      OR TO ANY THIRD PARTY FOR CONSEQUENTIAL, INCIDENTAL, SPECIAL, OR EXEMPLARY
      DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT, INCLUDING BUT NOT
      LIMITED TO LOST PROFITS OR LOSS OF BUSINESS, EVEN IF THE PARTY IS APPRISED
      OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING.
    </div>
    <div class="section-text">
      10.2 Cap on Liability. TO THE EXTENT PERMITTED BY LAW, UNDER NO
      CIRCUMSTANCES WILL EITHER PARTY'S TOTAL LIABILITY OF ALL KINDS ARISING OUT
      OF OR RELATED TO THIS AGREEMENT (INCLUDING BUT NOT LIMITED TO WARRANTY
      CLAIMS), REGARDLESS OF THE FORUM AND REGARDLESS OF WHETHER ANY ACTION OR
      CLAIM IS BASED ON CONTRACT, TORT, OR OTHERWISE, EXCEED THE TOTAL AMOUNTS
      PAID OR DUE BY SUBSCRIBER UNDER THIS AGREEMENT DURING THE 12 MONTHS
      IMMEDIATELY PRECEDING THE DATE OF THE EVENT GIVING RISE TO THE CLAIM.
    </div>
    <div class="section-text">
      10.3 EXCLUSIONS. THE LIMITATIONS CONTAINED IN THIS SECTION 10 WILL NOT
      LIMIT A PARTY'S (i) INDEMNIFICATION OBLIGATIONS UNDER THIS AGREEMENT; (ii)
      LIABILITY FOR ITS INFRINGEMENT OR MISAPPROPRIATION OF ANY PROPRIETARY
      RIGHTS OF THE OTHER PARTY, OR (iii) LIABILITY FOR DEATH OR PERSONAL INJURY
      ARISING FROM THE PARTY'S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.
    </div>
    <div class="section-text">
      10.4 Independent Allocations of Risk. EACH PROVISION OF THIS AGREEMENT
      THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR
      EXCLUSION OF DAMAGES IS TO ALLOCATE THE RISKS OF THIS AGREEMENT BETWEEN
      THE PARTIES. THIS ALLOCATION IS REFLECTED IN THE PRICING OFFERED BY Nickel
      TO SUBSCRIBER AND IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN
      BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT
      OF ALL OTHER PROVISIONS OF THIS AGREEMENT. THE LIMITATIONS IN THIS SECTION
      WILL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
      REMEDY IN THIS AGREEMENT.
    </div>
    <div class="section-header">11. GENERAL</div>
    <div class="section-text">
      11.1 U.S. Government Use. If the Service is licensed under a United States
      government contract, Subscriber acknowledges that the Service is a
      "commercial item" as defined in 48 CFR 2.101, consisting of "commercial
      computer software" and "commercial computer software documentation," as
      such terms are defined in FAR Section 2.101 and Section 252.227-7014 of
      the Defense Federal Acquisition Regulation Supplement (48 CFR
      252.227-7014) and used in 48 CFR 12.212 or 48 CFR 227.7202-1, as
      applicable. Subscriber also acknowledges that the Service is "commercial
      computer software" as defined in 48 CFR 252.227-7014(a)(1). United States
      government agencies and entities and others acquiring under a United
      States government contract shall have only those rights, and shall be
      subject to all restrictions, set forth in this Agreement.
    </div>
    <div class="section-text">
      11.2 Third Party Software Notices. The Service may contain third party
      software. Notices of applicable licenses for such third party software are
      set forth at http://files.Nickel.com/wiki/pdfs/Nickel_LegalNotices.pdf, as
      such may be changed from time to time in Nickel's sole discretion.
    </div>
    <div class="section-text">
      11.3 Relationship. Nickel will be and act as an independent contractor
      (and not as the agent or representative of Subscriber) in the performance
      of this Agreement.
    </div>
    <div class="section-text">
      11.4 Assignability. Neither party may assign its rights, duties, or
      obligations under this Agreement (including by way of merger, acquisition,
      sale of assets, change of control or operation of law) without the other
      party's prior written consent, which consent will not be unreasonably
      withheld or delayed, except that a party may assign this Agreement as a
      result of merger, acquisition, sale of assets, change of control, or
      operation of law without the other party's consent if (a) in the event of
      actual assignment, the assignee agrees in writing to assume and fulfill
      all of the assigning party's obligations under this Agreement, and (b)
      with respect to Subscriber, the proposed successor or controlling party is
      not a competitor of Nickel. Nickel may terminate this Agreement in its
      discretion if Subscriber assigns this Agreement to a competitor of Nickel
      or comes to be controlled by a competitor of Nickel.
    </div>
    <div class="section-text">
      11.5 Subcontractors. Nickel may use subcontractors or other third parties
      in carrying out its obligations under this Agreement and any Service
      Order. Nickel remains responsible for all of its obligations under this
      Agreement.
    </div>
    <div class="section-text">
      11.6 Notices. Any notice required or permitted to be given in accordance
      with this Agreement will be effective if it is in writing and sent by
      certified or registered mail, or overnight courier, return receipt
      requested, to the appropriate party at the address set forth on the
      Service Order and with the appropriate postage affixed. Either party may
      change its address for receipt of notice by notice to the other party in
      accordance with this section. Notices are deemed given two business days
      following the date of mailing or one business day following delivery to a
      courier.
    </div>
    <div class="section-text">
      11.7 Force Majeure. Neither party will be liable for, or be considered to
      be in breach of or default under this Agreement on account of, any delay
      or failure to perform as required by this Agreement as a result of any
      cause or condition beyond its reasonable control, so long as that party
      uses all commercially reasonable efforts to avoid or remove the causes of
      non-performance.
    </div>
    <div class="section-text">
      11.8 Governing Law. This Agreement will be interpreted, construed, and
      enforced in all respects in accordance with the local laws of the State of
      Utah, U.S.A., without reference to its choice of law rules and not
      including the provisions of the 1980 U.N. Convention on Contracts for the
      International Sale of Goods.
    </div>
    <div class="section-text">
      11.9 Arbitration. Except for the right of either party to apply to a court
      of competent jurisdiction for a temporary restraining order, a preliminary
      injunction, or other equitable relief to preserve the status quo or
      prevent irreparable harm, any action arising out of or in connection with
      this Agreement or the breach, termination, enforcement, interpretation or
      validity thereof, shall be determined by binding arbitration in Salt Lake
      County, Utah, U.S.A. by one arbitrator. The arbitration shall be
      administered by JAMS pursuant to its Comprehensive Arbitration Rules and
      Procedures. Judgment upon the award rendered by the arbitrators may be
      entered in any court of competent jurisdiction. The prevailing party will
      be entitled to receive from the other party its attorneys' fees and costs
      incurred in connection with any arbitration or litigation instituted in
      connection with this Agreement. The parties shall maintain the
      confidential nature of the arbitration proceeding except as may be
      necessary to prepare for or conduct the arbitration hearing on the merits.
    </div>
    <div class="section-text">
      11.10 No Third Party Beneficiaries. There are no third-party beneficiaries
      to this Agreement, including, without limitation, Subscriber Affiliates,
      Permitted Third Parties and Authorized Users.
    </div>
    <div class="section-text">
      11.11 Waiver. Any waiver of the provisions of this Agreement or of a
      party's rights or remedies under this Agreement must be in writing to be
      effective. Failure, neglect, or delay by a party to enforce the provisions
      of this Agreement or its rights or remedies at any time, will not be
      construed as a waiver of the party's rights under this Agreement and will
      not in any way affect the validity of the whole or any part of this
      Agreement or prejudice the party's right to take subsequent action.
      Exercise or enforcement by either party of any right or remedy under this
      Agreement will not preclude the enforcement by the party of any other
      right or remedy under this Agreement or that the party is entitled by law
      to enforce.
    </div>
    <div class="section-text">
      11.12 Severability. If any part of this Agreement is found to be illegal,
      unenforceable, or invalid, the remaining portions of this Agreement will
      remain in full force and effect. If any material limitation or restriction
      on the use of the Service under this Agreement is found to be illegal,
      unenforceable, or invalid, Subscriber's right to use the Service will
      immediately terminate.
    </div>
    <div class="section-text">
      11.13 Headings. Headings are used in this Agreement for reference only and
      will not be considered when interpreting this Agreement.
    </div>
    <div class="section-text">
      11.14 Counterparts. The Service Order may be executed in any number of
      identical counterparts, notwithstanding that the parties have not signed
      the same counterpart, with the same effect as if the parties had signed
      the same document. All counterparts will be construed as and constitute
      the same agreement. The Service Order may also be executed and delivered
      by facsimile or electronically and such execution and delivery will have
      the same force and effect of an original document with original
      signatures.
    </div>
    <div class="section-text">
      11.15 Entire Agreement. This Agreement, including all exhibits and
      schedules, is the final and complete expression of the agreement between
      these parties regarding Subscriber's use of the Service and Documentation.
      This Agreement supersedes, and the terms of this Agreement govern, all
      previous oral and written communications between the parties, their
      Affiliates, and their respective employees and representatives regarding
      these matters, all of which are merged into this Agreement, including any
      prior Nondisclosure Agreement between the parties or their Affiliates. If
      there is a conflict between the terms of this Service Agreement and a
      Service Order, the terms of the Service Order will control. No usage of
      trade or other regular practice or method of dealing between the parties
      will be used to modify, interpret, supplement, or alter the terms of this
      Agreement. This Agreement may be changed only by a written agreement
      signed by an authorized agent of both parties. If this Agreement is
      translated into another language, the English language version will
      control. Neither party will be bound by, and specifically objects to, any
      term, condition, or other provision that is different from or in addition
      to this Agreement (whether or not it would materially alter this
      Agreement) that is proffered by the other party in any acceptance,
      confirmation, invoice, purchase order, receipt, correspondence, or
      otherwise, unless each party mutually and expressly agrees to such
      provision in writing. [End of Service Agreement]
    </div>

  </div>
  <Footer />
</div>
