<script>
  import TCIcon from "../Home/TCIcon.svelte";

  export let crm = "";
</script>

<style>
  .wrapper {
    height: 40px;
    min-width: 80px;
    max-width: 132px;
    margin-right: 30px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrapper:nth-child(even) {
    margin-right: 0px;
  }
  .wrapper:last-child {
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .wrapper:nth-child(even):last-child {
    margin-bottom: 20px;
  }

  svg {
    width: 100%;
  }

  .st0 {
    fill: #74c269;
  }
  .st1 {
    fill: #484848;
  }
  .st2 {
    fill: #494949;
  }

  .other {
    height: 40px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #2487ff;
    border-radius: 5px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
</style>

<div class="wrapper">
  {#if crm === 'Evertrue'}
    <svg viewBox="0 0 250 57.5">
      <g>
        <path
          class="st0"
          d="M26.1,0c3.1,1.1,4.8,3.8,7,6c3,2.9,5.7,5.9,8.6,8.9c2.3,2.4,2.4,4.6,0.2,7c-4.2,4.5-8.4,9-12.6,13.5
          c-2.1,2.3-4.5,2.5-6.5,0.7c-2-1.8-1.9-4.4,0.2-6.7c1.9-2,3.7-4,5.6-6.1c-8.7-1.4-16-0.3-19,8.7c-2.2,6.5,1.4,13.4,7.8,15.7
          c6.5,2.4,13.5-0.7,16.2-7.1c1.2-2.9,2.7-4,4.9-3.8c3,0.3,4.8,3.1,3.6,6.3c-2.7,7.1-7.7,11.6-15,13.6c-1,0.3-2.2,0.1-3,0.8
          c-1.6,0-3.2,0-4.8,0C19,57,18.5,57.1,18.1,57C8.8,54.9,2.9,49.3,0.6,40c-0.1-0.5,0-1.1-0.6-1.3c0-2,0-4.1,0-6.1
          c0.2-0.2,0.4-0.4,0.5-0.7C4,19.5,11.1,14.1,24.1,14.1c1.3,0,2.6,0,4.5,0c-2.3-2.4-4.1-4.3-5.9-6.3c-1.4-1.5-1.8-3.1-1-5
          c0.6-1.6,2.2-2.2,3.6-2.8C25.6,0,25.8,0,26.1,0z" />
        <path
          class="st1"
          d="M250,32.7c-1.2,2.2-3.1,2.8-5.6,2.7c-4-0.2-8,0-12-0.1c-1.7,0-1.6,0.5-1.1,1.7c1.5,3.3,5.7,4.5,9.6,2.8
          c0.5-0.2,1-0.5,1.5-0.8c2-1.2,3.8-1.1,4.8,0.3c1.1,1.5,0.7,3.4-1.2,4.9c-4.3,3.4-11.6,3.7-16.7,0.7c-4.9-2.9-7.4-8.7-6.4-14.9
          c1-6,5.1-10.4,10.9-11.5c7.3-1.5,14,3,15.5,10.4c0.1,0.4-0.1,0.9,0.5,1.1C250,30.9,250,31.8,250,32.7z
          M236.3,30.4
          c1.5,0,3-0.1,4.6,0c1.3,0.1,1.4-0.5,1-1.6c-0.8-2.5-2.5-4-5-4.2c-2.4-0.2-4.4,1-5.6,3.3c-1,2.1-0.9,2.4,1.5,2.4
          C234,30.4,235.2,30.4,236.3,30.4z" />
        <path
          class="st1"
          d="M60.3,35.3c0.4,4.5,5.6,6.5,10.1,4.4c0.6-0.3,1.1-0.6,1.7-1c1.7-1,3.5-0.8,4.5,0.5c1.1,1.4,0.7,3.3-0.8,4.6
          c-6.5,5.5-20.8,4.1-23.3-8.2C50.3,25,59,16.2,69.3,18.7c5.6,1.4,9.8,6.8,9.8,12.6c0,2.6-1.3,4-4.1,4C70.1,35.4,65.2,35.3,60.3,35.3
          z
          M65.8,30.4c1.5,0,3,0,4.6,0c1,0,1.2-0.4,1-1.3c-0.6-2.5-2.9-4.5-5.4-4.5c-2.6,0-5,1.9-5.7,4.5c-0.2,0.9,0,1.3,1,1.3
          C62.7,30.4,64.2,30.4,65.8,30.4z" />
        <path
          class="st1"
          d="M121.8,35.4c-2.2,0-4.5,0.1-6.7,0c-1.5-0.1-1.5,0.5-1,1.6c1.5,3.4,5.8,4.6,9.8,2.8c0.7-0.3,1.3-0.7,1.9-1.1
          c1.6-0.9,3.1-0.8,4.3,0.6c1.1,1.3,0.9,3.3-0.6,4.4c-4.5,3.6-9.5,4.1-14.8,2.2c-5-1.7-8-5.5-8.7-10.7c-1.2-8.5,3.3-15.3,11-16.8
          c8.2-1.6,15.8,4.7,15.6,13c0,2.5-1.2,3.7-3.7,3.9c-0.1,0-0.3,0-0.4,0C126.2,35.4,124,35.3,121.8,35.4z
          M119.3,30.4c1.5,0,3,0,4.6,0
          c1.1,0,1.2-0.4,0.9-1.3c-0.7-2.6-2.8-4.5-5.4-4.5c-2.5,0-4.9,1.8-5.6,4.3c-0.3,1.1,0,1.6,1.2,1.5C116.4,30.4,117.8,30.4,119.3,30.4
          z" />
        <path
          class="st1"
          d="M211.9,42.9c-1.6,1.8-3.3,3.2-5.6,3.7c-6.2,1.3-11.1-2.2-11.5-8.6c-0.3-5.2-0.2-10.4-0.1-15.7
          c0-2.4,1.8-3.9,4.1-3.9c2.2,0.1,3.7,1.7,3.8,4.2c0,3.8,0,7.7,0,11.5c0.1,3.4,1.6,5.3,4.4,5.4c3.1,0.1,5-1.7,5.1-5.2
          c0.1-3.8,0-7.5,0-11.3c0-3,1.5-4.7,3.9-4.7c2.5,0,3.9,1.7,3.9,4.7c0,6.2,0,12.5,0,18.7c0,2.7-1.2,4.3-3.2,4.7
          C214.4,46.9,212.9,45.8,211.9,42.9z" />
        <path
          class="st2"
          d="M92.6,36.9c2-5.3,3.9-10.2,5.7-15.2c0.5-1.3,1.1-2.4,2.4-3c1.4-0.6,2.9-0.4,4.1,0.6c1.3,1.1,1.6,2.7,1,4.2
          c-2.9,6.9-5.8,13.9-9,20.7C95,48,90,48,88.3,44.4c-3.2-6.8-6.1-13.7-9-20.7c-0.9-2.1,0.2-4.2,2.3-4.9c2-0.7,4.2,0.1,5,2.3
          c1.7,4.3,3.2,8.7,4.9,13C91.8,34.9,92.1,35.7,92.6,36.9z" />
        <path
          class="st2"
          d="M166.1,46.8c-5.9,0-8.5-2.5-8.5-7.9c0-3.8,0-7.7,0-11.5c0-1.1-0.1-1.7-1.3-2c-1.6-0.4-2.4-1.7-2.3-3.3
          c0-1.5,0.8-2.7,2.3-3.1c1.3-0.3,1.4-1,1.3-2c0-0.6,0-1.2,0-1.7c0.1-2.2,1.6-3.7,3.7-3.7c2.2-0.1,3.9,1.4,4.1,3.6
          c0.1,1.1-0.5,2.6,0.3,3.4c0.8,0.8,2.2,0.2,3.3,0.3c1.9,0.2,3,1.5,3.1,3.3c0,1.9-1.1,3.2-3.1,3.5c-1.1,0.2-2.7-0.6-3.3,0.3
          c-0.6,1-0.3,2.5-0.3,3.8c0,2.5,0,4.9,0,7.4c0,1.8,0.9,2.6,2.7,2.6c2.5,0,3.7,1,3.9,2.8c0.2,1.9-0.8,3.2-3.1,3.8
          C167.9,46.7,166.8,46.7,166.1,46.8z" />
        <path
          class="st2"
          d="M183.3,22.5c1-1.3,1.6-2.5,2.7-3.2c1.4-1,2.8-1.1,4.3-0.3c1.4,0.8,1.9,2.1,1.8,3.7c-0.1,1.6-0.9,2.8-2.5,3.2
          c-5.2,1.2-6.4,5.1-6.6,9.7c-0.1,2.3,0,4.6,0,7c0,2.5-1.5,4.1-3.8,4.1c-2.3,0-4-1.5-4-3.9c-0.1-6.7-0.1-13.5,0-20.2
          c0-2.1,1.4-3.6,3.4-3.9C180.8,18.2,182.3,19.4,183.3,22.5z" />
        <path
          class="st2"
          d="M143,22.6c0.9-1.3,1.5-2.4,2.5-3.2c1.4-1.1,2.9-1.2,4.5-0.4c1.5,0.7,1.8,2.1,1.8,3.7c-0.1,1.7-1,3-2.7,3.3
          c-4.3,0.9-6.2,4.3-6.3,8.5c-0.1,2.6,0,5.2,0,7.8c0,2.7-1.6,4.4-4.1,4.3c-2.4,0-3.8-1.6-3.8-4.3c0-6.2,0-12.5,0-18.7
          c0-3.2,1-4.7,3.3-5C140.5,18.2,142,19.4,143,22.6z" />
      </g>
    </svg>
  {:else if crm === 'Paciolan'}
    <TCIcon icon="paciolan" forCRM={true} />
  {:else if crm === 'Blackbaud'}
    <TCIcon icon="blackbaud" forCRM={true} />
  {:else if crm === 'Salesforce'}
    <TCIcon icon="salesforce" forCRM={true} />
  {:else if crm === 'Other'}
    <div class="other">Other</div>
  {/if}
</div>
