<script>
  import request from "axios";
  import Nav from "../common/Nav.svelte";
  import NickelInput from "../common/NickelInput.svelte";
  import CircleIcon from "../common/CircleIcon.svelte";
  import Footer from "../common/Footer.svelte";
  import Map from "./Map/Map.svelte";

  export let router = {};

  let clientWidth;

  let name = "";
  let setName = e => (name = e.target.value);

  let universityEmail = "";
  let setUniversityEmail = e => (universityEmail = e.target.value);

  let email = "";
  let setEmail = e => (email = e.target.value);

  let phone = "";
  let setPhone = e => (phone = e.target.value);

  $: submitEnabled =
    name &&
    name.length &&
    /^[^\s@]+@[^\s@]+\.[^\s@]*edu$/.test(universityEmail) &&
    /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(email);

  let submitted = false;
  const submit = () => {
    request
      .post("https://api.nickeldxm.com/v1/marketing/contact", {
        name,
        universityEmail,
        email,
        phone
      })
      .then(() => (submitted = true))
      .catch(console.warn);
  };

  $: submitClasses = `button${submitEnabled ? "" : " disabled"}`;

  $: mobile = clientWidth < 882;
</script>

<style>
  .wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fafafa;
  }
  .content {
    height: 560px;
    width: 977px;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
  }

  .form-col {
    width: 383px;
  }

  .title {
    margin: 0px;
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
  }

  .body {
    margin: 0px;
    margin-top: 20px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
  }

  .inputs {
    margin-top: 48px;
  }

  .button {
    position: relative;
    z-index: 1;
    flex-shrink: 0;
    height: 40px;
    width: 100%;
    margin-top: 30px;
    padding: 0;
    color: #fff;
    background-color: #2487ff;
    border-radius: 5px;
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    font-weight: 700;
    cursor: pointer;
    vertical-align: middle;
  }
  .button:active {
    opacity: 0.9;
  }
  .button.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  .thank-you {
    height: 40px;
    width: 100%;
    margin-top: 30px;
    color: #000;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    font-weight: 700;
    text-align: center;
  }

  .info-col {
    width: 484px;
  }

  .icon-line {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
  }

  .icon.title {
    margin: 0px;
    margin-left: 20px;
    margin-top: 0px;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.5px;
  }
  .icon.title:first-of-type {
    margin-top: 5px;
  }

  img {
    width: 100vw;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    .content {
      height: auto;
      width: 335px;
      margin-top: 60px;
      flex-direction: column;
    }

    .form-col {
      width: 335px;
      align-items: center;
    }

    .title {
      width: 100%;
      text-align: center;
    }

    .body {
      text-align: center;
    }

    .info-col {
      margin-top: 40px;
      width: 335px;
    }

    .icon.title {
      max-width: 90%;
      text-align: left;
    }

    img {
      margin-top: 60px;
      width: 100vw;
    }
  }
  @media only screen and (max-width: 372px) {
    .form {
      padding: 30px 10px;
    }
  }
</style>

<div class="wrapper" bind:clientWidth>
  <Nav lightBackground={true} {clientWidth} width={977} />
  <div class="content">
    <div class="form-col">
      <h2 class="title">Let's Chat</h2>
      <p class="body">
        We'd love to talk with you more about how to increase your donations.
        Drop us your contact info and a real human will be in touch with you.
      </p>
      <div class="inputs">
        <NickelInput
          type="text"
          placeholder="Name"
          value={name}
          onInput={setName} />
        <NickelInput
          type="email"
          placeholder="University Email"
          value={universityEmail}
          onInput={setUniversityEmail} />
        <NickelInput
          type="email"
          placeholder="Email"
          value={email}
          onInput={setEmail} />
        <NickelInput
          type="tel"
          placeholder="Phone (optional)"
          value={phone}
          onInput={setPhone} />
        {#if submitted}
          <div class="thank-you">THANK YOU!</div>
        {:else}
          <button class={submitClasses} on:click|once={submit}>SUBMIT</button>
        {/if}
      </div>
    </div>
    <div class="info-col">
      <div class="icon-line">
        <div class="icon">
          <CircleIcon icon="phone" size="small" />
        </div>
        <h4 class="icon title">801.756.5701</h4>
      </div>
      <div class="icon-line">
        <div class="icon">
          <CircleIcon icon="address" size="small" />
        </div>
        <div>
          <h4 class="icon title">2100 PLEASANT GROVE BLVD 2ND FLOOR</h4>
          <h4 class="icon title">PLEASANT GROVE UT 84062 {clientWidth}</h4>
        </div>
      </div>
      <Map {mobile} />
    </div>
  </div>
  <img
    src="assets/office.png"
    alt="Nickel office building under blue transparent overlay." />
  <Footer />
</div>
