<script>
  export let icon = "paciolan";
  export let forCRM = false;

  $: svgClasses = forCRM ? "margin-override" : "";
  $: largeClasses = `large${forCRM ? " margin-override" : ""}`;
</script>

<style>
  svg {
    height: 20px;
    margin-bottom: 24px;
  }
  svg.large {
    height: 36px;
    margin-top: -8px;
    margin-bottom: 16px;
  }
  svg.margin-override {
    margin: 0px;
  }

  .paciolan-1 {
    fill: #eb0029;
  }
  .paciolan-2 {
    fill: #040708;
  }
  .salesforce-1 {
    fill: #009ddc;
  }
  .salesforce-2 {
    fill: #ffffff;
  }
  .salesforce-3 {
    fill: #ffffff;
    fill-rule: evenodd;
    clip-rule: evenodd;
  }
  .blackbaud {
    fill: #53575a;
  }
  .ticketmaster {
    fill: #026cdf;
  }
  .tailgate-guys {
    fill: #0e2240;
  }
</style>

{#if icon === 'paciolan'}
  <svg class={svgClasses} viewBox="0 0 250 46.2">
    <path
      class="paciolan-1"
      d="M28.6,6.7H15.7l-3.7,17.4h12.5c3,0,6-2.1,6.5-4.6l1.8-8.7c0.2-1,0-1.9-0.6-2.7C31.5,7.3,30.1,6.7,28.6,6.7z
      M7.5,46.2H0l9.6-46h19.9c3.8,0,7,1.4,9,3.8c1.7,2,2.3,4.6,1.7,7.3l-1.8,8.7c-1.2,5.9-7.9,10.7-14.9,10.7H10.7L7.5,46.2z
      M63.3,39.6H50.4c-1.7,0-3.1-0.5-3.8-1.5c-0.5-0.7-0.7-1.5-0.5-2.4c0.5-2.4,3.3-4.3,6.4-4.3H65L63.3,39.6z
      M63.5,12.1H45.3
      l-1.4,6.5h18.5c1.6,0,2.9,0.6,3.7,1.5c0.6,0.8,0.8,1.7,0.6,2.7l-0.4,2.1H53.5c-7.1,0-13.6,4.6-14.8,10.4c-0.5,2.6,0,5.1,1.6,7
      c2,2.4,5.3,3.8,9.2,3.8h19.9l4.8-23c0.6-2.7,0-5.2-1.7-7.3C70.5,13.5,67.2,12.1,63.5,12.1L63.5,12.1z" />
    <path
      class="paciolan-2"
      d="M201.8,39.6H189c-1.7,0-3.1-0.5-3.8-1.5c-0.5-0.7-0.7-1.5-0.5-2.4c0.5-2.4,3.3-4.3,6.4-4.3h12.5L201.8,39.6z
      M202,12.1h-18.2l-1.4,6.5H201c1.6,0,2.9,0.6,3.7,1.5c0.6,0.8,0.8,1.7,0.6,2.7l-0.4,2.1H192c-7.1,0-13.6,4.6-14.8,10.4
      c-0.5,2.6,0,5.1,1.6,7c2,2.4,5.3,3.8,9.2,3.8h19.9l4.8-23c0.6-2.7,0-5.2-1.7-7.3C209,13.5,205.7,12.1,202,12.1L202,12.1z" />
    <path
      class="paciolan-1"
      d="M86.7,21.7C86.7,21.7,86.7,21.6,86.7,21.7c0.3-0.7,0.7-1.3,1.3-1.8c1-0.8,2.3-1.3,3.6-1.3h15.8l1.4-6.8H92.2
      c-1.6,0-3.3,0.3-4.8,0.9c-1.5,0.5-2.8,1.3-4.1,2.3c-2.1,1.7-3.5,3.9-4,6.1l-0.5,2.2l-1.7,8c0,0.2-0.1,0.4-0.1,0.5l-0.6,2.8
      l-0.5,2.2c-0.5,2.2,0,4.4,1.4,6.1c0.8,1,1.9,1.8,3.1,2.3c1.3,0.6,2.8,0.9,4.5,0.9h16.7l1.4-6.6H87.4c-1.3,0-2.4-0.5-3.1-1.3
      c-0.5-0.6-0.7-1.4-0.5-2.2l0,0l0.2-1.4h0l1.1-5.5h0l0.5-2.2L86.7,21.7z" />
    <polygon class="paciolan-1" points="117.5,0 115.8,8.3 146.5,3 " />
    <polygon
      class="paciolan-2"
      points="115.1,12.2 108,46.1 115.5,46.1 122.6,12.2 " />
    <polygon
      class="paciolan-2"
      points="179.3,0 171.8,0 162.2,46.1 169.7,46.1 " />
    <path
      class="paciolan-2"
      d="M249.7,23.6L247.3,35L245,46.1h0h-7.5l4.9-23.3c0.7-3.5-4-4.2-5.7-4.2h0c-0.7,0-2,0-2.9,0.1
      c-4.4,0.7-7.1,2.9-7.5,5.1l-4.6,22.2h-7.5l7.1-34.1h7.5l-0.3,1.3c0,0,0,0,0,0l-0.2,0.8c0.3-0.1,0.5-0.3,0.8-0.4
      c2.6-1.2,5.5-1.7,8.3-1.7h1.6c0.2,0,0.5,0,0.7,0c1.8,0.1,3.3,0.6,4.6,1.2c2.4,1.1,3.7,2.6,3.7,2.6
      C249.5,17.5,250.6,19.4,249.7,23.6
      M153,22.8c-0.8,4.1-1.7,8.2-2.6,12.3c-0.5,2.5-3.5,4.6-6.5,4.6c-2.5,0-5,0-7.5,0
      c-1.6,0-2.9-0.6-3.7-1.5c-0.6-0.8-0.8-1.7-0.6-2.7l0.6-3c0.6-3.1,1.3-6.2,1.9-9.2c0.5-2.5,3.5-4.6,6.5-4.6c2.5,0,5,0,7.5,0
      c1.6,0,2.9,0.6,3.7,1.5C153,20.9,153.2,21.8,153,22.8L153,22.8z
      M142.1,12c-7,0-13.7,4.8-14.9,10.8l-2.6,12.3
      c-0.6,2.7,0,5.2,1.7,7.3c2,2.4,5.3,3.8,9,3.8h2.2h5.3h1c0,0,0.1,0,0.1,0c6.6-0.5,12.6-5.1,13.8-10.7l2.6-12.3
      c0.6-2.7,0-5.2-1.7-7.3c-2-2.4-5.3-3.8-9-3.8H142.1z" />
  </svg>
{:else if icon === 'salesforce'}
  <svg class={largeClasses} viewBox="0 0 250 175">
    <path
      class="salesforce-1"
      d="M104,19.1c8.1-8.4,19.3-13.6,31.7-13.6c16.5,0,30.9,9.2,38.6,22.9c6.7-3,14-4.6,21.8-4.6
      c29.8,0,53.9,24.3,53.9,54.4c0,30-24.1,54.4-53.9,54.4c-3.6,0-7.2-0.4-10.6-1.1c-6.8,12-19.6,20.2-34.4,20.2c-6.2,0-12-1.4-17.2-4
      C127,163.7,111.1,175,92.5,175c-19.4,0-35.9-12.2-42.2-29.4c-2.8,0.6-5.6,0.9-8.6,0.9c-23,0-41.7-18.9-41.7-42.2
      c0-15.6,8.4-29.2,20.9-36.5c-2.6-5.9-4-12.4-4-19.3C16.9,21.7,38.6,0,65.4,0C81.2,0,95.2,7.5,104,19.1z" />
    <path
      class="salesforce-2"
      d="M36.2,90.7c-0.2,0.4,0.1,0.5,0.1,0.6c0.5,0.3,0.9,0.6,1.4,0.9c2.5,1.4,5,1.7,7.5,1.7c5.1,0,8.3-2.7,8.3-7.1
      v-0.1c0-4.1-3.6-5.5-7-6.6L46.1,80c-2.5-0.8-4.7-1.5-4.7-3.2v-0.1c0-1.4,1.3-2.5,3.3-2.5c2.2,0,4.8,0.7,6.5,1.7
      c0,0,0.5,0.3,0.7-0.2c0.1-0.3,1-2.6,1-2.8c0.1-0.3-0.1-0.5-0.2-0.6c-1.9-1.2-4.6-2-7.3-2l-0.5,0c-4.7,0-8,2.8-8,6.9v0.1
      c0,4.3,3.6,5.7,7,6.6l0.5,0.2c2.5,0.8,4.6,1.4,4.6,3.1v0.1c0,1.6-1.4,2.8-3.6,2.8c-0.9,0-3.6,0-6.6-1.9c-0.4-0.2-0.6-0.4-0.8-0.5
      c-0.1-0.1-0.5-0.3-0.7,0.2L36.2,90.7z" />
    <path
      class="salesforce-2"
      d="M111.3,90.7c-0.2,0.4,0.1,0.5,0.1,0.6c0.5,0.3,0.9,0.6,1.4,0.9c2.5,1.4,5,1.7,7.5,1.7c5.1,0,8.3-2.7,8.3-7.1
      v-0.1c0-4.1-3.6-5.5-7-6.6l-0.4-0.1c-2.5-0.8-4.7-1.5-4.7-3.2v-0.1c0-1.4,1.3-2.5,3.3-2.5c2.2,0,4.8,0.7,6.5,1.7
      c0,0,0.5,0.3,0.7-0.2c0.1-0.3,1-2.6,1-2.8c0.1-0.3-0.1-0.5-0.2-0.6c-1.9-1.2-4.6-2-7.3-2l-0.5,0c-4.7,0-8,2.8-8,6.9v0.1
      c0,4.3,3.6,5.7,7,6.6l0.5,0.2c2.5,0.8,4.6,1.4,4.6,3.1v0.1c0,1.6-1.4,2.8-3.6,2.8c-0.9,0-3.6,0-6.6-1.9c-0.4-0.2-0.6-0.4-0.8-0.5
      c-0.1-0.1-0.5-0.2-0.7,0.2L111.3,90.7z" />
    <path
      class="salesforce-2"
      d="M166.8,77.5c-0.4-1.4-1.1-2.7-1.9-3.7c-0.9-1-2-1.9-3.3-2.5c-1.3-0.6-2.9-0.9-4.6-0.9
      c-1.7,0-3.3,0.3-4.6,0.9c-1.3,0.6-2.4,1.5-3.3,2.5c-0.9,1-1.5,2.3-1.9,3.7c-0.4,1.4-0.6,3-0.6,4.6c0,1.6,0.2,3.2,0.6,4.6
      c0.4,1.4,1.1,2.7,1.9,3.7c0.9,1,2,1.9,3.3,2.5c1.3,0.6,2.9,0.9,4.6,0.9c1.7,0,3.3-0.3,4.6-0.9c1.3-0.6,2.4-1.4,3.3-2.5
      c0.9-1,1.5-2.3,1.9-3.7c0.4-1.4,0.6-3,0.6-4.6C167.4,80.5,167.2,79,166.8,77.5
      M162.5,82.1c0,2.5-0.5,4.4-1.4,5.8
      c-0.9,1.4-2.3,2-4.2,2c-1.9,0-3.3-0.7-4.1-2c-0.9-1.4-1.4-3.3-1.4-5.8c0-2.5,0.5-4.4,1.4-5.8c0.9-1.4,2.2-2,4.1-2
      c1.9,0,3.3,0.7,4.2,2C162.1,77.7,162.5,79.7,162.5,82.1" />
    <path
      class="salesforce-3"
      d="M202,89.3c-0.1-0.4-0.5-0.3-0.5-0.3c-0.6,0.2-1.3,0.5-2,0.6c-0.7,0.1-1.5,0.2-2.3,0.2
      c-2.1,0-3.7-0.6-4.9-1.8c-1.2-1.2-1.8-3.2-1.8-5.9c0-2.4,0.6-4.2,1.6-5.6c1-1.4,2.6-2.1,4.7-2.1c1.8,0,3.1,0.2,4.5,0.6
      c0,0,0.3,0.1,0.5-0.3c0.4-1,0.7-1.8,1.1-2.9c0.1-0.3-0.2-0.5-0.3-0.5c-0.6-0.2-1.9-0.6-2.9-0.7c-0.9-0.1-2-0.2-3.2-0.2
      c-1.8,0-3.4,0.3-4.8,0.9c-1.4,0.6-2.5,1.5-3.4,2.5c-0.9,1-1.6,2.3-2.1,3.7c-0.5,1.4-0.7,3-0.7,4.6c0,3.6,1,6.4,2.8,8.5
      c1.9,2.1,4.7,3.2,8.5,3.2c2.2,0,4.4-0.4,6.1-1.1c0,0,0.3-0.1,0.2-0.5L202,89.3z" />
    <path
      class="salesforce-3"
      d="M223.9,76.7c-0.4-1.4-1.3-2.8-1.9-3.4c-0.9-1-1.8-1.7-2.8-2.1c-1.2-0.5-2.6-0.8-4.1-0.8
      c-1.8,0-3.5,0.3-4.8,0.9c-1.3,0.6-2.5,1.5-3.3,2.5c-0.9,1.1-1.5,2.3-2,3.8c-0.4,1.4-0.6,3-0.6,4.6c0,1.7,0.2,3.2,0.7,4.6
      c0.4,1.4,1.1,2.7,2.1,3.7c1,1,2.2,1.8,3.6,2.4c1.5,0.6,3.2,0.9,5.3,0.9c4.2,0,6.4-0.9,7.3-1.4c0.2-0.1,0.3-0.2,0.1-0.7l-0.9-2.7
      c-0.1-0.4-0.5-0.3-0.5-0.3c-1,0.4-2.5,1.1-5.9,1.1c-2.2,0-3.9-0.7-5-1.7c-1.1-1.1-1.6-2.6-1.7-4.8l14.5,0c0,0,0.4,0,0.4-0.4
      C224.3,82.8,224.8,80,223.9,76.7z
      M209.5,79.8c0.2-1.4,0.6-2.5,1.2-3.4c0.9-1.4,2.2-2.1,4.1-2.1s3.2,0.7,4.1,2.1
      c0.6,0.9,0.9,2.1,1,3.4L209.5,79.8z" />
    <path
      class="salesforce-3"
      d="M107.8,76.7c-0.4-1.4-1.3-2.8-1.9-3.4c-0.9-1-1.8-1.7-2.8-2.1c-1.2-0.5-2.6-0.8-4.1-0.8
      c-1.8,0-3.5,0.3-4.8,0.9c-1.3,0.6-2.5,1.5-3.3,2.5c-0.9,1.1-1.5,2.3-2,3.8c-0.4,1.4-0.6,3-0.6,4.6c0,1.7,0.2,3.2,0.7,4.6
      c0.4,1.4,1.1,2.7,2.1,3.7c1,1,2.2,1.8,3.6,2.4c1.5,0.6,3.2,0.9,5.3,0.9c4.2,0,6.4-0.9,7.3-1.4c0.2-0.1,0.3-0.2,0.1-0.7l-0.9-2.7
      c-0.1-0.4-0.5-0.3-0.5-0.3c-1,0.4-2.5,1.1-5.9,1.1c-2.2,0-3.9-0.7-5-1.7c-1.1-1.1-1.6-2.6-1.7-4.8l14.5,0c0,0,0.4,0,0.4-0.4
      C108.3,82.8,108.7,80,107.8,76.7z
      M93.4,79.8c0.2-1.4,0.6-2.5,1.2-3.4c0.9-1.4,2.2-2.1,4.1-2.1c1.9,0,3.2,0.7,4.1,2.1
      c0.6,0.9,0.9,2.1,1,3.4L93.4,79.8z" />
    <path
      class="salesforce-2"
      d="M67.9,79.1c-0.6,0-1.3-0.1-2.3-0.1c-1.2,0-2.4,0.2-3.6,0.5c-1.1,0.3-2.2,0.8-3,1.4c-0.9,0.6-1.6,1.4-2.1,2.4
      c-0.5,1-0.8,2.1-0.8,3.3c0,1.3,0.2,2.4,0.7,3.3c0.4,0.9,1.1,1.7,1.9,2.3c0.8,0.6,1.8,1,3,1.3c1.1,0.3,2.4,0.4,3.8,0.4
      c1.5,0,3-0.1,4.4-0.4c1.4-0.2,3.2-0.6,3.7-0.7c0.5-0.1,1-0.3,1-0.3c0.4-0.1,0.3-0.5,0.3-0.5l0-13.3c0-2.9-0.8-5.1-2.3-6.4
      c-1.5-1.3-3.8-2-6.7-2c-1.1,0-2.8,0.1-3.9,0.4c0,0-3.2,0.6-4.5,1.6c0,0-0.3,0.2-0.1,0.6l1,2.8c0.1,0.4,0.5,0.2,0.5,0.2
      s0.1,0,0.2-0.1c2.8-1.5,6.3-1.5,6.3-1.5c1.6,0,2.8,0.3,3.6,0.9c0.8,0.6,1.2,1.5,1.2,3.5v0.6C69,79.2,67.9,79.1,67.9,79.1z
      M62.1,89.3c-0.6-0.5-0.6-0.6-0.8-0.9c-0.3-0.4-0.4-1.1-0.4-1.9c0-1.3,0.4-2.2,1.3-2.8c0,0,1.2-1.1,4.2-1c2.1,0,3.9,0.3,3.9,0.3
      v6.6h0c0,0-1.8,0.4-3.9,0.5C63.4,90.3,62.1,89.3,62.1,89.3z" />
    <path
      class="salesforce-3"
      d="M184.7,71.6c0.1-0.3-0.1-0.5-0.2-0.5c-0.2-0.1-1.5-0.4-2.4-0.4c-1.8-0.1-2.8,0.2-3.7,0.6
      c-0.9,0.4-1.9,1.1-2.4,1.8l0-1.8c0-0.2-0.2-0.4-0.4-0.4h-3.7c-0.2,0-0.4,0.2-0.4,0.4v21.6c0,0.2,0.2,0.4,0.4,0.4h3.8
      c0.2,0,0.4-0.2,0.4-0.4V82.1c0-1.5,0.2-2.9,0.5-3.8c0.3-0.9,0.7-1.6,1.3-2.1c0.5-0.5,1.1-0.9,1.8-1.1c0.7-0.2,1.4-0.3,1.9-0.3
      c0.8,0,1.6,0.2,1.6,0.2c0.3,0,0.4-0.1,0.5-0.4C183.9,74,184.6,72,184.7,71.6z" />
    <path
      class="salesforce-3"
      d="M149,61.6c-0.5-0.1-0.9-0.2-1.4-0.3c-0.6-0.1-1.2-0.2-2-0.2c-2.6,0-4.7,0.7-6.1,2.2
      c-1.4,1.5-2.4,3.7-2.9,6.6l-0.2,1h-3.3c0,0-0.4,0-0.5,0.4l-0.5,3c0,0.3,0.1,0.5,0.5,0.5h3.2l-3.2,18.1c-0.3,1.5-0.5,2.7-0.9,3.6
      c-0.3,0.9-0.6,1.6-1,2.1c-0.4,0.5-0.7,0.8-1.3,1c-0.5,0.2-1.1,0.2-1.7,0.2c-0.4,0-0.8-0.1-1.2-0.1c-0.3-0.1-0.5-0.1-0.8-0.3
      c0,0-0.4-0.1-0.5,0.2c-0.1,0.3-1,2.7-1.1,2.9c-0.1,0.3,0,0.5,0.2,0.6c0.4,0.2,0.7,0.3,1.3,0.4c0.8,0.2,1.5,0.2,2.1,0.2
      c1.3,0,2.5-0.2,3.6-0.6c1-0.4,1.9-1,2.7-1.9c0.8-0.9,1.4-1.9,1.9-3.2c0.5-1.3,0.9-3,1.3-4.9l3.3-18.5h4.8c0,0,0.4,0,0.5-0.4
      l0.5-3c0-0.3-0.1-0.5-0.5-0.5H141c0-0.1,0.2-1.7,0.8-3.3c0.2-0.7,0.7-1.2,1-1.5c0.4-0.4,0.8-0.6,1.2-0.8c0.5-0.1,1-0.2,1.6-0.2
      c0.4,0,0.9,0.1,1.2,0.1c0.4,0.1,0.6,0.1,0.7,0.2c0.5,0.1,0.5,0,0.6-0.2l1.1-3C149.4,61.8,149.1,61.6,149,61.6z" />
    <path
      class="salesforce-3"
      d="M84.3,92.9c0,0.2-0.2,0.4-0.4,0.4H80c-0.2,0-0.4-0.2-0.4-0.4V62c0-0.2,0.2-0.4,0.4-0.4h3.8
      c0.2,0,0.4,0.2,0.4,0.4V92.9z" />
  </svg>
{:else if icon === 'blackbaud'}
  <svg class={svgClasses} viewBox="0 0 250 38.1">
    <path
      class="blackbaud"
      d="M65.4,30.2V10.7h-5.3v2.2c-1.7-1.2-4-2.4-6.6-2.5C44.4,10,39,16.5,39,24.2c0,7.4,5.4,13.7,14.4,13.8
      c4.7,0.1,9-3.7,9-3.7l3.6,3.6l3.5-3.6L65.4,30.2z
      M53.8,32.8c-5.7,0.5-9.7-4-9.7-8.5c0-4.6,3.8-9.5,9.9-8.7c2.6,0.3,4.8,2,6.1,3.7
      V29C58.8,30.8,56.4,32.6,53.8,32.8z
      M179.8,30.2V10.5h-5.3v2.4c-1.7-1.2-4-2.4-6.6-2.5c-9.2-0.4-14.5,6.1-14.5,13.8
      c0,7.4,5.4,13.7,14.4,13.8c4.7,0.1,9-3.7,9-3.7l3.6,3.6l3.5-3.6L179.8,30.2z
      M168.2,32.8c-5.7,0.5-9.7-4-9.7-8.5
      c0-4.6,3.8-9.5,9.9-8.7c2.6,0.3,4.8,2,6.1,3.7V29C173.1,30.8,170.8,32.6,168.2,32.8z
      M12,38c8.9,0,14.4-6.4,14.4-13.8
      c0-7.7-5.4-14.2-14.5-13.8c-2.6,0.1-4.9,1.3-6.6,2.5V0H0v30.2C0,30.2,3.1,38,12,38z
      M5.3,29v-9.6c1.3-1.7,3.5-3.4,6.1-3.7
      c6.2-0.8,9.9,4.1,9.9,8.7c0,4.5-4,9.1-9.7,8.5C9,32.6,6.7,30.8,5.3,29L5.3,29z
      M137,38c8.9,0,14.4-6.4,14.4-13.8
      c0-7.7-5.4-14.2-14.5-13.8c-2.6,0.1-4.9,1.3-6.6,2.5V0H125v30.2C125,30.2,128.1,38,137,38z
      M130.3,29v-9.6c1.3-1.7,3.5-3.4,6.1-3.7
      c6.2-0.8,9.9,4.1,9.9,8.7c0,4.5-4,9.1-9.7,8.5C133.9,32.6,131.6,30.8,130.3,29L130.3,29z
      M241.2,30.2V0h-5.3v12.8
      c-1.7-1.2-4-2.4-6.6-2.5c-9.2-0.4-14.5,6.1-14.5,13.8c0,7.4,5.5,13.8,14.4,13.8C238.1,38,241.2,30.2,241.2,30.2L241.2,30.2z
      M229.7,32.8c-5.7,0.5-9.7-4-9.7-8.5c0-4.6,3.8-9.5,9.9-8.7c2.6,0.3,4.8,2,6.1,3.7V29C234.6,30.8,232.3,32.6,229.7,32.8z
      M35.4,0 v37.6H30V0H35.4z
      M83.6,38c-7.4,0-13.8-5.9-13.9-13.8c0-8,6.5-13.9,13.9-13.9c4.4,0,7.8,1.9,10.1,4.4c0,0-3.6,3.4-3.9,3.6
      c-0.7-0.9-3.1-2.7-6.2-2.7c-4.6,0-8.5,3.7-8.5,8.5c0,4.8,4,8.5,8.5,8.5c3.1,0,5.4-1.7,6.4-2.9l3.7,3.8C91.4,36.2,87.7,38,83.6,38
      L83.6,38z
      M199.1,32.7c3.1,0,4.8-1.3,6.2-2.6V10.4h5.4v19.7l3.8,3.9l-3.5,3.6l-2.8-2.8c0,0-3.7,3.2-9.1,3.2c-9,0-14-7.6-14-14V10.4
      h5.4v13.2C190.5,27.8,193.4,32.7,199.1,32.7L199.1,32.7z
      M97.5,0h5.4v37.6h-5.4V0z M115.9,10.7h6.8l-12.2,13.8l13.5,13.1h-7.4
      l-12.9-13.1L115.9,10.7z
      M247,0.1c1.6,0,3,1.3,3,3c0,1.7-1.4,3-3,3c-1.7,0-3.1-1.3-3.1-3C243.9,1.4,245.3,0.1,247,0.1z
      M247,5.7
      c1.4,0,2.5-1.1,2.5-2.6c0-1.4-1.1-2.6-2.5-2.6c-1.4,0-2.5,1.1-2.5,2.6C244.5,4.6,245.5,5.7,247,5.7z
      M245.8,1.3h1.4
      c0.9,0,1.2,0.3,1.2,1c0,0.6-0.4,0.9-0.9,1l1,1.6h-0.6l-1-1.5h-0.6v1.5h-0.5L245.8,1.3L245.8,1.3z
      M246.3,2.9h0.6 c0.5,0,0.9,0,0.9-0.6c0-0.5-0.4-0.5-0.8-0.5h-0.7V2.9z" />
  </svg>
{:else if icon === 'ticketmaster'}
  <svg class={svgClasses} viewBox="0 0 250 33.6">
    <path
      class="ticketmaster"
      d="M153.7,29.5L153.7,29.5c-2.1,2.6-4.1,4-7.4,4c-4.1,0-7-2.3-7-6.5c0-7.5,8.3-8,13.9-8h2.1
      c0.1-0.6,0.1-1.3,0.1-1.9c0-2.3-2.5-2.9-4.6-2.9c-2.6,0-5.1,0.6-7.5,1.8l0.9-4.7c2.5-0.9,5.1-1.4,7.7-1.4c4.6,0,8.8,1.6,8.8,7
      c0,3.1-2.3,12-2.8,16.2h-5L153.7,29.5
      M148,29.2c4.4,0,5.8-3.1,6.7-6.7h-2.1c-2.8,0-7.6,0.5-7.6,4.3
      C145,28.4,146.5,29.2,148,29.2z" />
    <path
      class="ticketmaster"
      d="M0.9,10.3h4.3l1-4.7l6.3-2.1l-1.4,6.8h5.3l-0.9,4.3h-5.3L8,24.5c-0.1,0.5-0.3,1.4-0.3,2.2
      c0,1.4,0.9,2.2,2.3,2.2c1.1,0,2.1-0.2,2.8-0.5l-1,4.6c-1,0.2-2.1,0.5-3.2,0.5c-4,0-6.8-1.6-6.8-5.9c0-1.8,0.4-3.5,0.8-5.3
      l1.6-7.7H0L0.9,10.3z" />
    <path
      class="ticketmaster"
      d="M18.8,10.3h5.9l-4.8,22.6h-5.9L18.8,10.3
      M20.9,0.6h5.9l-1.2,5.6h-5.9L20.9,0.6z" />
    <path
      class="ticketmaster"
      d="M42.5,15.5c-1.1-0.8-2.7-1.1-4.3-1.1c-4.7,0-7.3,4.4-7.3,8.7c0,2.9,1,5.9,5,5.9c1.4,0,3.3-0.4,4.8-1.1
      l-0.6,4.7c-1.8,0.9-3.8,1.1-5.7,1.1c-5.8,0-9.6-3.9-9.6-9.7c0-7.9,5.3-13.9,13.4-13.9c2.2,0,4.3,0.4,5.9,0.9L42.5,15.5z" />
    <polygon
      class="ticketmaster"
      points="48.9,0 54.9,0 50.9,18.6 51,18.6 59.3,10.3 66.9,10.3 56.1,20.6
      62.8,33 56.2,33 50.4,21 50.3,21 47.7,33 42,33 " />
    <path
      class="ticketmaster"
      d="M88.1,10.3h4.3l1-4.7l6.3-2.1l-1.4,6.8h5.3l-0.9,4.3h-5.3l-2.1,9.9C95.1,25,95,25.9,95,26.8
      c0,1.4,0.9,2.2,2.3,2.2c1.1,0,2.1-0.2,2.8-0.5l-1,4.6c-1,0.2-2.1,0.5-3.2,0.5c-4,0-6.8-1.6-6.8-5.9c0-1.8,0.4-3.5,0.8-5.3
      l1.6-7.7h-4.3L88.1,10.3z" />
    <path
      class="ticketmaster"
      d="M86.2,18.4c0-5.8-3.7-8.6-9.2-8.6C69.4,9.8,64,16.6,64,23.6c0,6.8,4.4,9.9,10.9,9.9c2.4,0,4.9-0.6,7.2-1.3
      l0.8-4.7c-2.2,1-4.6,1.6-7,1.6c-3.8,0-6-1.4-6.3-4.9l0,0c0-0.2,0-0.4,0-0.6c0-0.1,0-0.1,0-0.1v-0.1l0,0c0-1.6,0.4-3.1,0.9-4.5
      l0,0c1.1-2.9,2.7-4.9,6.2-4.9c2.5,0,3.8,1.4,3.8,3.8c0,0.5-0.1,1-0.1,1.6h-8c-0.5,1.8-0.6,3-0.6,4.1h13.9
      C85.9,21.8,86.2,20.1,86.2,18.4z" />
    <path
      class="ticketmaster"
      d="M106.1,12.8c0.1-0.9,0.4-1.8,0.5-2.5h5.5l-0.5,3.1h0.1c1.6-2.3,4.2-3.6,7-3.6c2.7,0,5.5,1.6,5.8,4.3h0.1
      c1.5-2.9,4.8-4.3,8-4.3c3.4,0,6.3,2.8,6.3,6.2c0,2-0.5,4.3-1,6.2L135.4,33h-5.9l2.3-11c0.3-1.4,0.8-3.5,0.8-4.8
      c0-1.6-1.3-2.7-2.8-2.7c-4.1,0-5.1,4.8-5.8,7.8L121.7,33h-5.9l2.3-11c0.3-1.4,0.8-3.5,0.8-4.8c0-1.6-1.3-2.7-2.8-2.7
      c-4,0-5.1,4.8-5.8,7.8L107.9,33H102L106.1,12.8z" />
    <path
      class="ticketmaster"
      d="M178.4,14.9c-0.6-0.4-2.3-0.9-3.9-0.9c-1.8,0-4,0.5-4,2.3c0,2.8,7.7,3.9,7.7,9.5c0,5.9-5.1,7.7-10.2,7.7
      c-2.3,0-4.8-0.4-6.9-1.4l1.3-4.7c1.6,0.9,3.3,1.8,5.6,1.8c2,0,4.1-0.6,4.1-2.5c0-3.6-7.7-4-7.7-9.7c0-5.3,5.1-7.2,9.8-7.2
      c2.1,0,4.3,0.4,5.5,0.7L178.4,14.9z" />
    <path
      class="ticketmaster"
      d="M182.1,10.3h4.3l1-4.7l6.3-2.1l-1.4,6.8h5.3l-0.9,4.3h-5.3l-2.1,9.9c-0.1,0.5-0.3,1.4-0.3,2.2
      c0,1.4,0.9,2.2,2.3,2.2c1.1,0,2.1-0.2,2.8-0.5l-1,4.6c-1,0.2-2.1,0.5-3.2,0.5c-4,0-6.8-1.6-6.8-5.9c0-1.8,0.4-3.5,0.7-5.3
      l1.7-7.7h-4.3L182.1,10.3z" />
    <path
      class="ticketmaster"
      d="M222.1,13.4c0.1-1,0.4-2,0.5-3.1h5.6l-0.8,4.3h0.1c1.1-2.5,4-4.8,6.7-4.8c0.8,0,1.6,0.1,2.3,0.2l-1.2,5.8
      c-0.6-0.2-1.5-0.4-2.3-0.4c-4.2,0-6.2,3.8-7,7.4L223.9,33H218L222.1,13.4z" />
    <path
      class="ticketmaster"
      d="M218.1,18.4c0-5.8-3.7-8.6-9.2-8.6c-7.4,0-12.9,6.8-12.9,13.8c0,6.8,4.4,9.9,10.9,9.9c2.4,0,4.9-0.6,7.2-1.3
      l0.8-4.7c-2.2,1-4.6,1.6-7,1.6c-3.8,0-6-1.4-6.3-4.9l0,0c0-0.2,0-0.4,0-0.6c0-0.1,0-0.1,0-0.1v-0.1l0,0c0-1.6,0.4-3.1,0.9-4.5
      l0,0c1.1-2.9,2.7-4.9,6.2-4.9c2.5,0,3.8,1.4,3.8,3.8c0,0.5-0.1,1-0.1,1.6h-8c-0.5,1.8-0.6,3-0.6,4.1h13.9
      C217.8,21.8,218.1,20.1,218.1,18.4z" />
    <path
      class="ticketmaster"
      d="M244.9,9c2.8,0,5.1,2.3,5.1,5.1c0,2.8-2.3,5.1-5.1,5.1c-2.8,0-5.1-2.3-5.1-5.1S242,9,244.9,9z
      M244.9,18.4
      c2.3,0,4.1-1.9,4.1-4.3s-1.8-4.3-4.1-4.3s-4.1,1.9-4.1,4.3C240.8,16.5,242.5,18.4,244.9,18.4z
      M242.9,11.1h2.3
      c1.4,0,2.1,0.6,2.1,1.7c0,0.9-0.6,1.6-1.6,1.6l1.7,2.6h-1.1l-1.6-2.6h-0.8v2.6h-1V11.1z
      M243.9,13.7h1.1c0.8,0,1.2-0.1,1.2-0.9 c0-0.7-0.6-0.9-1.2-0.9h-1.1V13.7z" />
  </svg>
{:else if icon === 'tailgate-guys'}
  <svg class={svgClasses} viewBox="0 0 250 22.7">
    <path
      class="tailgate-guys"
      d="M7.6,12.8c-0.7,0.4-1.5,0.7-2.1,1.2c0.7,0.5,1.4,0.8,2.1,1.1C7.8,15.3,8,15.2,8,14.8c0-0.6,0-1.1,0-1.7
      C8,12.8,7.9,12.6,7.6,12.8z" />
    <path
      class="tailgate-guys"
      d="M14.6,15.1c0.7-0.4,1.5-0.7,2.3-1.1c-0.9-0.4-1.6-0.8-2.3-1.2c-0.3-0.1-0.3,0-0.3,0.2c0,0.6,0,1.2,0,1.9
      C14.3,15.1,14.4,15.2,14.6,15.1z" />
    <path
      class="tailgate-guys"
      d="M11.2,6C10.5,6,10,6.5,10,7.2c0,0.7,0.5,1.1,1.2,1.1c0.6,0,1.1-0.5,1.1-1.1C12.3,6.5,11.8,6,11.2,6z" />
    <path
      class="tailgate-guys"
      d="M22.3,21.9c0-4.8,0-9.7,0-14.5c0-0.2,0-0.3,0-0.5c0-0.2-0.1-0.3-0.3-0.4c-3.3-2.1-6.7-4.1-10-6.2
      c-0.7-0.5-1.2-0.3-1.9,0.1c-3.2,2-6.4,4-9.6,6C0.2,6.6,0,6.8,0,7.2c0,5,0,9.9,0,14.9c0,0.5,0.1,0.6,0.6,0.6c2.3,0,4.5,0,6.8,0
      c0.5,0,0.6-0.1,0.6-0.6c0-1.6,0-3.1,0-4.7c0-0.4-0.1-0.7-0.5-0.9c-1.4-0.7-2.8-1.5-4.3-2.2c-0.2-0.1-0.5-0.2-0.5-0.4
      c0-0.2,0.2-0.3,0.4-0.4c1.8-0.9,3.5-1.8,5.3-2.7c0.6-0.3,0.9-0.2,0.9,0.5c0,3.6,0,7.2,0,10.8c0,0.5,0.1,0.5,0.5,0.5
      c0.8,0,1.6,0,2.4,0c0.6,0,0.7-0.2,0.7-0.7c0-3.6,0-7.1,0-10.7c0-0.7,0.1-0.8,0.7-0.5c1.8,0.9,3.6,1.8,5.3,2.7
      c0.2,0.1,0.5,0.1,0.5,0.4c0,0.3-0.3,0.4-0.5,0.5c-1.4,0.7-2.8,1.4-4.2,2.1c-0.3,0.2-0.5,0.4-0.5,0.8c0,1.6,0,3.2,0,4.8
      c0,0.4,0.1,0.6,0.6,0.6c2.3,0,4.5,0,6.8,0c0.3,0,0.5,0,0.6-0.3C22.4,22.3,22.3,22.1,22.3,21.9z
      M11.1,9.6c-1.4,0-2.5-1.1-2.5-2.5
      c0-1.3,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C13.6,8.6,12.5,9.6,11.1,9.6z" />
    <path
      class="tailgate-guys"
      d="M105.5,7.4c-1.6,0-3.7,1.9-4.1,4.1c-0.3,2,0.4,4.2,2.1,5.3c1.7,1.1,3.5,1.3,5.4,0.2c0.2-0.6,0.2-1.1,0-1.7
      c-0.4-0.2-0.9-0.2-1.3-0.1c-0.7,0.1-0.8-0.2-0.7-0.7c0-2.3-0.3-2,1.9-1.9c1.1,0,2.1-0.2,3.2,0.2c0.1,2.1,0.1,4.2,0,6.3
      c-2.3,1.4-4.8,1.8-7.3,1.5c-3-0.3-6-3.8-6.3-6.9c-0.3-2.8,0.4-5.2,2.3-7.3c2.7-2.8,7.8-2.9,10.7-0.4c0.5,0.4,0.8,0.8,0.1,1.3
      c-0.1,0.1-0.2,0.2-0.3,0.4c-0.6,0.8-1.2,1.1-2.1,0.4c-0.5-0.4-1-0.8-1.7-0.8C106.6,7.3,106,7.3,105.5,7.4z" />
    <path
      class="tailgate-guys"
      d="M194.4,19.1c-2.3,1.4-4.8,2-7.3,1.5c-2.6-0.5-4.7-2.1-5.7-4.8c-1.5-3.4-0.6-7.2,2-9.5c3-2.7,7.5-2.6,10.5-0.1
      c0.4,0.3,0.6,0.7,0.1,1.1c-0.1,0.1-0.2,0.2-0.3,0.4c-0.9,1.1-0.9,1.1-2.2,0.3c-1.9-1.2-4.2-1-5.8,0.5c-1.7,1.6-2.3,4.2-1.3,6.2
      c1,2.1,3.1,3.3,5.3,2.9c0.3-0.1,0.6-0.1,0.9-0.2c0.9-0.2,1.4-1.2,1-2c-0.2-0.5-0.7-0.1-1.1-0.2c-0.4-0.1-1,0.2-1.1-0.4
      c-0.1-0.7-0.2-1.4,0.1-2.1c0-0.1,0.2-0.1,0.2-0.1c1.6,0.1,3.1-0.3,4.7,0.2C194.5,14.9,194.5,17,194.4,19.1z" />
    <path
      class="tailgate-guys"
      d="M202.4,5.4c0-0.7,0.2-0.9,0.9-0.8c2.5,0.1,2.2-0.3,2.2,2.3c0,2.5,0,4.9,0,7.4c0,1.4,0.5,2.5,1.7,3.2
      c1.2,0.7,2.5,0.4,3.5-0.3c1-0.7,1.3-2,1.3-3.2c0-2.8,0-5.5,0-8.3c0-0.8,0.2-1,1-1c2,0.1,2,0,2,2c0,2.6,0,5.3,0.1,7.9
      c-0.2,0.3,0,0.7-0.1,1.1c-0.6,2.3-1.8,4.1-4.2,4.9c-4,1.2-7.5-1.1-8.1-5.1C202.3,15,202.4,8.3,202.4,5.4z" />
    <path
      class="tailgate-guys"
      d="M165.4,13.4c-0.1,0.1-0.3,0.3-0.4,0.4c-1.6,0.1-3.1,0-4.7,0c-0.6,0-0.8,0.2-0.7,0.7c0.1,1-0.1,2.1,0.1,3.1
      c1.9,0,3.9,0.1,5.8,0c0.8,0,1,0.3,1,1c0,1.7,0,1.7-1.7,1.7c-2.5,0-4.9,0-7.4,0c-0.7,0-0.9-0.2-0.9-0.9c0-4.8,0-9.6,0-14.4
      c0-0.6,0.2-0.8,0.7-0.8c2.8,0,5.6,0,8.4,0c0.6,0,0.9,0.2,0.8,0.8c0,0.6,0.1,1.3-0.1,1.9c-0.8,0.3-1.7,0.1-2.5,0.1
      c-1.2,0-2.4,0-3.6,0c-0.5,0-0.8,0.1-0.7,0.7c0.1,1-0.1,2,0.1,2.9c1.6,0,3.2,0,4.7,0c0.4,0,0.8-0.1,1,0.4
      C165.5,12.1,165.5,12.7,165.4,13.4z" />
    <path
      class="tailgate-guys"
      d="M239.7,8.2c0.3-3,2.7-4,5.4-3.9c1.3,0,2.5,0.5,3.7,1.2c0.6,0.4,0.9,0.8,0.3,1.4c-0.2,0.2-0.3,0.5-0.5,0.7
      c-0.3,0.5-0.7,0.7-1.3,0.3s-1.2-0.7-1.9-0.9c-1.1-0.4-2.3-0.1-2.7,0.8c-0.4,0.8,0.1,1.9,1.1,2.5c1.1,0.7,2.5,1.1,3.6,1.8
      c1.2,0.8,2.1,1.8,2.4,3.2c0.1,0.5,0.1,1.1,0,1.6c-0.6,2.4-3.1,3.5-4.6,3.8c-0.2,0.1-0.6,0.1-1,0c-1.5-0.2-2.9-0.6-4.2-1.4
      c-0.5-0.4-0.7-0.7-0.4-1.3c0.9-1.6,0.9-1.6,2.6-0.7c0.5,0.3,2.6,0.8,3,0.6c0.7-0.3,1.3-0.7,1.3-1.5c0.1-0.8-0.4-1.5-1.2-1.9
      c-0.9-0.5-1.9-0.9-2.8-1.4c-1.5-0.8-2.6-1.9-3-3.7C239.7,9,239.7,8.6,239.7,8.2z" />
    <path
      class="tailgate-guys"
      d="M229.1,20.3c-0.8,0.4-1.6,0.2-2.4,0.2c-0.5,0-0.4-0.5-0.4-0.8c0-1.6-0.1-3.2,0-4.7c0.1-1.4-0.3-2.5-1.1-3.6
      c-1.3-1.9-2.5-3.7-3.8-5.6c-0.2-0.3-0.3-0.6-0.2-0.9c0.1-0.4,0.5-0.2,0.8-0.2c1.5-0.3,2.5,0.3,3.2,1.7c0.7,1.3,1.6,2.4,2.5,3.7
      c0.9-1.3,1.8-2.6,2.7-3.9c0.3-0.5,0.6-1.3,1.1-1.4c0.7-0.2,1.6-0.1,2.5,0c0.8,0.1,0.4,0.6,0.2,1c-1.2,1.8-2.5,3.6-3.7,5.4
      c-0.4,0.6-0.9,1.1-1.1,1.7c-0.2,2.2,0,4.3-0.1,6.5C229.2,19.6,229.3,20,229.1,20.3z" />
    <path
      class="tailgate-guys"
      d="M145,20.1c-0.4,0.3-2.5,0.6-2.8,0.3c-0.3-0.3-0.2-0.6-0.2-0.9c0-3.7,0-7.3,0-11c0-0.4,0.1-0.7-0.1-1.1
      c-1.2-0.3-2.4,0-3.5-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.7-0.1-1.5,0-2.2c0.2-0.4,0.6-0.3,1-0.3c2.8,0,5.7,0,8.5,0
      c0.9,0,1.3,0.3,1.1,1.2c-0.1,0.5,0.1,1-0.1,1.5c-0.8,0.3-1.7,0.1-2.5,0.1c-0.4,0-0.7-0.1-1.1,0.1l0,0c-0.2,0.7-0.1,1.5-0.1,2.3
      C145,13.2,145.1,16.6,145,20.1z" />
    <path
      class="tailgate-guys"
      d="M34.7,7.3c-0.4-0.7-0.2-1.5-0.2-2.3c0-0.4,0.4-0.4,0.7-0.4c3,0,6.1,0,9.1,0c0.6,0,0.9,0.2,0.8,0.8
      c0,0.6,0.1,1.3-0.2,1.9c-0.8,0.3-1.7,0.1-2.5,0.1c-0.4,0-0.7-0.1-1.1,0.1l0,0c-0.2,0.7-0.1,1.5-0.1,2.3c0,3.2,0,6.5,0,9.7
      c0,0.3,0.1,0.7-0.1,1c-0.8,0.4-1.6,0.2-2.4,0.2c-0.6,0-0.5-0.6-0.5-1c0-3.7,0-7.4,0-11.1c0-0.3,0.1-0.7-0.1-1
      C37,7.2,35.8,7.5,34.7,7.3z" />
    <path
      class="tailgate-guys"
      d="M83.7,4.9c0.8-0.6,1.7-0.3,2.5-0.2c0.5,0,0.3,0.6,0.4,1c0,3.7,0,7.5,0,11.2c0,0.8,0.2,1,1,1
      c1.4-0.1,2.8,0,4.3,0c0.7,0,0.9,0.2,0.9,0.9c0.1,1.9,0.1,1.9-1.7,1.9c-2.2,0-4.3,0-6.5,0c-0.3,0-0.8,0.2-0.8-0.4
      c-0.1-3.8,0-7.7,0-11.5C83.6,7.4,83.5,6.1,83.7,4.9z" />
    <path
      class="tailgate-guys"
      d="M74.1,20.3c-0.8,0.4-1.7,0.2-2.5,0.2c-0.5,0-0.4-0.6-0.4-0.9c0-2.9,0-5.8,0-8.7c0-1.8,0-3.6,0-5.5
      c0-0.7,0.2-0.9,0.9-0.9c0.7,0.1,1.3,0,2,0.1c0.2,0.1,0.1,0.4,0.1,0.6c0,4.8,0,9.7,0,14.5C74.2,20,74.3,20.2,74.1,20.3z" />
    <path
      class="tailgate-guys"
      d="M133.1,19.4c-0.8-1.9-1.6-3.7-2.5-5.5c-1.3-3-2.7-6-4.1-9c-0.1-0.3-0.3-0.5-0.7-0.6c-0.5-0.1-0.4,0.3-0.5,0.5
      c-2.1,4.7-4.3,9.4-6.4,14.1c-0.7,1.6-0.7,1.6,1.1,1.6c0.9,0,1.5-0.2,1.8-1.1c0.2-0.6,0.5-1.1,0.8-1.7c2.2-0.2,4.4-0.2,6.7,0
      c0.2,0.3,0.3,0.7,0.5,1.1c0.7,1.7,0.7,1.7,2.7,1.7C133.5,20.5,133.6,20.4,133.1,19.4z
      M127.6,15.1c-1.3,0-2.5,0-3.9,0
      c0.8-1.7,1.4-3.3,2.2-5c0.7,1.6,1.4,3.1,2,4.5C128.2,15,128,15.1,127.6,15.1z" />
    <path
      class="tailgate-guys"
      d="M63.6,19.2c-1.1-2.3-2.1-4.7-3.1-7c-1.1-2.4-2.2-4.7-3.2-7.1c-0.2-0.3-0.3-0.7-0.7-0.7
      c-0.5,0-0.5,0.4-0.7,0.8C55.4,5.7,55,6.4,54.8,7c-1.7,3.9-3.5,7.8-5.2,11.7c-0.8,1.9-0.8,1.8,1.1,1.9c0.8,0,1.3-0.2,1.5-1
      c0.2-0.6,0.6-1.2,0.9-1.8c2.2-0.2,4.4-0.1,6.7,0c0.3,0.7,0.7,1.3,0.9,2c0.2,0.6,0.5,0.8,1,0.8c0.4,0,0.7,0,1.1,0
      C64,20.5,64.1,20.4,63.6,19.2z
      M54.2,15c0.7-1.7,1.4-3.2,2.2-5c0.8,1.7,1.5,3.3,2.2,5C57.2,15,55.8,15,54.2,15z" />
  </svg>
{:else if icon === 'database'}
  <svg class={largeClasses} viewBox="0 0 215 247">
    <path
      d="M170.119048,156.087302 C170.119048,142.515873 132.234127,131.511905
      85.5039683,131.511905 C38.7738095,131.511905 0.888888889,142.511905
      0.888888889,156.087302 L0.888888889,202.468254 C0.888888889,216.039683
      38.7738095,227.043651 85.5039683,227.043651 C132.238095,227.043651
      170.119048,216.039683 170.119048,202.468254 L170.119048,156.087302
      L170.119048,156.087302 Z"
      id="Path"
      fill="#1D6CCC"
      fill-rule="nonzero" />
    <ellipse
      id="Oval"
      fill="#2487FF"
      fill-rule="nonzero"
      cx="85.5039683"
      cy="156.087302"
      rx="84.6150794"
      ry="24.5753968" />
    <path
      d="M170.119048,88.5119048 C170.119048,74.9404762 132.234127,63.9365079
      85.5039683,63.9365079 C38.7738095,63.9365079 0.888888889,74.9404762
      0.888888889,88.5119048 L0.888888889,134.892857 C0.888888889,148.464286
      38.7738095,159.468254 85.5039683,159.468254 C132.238095,159.468254
      170.119048,148.464286 170.119048,134.892857 L170.119048,88.5119048
      L170.119048,88.5119048 Z"
      id="Path"
      fill="#1D6CCC"
      fill-rule="nonzero" />
    <path
      d="M134.638889,108.52381 C120.785714,111.396825 103.825397,113.087302
      85.5039683,113.087302 C38.7698413,113.087302 0.888888889,102.083333
      0.888888889,88.5119048 C0.888888889,74.9404762 38.7738095,63.9365079
      85.5039683,63.9365079 C132.234127,63.9365079 170.119048,74.9404762
      170.119048,88.5119048 C170.119048,93.6031746 164.789683,98.3293651
      155.666667,102.253968"
      id="Path"
      fill="#2487FF"
      fill-rule="nonzero" />
    <path
      d="M144.492063,106.130952 C143.81746,106.321429 143.130952,106.507937
      142.436508,106.694444"
      id="Path" />
    <path
      d="M170.119048,25.1666667 C170.119048,11.5952381 132.234127,0.591269841
      85.5039683,0.591269841 C38.7738095,0.591269841 0.888888889,11.5912698
      0.888888889,25.1666667 L0.888888889,71.547619 C0.888888889,85.1190476
      38.7738095,96.1230159 85.5039683,96.1230159 C132.238095,96.1230159
      170.119048,85.1190476 170.119048,71.547619 L170.119048,25.1666667
      L170.119048,25.1666667 Z"
      id="Path"
      fill="#1D6CCC"
      fill-rule="nonzero" />
    <path
      d="M11.8134921,37.2539683 C4.85714286,33.6865079 0.888888889,29.5595238
      0.888888889,25.1666667 C0.888888889,11.5952381 38.7738095,0.591269841
      85.5039683,0.591269841 C132.234127,0.591269841 170.119048,11.5952381
      170.119048,25.1666667 C170.119048,38.7380952 132.234127,49.7420635
      85.5039683,49.7420635 C62.7420635,49.7420635 42.0793651,47.1309524
      26.8730159,42.8849206"
      id="Path"
      fill="#2487FF"
      fill-rule="nonzero" />
    <path
      d="M21.515873,41.2460317 C21.031746,41.0833333 20.5555556,40.9206349
      20.0873016,40.7539683"
      id="Path" />
    <circle
      id="Oval"
      fill="#FAFAFA"
      fill-rule="nonzero"
      cx="149.892857"
      cy="180.662698"
      r="57.1071429" />
    <path
      d="M147.34127,108.920635 L145.309524,109.47619 C144.25,109.757937
      143.619048,110.849206 143.904762,111.904762 C144.142857,112.793651
      144.944444,113.376984 145.821429,113.376984 C145.988095,113.376984
      146.162698,113.357143 146.333333,113.309524 L148.416667,112.738095
      C149.472222,112.440476 150.083333,111.34127 149.789683,110.289683
      C149.492063,109.238095 148.40873,108.630952 147.34127,108.920635 Z"
      id="Path"
      fill="#222222" />
    <path
      d="M25.5357143,44.0634921 L24.1349206,43.5833333 C23.1230159,43.2063492
      21.9722222,43.7539683 21.6031746,44.7896825 C21.234127,45.8214286
      21.7738095,46.9563492 22.8095238,47.3214286 L24.2698413,47.8253968
      C24.4801587,47.9007937 24.6904762,47.9285714 24.9007937,47.9285714
      C25.7301587,47.9285714 26.5,47.4047619 26.781746,46.5753968
      C27.1309524,45.5357143 26.5714286,44.4126984 25.5357143,44.0634921 Z"
      id="Path"
      fill="#222222" />
    <path
      d="M178.571429,129.464286 L178.571429,93.015873 C178.571429,90.031746
      177.396825,87.2896825 175.329365,84.7579365 C177.424603,82.2142857
      178.571429,79.4404762 178.571429,76.4325397 L178.571429,29.6706349
      C178.571429,10.7936508 133.654762,0.920634921 89.2857143,0.920634921
      C44.9166667,0.920634921 0,10.7936508 0,29.6706349 L0,76.4325397
      C0,79.4325397 1.14285714,82.2063492 3.23412698,84.75
      C1.15873016,87.2777778 0,90.031746 0,93.015873 L0,139.781746 C0,143.630952
      1.89285714,147.099206 5.24603175,150.194444 C1.8968254,153.285714
      0,156.746032 0,160.59127 L0,207.357143 C0,226.230159 44.9166667,236.103175
      89.2857143,236.103175 C98.4880952,236.103175 107.686508,235.650794
      116.531746,234.805556 C126.710317,242.265873 139.218254,246.730159
      152.777778,246.730159 C186.694444,246.730159 214.285714,219.138889
      214.285714,185.222222 C214.285714,160.527778 199.623016,139.242063
      178.571429,129.464286 Z M152.777778,123.718254 C127.666667,123.718254
      106.051587,138.865079 96.5079365,160.484127 C94.1468254,160.547619
      91.7579365,160.595238 89.2857143,160.595238 C38.8849206,160.595238
      7.93650794,148.472222 7.93650794,139.781746 L7.93650794,102.952381
      C21.1349206,113.638889 55.4563492,119.769841 88.8888889,119.769841
      C106.761905,119.769841 123.888889,118.178571 138.424603,115.162698
      C139.5,114.940476 140.186508,113.892857 139.964286,112.81746
      C139.742063,111.742063 138.670635,111.043651 137.619048,111.277778
      C123.345238,114.234127 106.492063,115.801587 88.8888889,115.801587
      C46.4801587,115.801587 15.2896825,106.769841 7.93650794,97.3809524
      L7.93650794,93.015873 C7.93650794,92.0198413 8.40079365,90.9722222
      9.21031746,89.9087302 C24.6071429,99.9801587 57.0833333,105.186508
      89.2857143,105.186508 C121.496032,105.186508 153.984127,99.9761905
      169.373016,89.9007937 C170.186508,90.968254 170.634921,92.015873
      170.634921,93.015873 L170.634921,96.2738095 C168.900794,99.3214286
      164.662698,102.380952 158.27381,105.130952 C157.265873,105.559524
      156.801587,106.730159 157.234127,107.734127 C157.662698,108.746032
      158.833333,109.210317 159.837302,108.77381 C164.452381,106.785714
      168.031746,104.595238 170.634921,102.265873 L170.634921,126.384921
      C164.980159,124.662698 158.988095,123.718254 152.777778,123.718254 Z
      M91.3730159,183.18254 C90.0238095,183.206349 88.6865079,183.242063
      87.3015873,183.242063 C47.9642857,183.242063 18.234127,175.861111
      7.93650794,167.619048 L7.93650794,160.59127 C7.93650794,158.789683
      9.31349206,156.837302 11.8968254,154.880952 C28.1746032,163.888889
      58.8571429,168.531746 89.2857143,168.531746 C90.7420635,168.531746
      92.1944444,168.492063 93.6468254,168.472222 C92.3134921,173.174603
      91.5396825,178.099206 91.3730159,183.18254 Z M7.93650794,76.4325397
      L7.93650794,39.6349206 C9.67063492,41.047619 11.7619048,42.4087302
      14.2936508,43.7103175 C15.265873,44.2142857 16.468254,43.8333333
      16.9642857,42.8531746 C17.468254,41.8769841 17.0793651,40.6825397
      16.1071429,40.1825397 C13.1626984,38.6666667 9.87301587,36.5634921
      7.93650794,34.0714286 L7.93650794,29.6706349 C7.93650794,20.9801587
      38.8849206,8.85714286 89.2857143,8.85714286 C139.686508,8.85714286
      170.634921,20.9801587 170.634921,29.6706349 L170.634921,32.7380952
      C165.261905,42.5753968 133.246032,52.452381 88.8888889,52.452381
      C67.0992063,52.452381 46.468254,50.0436508 30.7896825,45.6706349
      C29.7222222,45.3690476 28.6388889,45.9960317 28.3452381,47.047619
      C28.0515873,48.1071429 28.6666667,49.1984127 29.7222222,49.4920635
      C45.7380952,53.9603175 66.75,56.4206349 88.8888889,56.4206349
      C123.02381,56.4206349 158.075397,50.0277778 170.634921,38.9246032
      L170.634921,76.4325397 C170.634921,85.1230159 139.686508,97.2460317
      89.2857143,97.2460317 C38.8849206,97.2460317 7.93650794,85.1269841
      7.93650794,76.4325397 Z M7.93650794,207.357143 L7.93650794,172.666667
      C22.6904762,182.178571 55.7301587,187.210317 87.3015873,187.210317
      C88.6547619,187.210317 90.0119048,187.178571 91.3690476,187.15873
      C91.8571429,202.765873 98.1587302,216.900794 108.214286,227.496032
      C102.238095,227.896825 95.9960317,228.166667 89.2896825,228.166667
      C38.8849206,228.170635 7.93650794,216.047619 7.93650794,207.357143 Z
      M152.777778,238.797619 C123.238095,238.797619 99.2063492,214.765873
      99.2063492,185.22619 C99.2063492,155.686508 123.238095,131.654762
      152.777778,131.654762 C182.31746,131.654762 206.349206,155.686508
      206.349206,185.22619 C206.349206,214.765873 182.31746,238.797619
      152.777778,238.797619 Z"
      id="Shape"
      fill="#222222" />
    <path
      d="M178.079365,183.376984 L155.261905,183.376984 L155.261905,159.31746
      C155.261905,158.222222 154.373016,157.333333 153.277778,157.333333
      C152.18254,157.333333 151.293651,158.222222 151.293651,159.31746
      L151.293651,183.376984 L128.47619,183.376984 C127.380952,183.376984
      126.492063,184.265873 126.492063,185.361111 C126.492063,185.90873
      126.714286,186.404762 127.071429,186.765873 C127.428571,187.126984
      127.924603,187.345238 128.47619,187.345238 L151.293651,187.345238
      L151.293651,211.400794 C151.293651,211.948413 151.515873,212.444444
      151.873016,212.805556 C152.230159,213.166667 152.72619,213.384921
      153.277778,213.384921 C154.373016,213.384921 155.261905,212.496032
      155.261905,211.400794 L155.261905,187.345238 L178.079365,187.345238
      C179.174603,187.345238 180.063492,186.456349 180.063492,185.361111
      C180.063492,184.265873 179.174603,183.376984 178.079365,183.376984 Z"
      id="Path"
      fill="#1F212B" />
  </svg>
{/if}
