<script>
  import TIIcon from "./TIIcon.svelte";

  let clientWidth;

  $: iconHeight = clientWidth > 882 ? 60 : 48;
</script>

<style>
  .trust-indicators-wrapper {
    width: 100%;
    padding: 64px 162px 0;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 1248px) {
    .trust-indicators-wrapper {
      padding: 64px 72px 0;
    }
  }

  .ti-header {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
  }
  .ti-strikethrough {
    flex-grow: 1;
    height: 1px;
    background-color: #000;
  }
  .ti-header-text {
    padding: 0 6px;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }

  .conferences {
    height: 60px;
    margin: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .conference + .conferences {
    margin-top: 0;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    .trust-indicators-wrapper {
      padding: 0 20px;
    }

    .ti-header-text {
      padding: 0;
    }

    .conferences {
      height: auto;
      flex-wrap: wrap;
    }
  }
</style>

<div class="trust-indicators-wrapper" bind:clientWidth>
  <div class="ti-header">
    {#if clientWidth > 608}
      <div class="ti-strikethrough" />
    {/if}
    <div>
      <p class="ti-header-text">
        Built to manage athletic departments of all sizes
      </p>
    </div>
    {#if clientWidth > 608}
      <div class="ti-strikethrough" />
    {/if}
  </div>
  {#if clientWidth > 608}
    <div class="conferences">
      <TIIcon height={iconHeight} icon="acc" />
      <TIIcon height={iconHeight} icon="big-10" />
      <TIIcon height={iconHeight} icon="big-12" />
      <TIIcon height={iconHeight} icon="sec" />
      <TIIcon height={iconHeight} icon="pac-12" />
      <TIIcon height={iconHeight} icon="mountain-west" />
      <TIIcon height={iconHeight} icon="american" />
    </div>
  {:else}
    <div class="conferences">
      <TIIcon height={iconHeight} icon="acc" />
      <TIIcon height={iconHeight} icon="big-10" />
      <TIIcon height={iconHeight} icon="big-12" />
    </div>
    <div class="conferences">
      <TIIcon height={iconHeight} icon="sec" />
      <TIIcon height={iconHeight} icon="pac-12" />
      <TIIcon height={iconHeight} icon="mountain-west" />
      <TIIcon height={iconHeight} icon="american" />
    </div>
  {/if}
</div>
