<script>
  import TopLink from "../common/TopLink.svelte";
  import NickelLogo from "../common/NickelLogo.svelte";
</script>

<style>
  .wrapper {
    position: relative;
    min-height: 100vh;
    width: 100vw;
    padding: 45px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #2487ff;
    background-image: linear-gradient(45deg, #2487ff 0%, #1d6ccc 100%);
    overflow: hidden;
  }

  :global(.header) {
    position: relative;
    z-index: 1;
    flex-shrink: 0;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    flex-shrink: 0;
    width: 382px;
  }

  .title {
    max-width: 382px;
    margin-top: 45px;
    margin-bottom: 20px;
    color: #fff;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.8px;
    text-align: center;
  }

  .fine-print {
    max-width: 382px;
    width: 100%;
    margin: 0px;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
  }

  .back-button {
    height: 60px;
    width: 200px;
    margin-top: 40px;
    padding: 0;
    border-radius: 5px;
    outline: none;
    border: 2px solid #fff;
    color: #fff;
    background-color: transparent;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.5px;
    cursor: pointer;
    text-decoration: none;
  }
  .back-button:active {
    opacity: 0.9;
  }

  img {
    width: 586px;
    margin-top: 65px;
    margin-bottom: -175px;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    .wrapper {
      height: 100vh;
      padding: 45px 0px;
    }

    .content,
    .title,
    .fine-print {
      max-width: 335px;
    }

    .back-button {
      height: 60px;
      width: 176px;
      margin-top: 40px;
    }

    img {
      width: 335px;
      margin-top: 40px;
      margin-bottom: -175px;
    }
  }
  @media only screen and (max-width: 372px) {
    .content,
    .title,
    .fine-print {
      max-width: calc(100vw - 20px);
    }

    img {
      width: calc(100vw - 20px);
      margin-top: 40px;
      margin-bottom: -175px;
    }
  }
</style>

<div class="wrapper">
  <TopLink class="header" href="/">
    <NickelLogo light={true} />
  </TopLink>
  <div class="content">
    <h1 class="title">Thank you</h1>
    <p class="fine-print">
      We can't wait to chat with you. Look for an email from us soon!
    </p>
    <TopLink href="/">
      <button class="back-button">BACK TO NICKEL</button>
    </TopLink>
    <img
      src="assets/nickel-tablet.png"
      alt="Stylized Nickel app running on a tablet." />
  </div>
</div>
