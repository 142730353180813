<script>
  export let placeholder = "Select one...";
  export let value = "";
  export let onSelect = () => null;

  const handleSelection = selection => e => {
    e.stopPropagation();
    onSelect(selection);
    open = false;
  };
  export let options = [];

  export let open = false;
  const toggleOpen = () => options && options.length && (open = !open);

  $: containerClasses = `dropdown${value ? " selected" : ""}${
    open ? " open" : ""
  }`;
</script>

<style>
  .dropdown {
    position: relative;
    z-index: 1;
    height: 42px;
    width: 322px;
    margin-top: 12px;
    margin-bottom: 24px;
    padding: 0px 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .dropdown.selected {
    color: #fff;
    background-color: #2487ff;
  }
  .dropdown.open {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .dropdown-selection {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.5;
  }
  .selected .dropdown-selection {
    opacity: 1;
  }

  .dropdown svg {
    height: 8px;
    width: 16px;
    fill: rgba(0, 0, 0, 0.1);
    stroke: #888;
  }
  .dropdown.selected svg {
    stroke: #fff;
  }

  .options-top-mask {
    position: absolute;
    z-index: 1;
    top: calc(100% - 8px);
    left: 0;
    height: 8px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
  }
  .selected .options-top-mask {
    background-color: #2487ff;
  }

  .options {
    position: absolute;
    z-index: 0;
    top: 100%;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    overflow-y: hidden;
  }

  .option-line {
    height: 40px;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .option-line:hover {
    background-color: #fafafa;
  }

  .option-text {
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    .dropdown {
      width: 276px;
    }
  }
</style>

<div class={containerClasses} on:click={toggleOpen}>
  <p class="dropdown-selection">{value || placeholder}</p>
  <svg viewBox="0 0 24 12">
    <line
      x1="1"
      y1="1"
      x2="12"
      y2="11"
      stroke-width="2"
      stroke-linecap="round" />
    <line
      x1="12"
      y1="11"
      x2="23"
      y2="1"
      stroke-width="2"
      stroke-linecap="round" />
  </svg>
  {#if open}
    <div class="options-top-mask" />
    <div class="options">
      {#each options as option}
        <div class="option-line" on:click={handleSelection(option)}>
          <div class="option-text">{option}</div>
        </div>
      {/each}
    </div>
  {/if}
</div>
