<script>
  export let type = "text";
  export let placeholder = "";
  export let value = "";
  export let onInput = e => (value = e.target.value);
  export let onBlur = () => null;
  export let error;

  $: inputStyles = error ? "border-right: 6px solid #E0614A;" : "";
</script>

<style>
  .nickel-input {
    position: relative;
    width: 100%;
    height: 40px;
  }

  .nickel-input + .nickel-input {
    margin-top: 16px;
  }

  .nickel-input input {
    height: 100%;
    width: 100%;
    padding: 0px 8px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 34px;
    border-radius: 3px;
    outline: none;
    border: none;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  }
  .nickel-input input::placeholder {
    color: #222;
    opacity: 0.5;
    font-family: Montserrat;
  }

  .nickel-input .nickel-input-error {
    width: 100%;
    margin: 0px;
    margin-top: 4px;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
  }

  .nickel-input .nickel-input-error-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
    height: 16px;
    width: 16px;
  }
</style>

<div class="nickel-input">
  <input
    style={inputStyles}
    {type}
    {placeholder}
    {value}
    on:input={onInput}
    on:blur={onBlur} />
  {#if error}
    <svg class="nickel-input-error-icon" viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="8" fill="#E0614A" />
      <line
        x1="5.5"
        y1="5.5"
        x2="10.5"
        y2="10.5"
        stroke="#FFF"
        strokeWidth="1.5"
        strokeLinecap="round" />
      <line
        x1="10.5"
        y1="5.5"
        x2="5.5"
        y2="10.5"
        stroke="#FFF"
        strokeWidth="1.5"
        strokeLinecap="round" />
    </svg>
    <p class="nickel-input-error">{error}</p>
  {/if}
</div>
