<script>
  import CircleIcon from "../common/CircleIcon.svelte";

  export let title;
  export let copy;
  export let icon;
  export let selected = false;
  export let onClick = () => null;
</script>

<style>
  .opt {
    position: relative;
    margin-bottom: 40px;
    display: flex;
    cursor: pointer;
  }
  .opt:last-of-type {
    margin-bottom: 0px;
  }
  .opt:active {
    opacity: 0.9;
  }

  .icon-col {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon-line {
    flex: 1;
    width: 1px;
    margin: 10px 0px;
    background-color: #1d6ccc;
  }

  .opt-title {
    margin: 0px;
    margin-top: 5px;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.5px;
  }

  .opt-body {
    max-width: calc(100% - 38px); /* for absolutely-positioned toggle */
  }

  .opt-body-text {
    font-size: 16px;
    line-height: 24px;
  }

  .selection-toggle {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    height: 29px;
    width: 29px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 2px solid rgba(34, 34, 34, 0.25);
    border-radius: 50%;
  }
  .selection-toggle.selected {
    background-color: #1fb589;
    border: none;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
  .selection-toggle svg {
    width: 100%;
    fill: #fff;
  }
</style>

<div class="opt" on:click={onClick}>
  <div class="icon-col">
    <CircleIcon {icon} size="small" />
    <div class="icon-line" />
  </div>
  <div class="copy-col">
    <h4 class="opt-title">{title}</h4>
    <div class="opt-body">
      <p class="opt-body-text">{copy}</p>
      {#if selected}
        <div class="selection-toggle selected">
          <svg viewBox="0 0 26 22">
            <path
              transform="translate(5, 6)"
              d="M14.8032409,0.58680557 C14.6492861,0.59139291
              14.5031648,0.655736122 14.3958335,0.766203733 L5.33333333,9.828704
              L1.60416664,6.09953719 C1.45553422,5.94472955
              1.23482371,5.88236947 1.02715541,5.93650705
              C0.819487113,5.99064463 0.657311402,6.1528203
              0.603173762,6.36048858 C0.549036123,6.56815687
              0.611396136,6.78886739 0.766203733,6.93749985 L4.914352,11.085648
              C5.14577707,11.3169772 5.52088959,11.3169772 5.75231467,11.085648
              L15.2337961,1.60416664 C15.4090616,1.43380139
              15.4617599,1.17331277 15.3664981,0.948218378
              C15.2712364,0.723123987 15.047557,0.579600588
              14.8032409,0.58680557 Z"
              id="Path" />
          </svg>
        </div>
      {:else}
        <div class="selection-toggle" />
      {/if}
    </div>
  </div>
</div>
