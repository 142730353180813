<script>
  export let icon = "foam-finger";
  export let size = "normal";

  $: wrapperClasses = `vp-icon${size === "small" ? " small" : ""}`;
</script>

<style>
  .vp-icon {
    height: 60px;
    width: 60px;
  }
  .small {
    height: 36px;
    width: 36px;
  }
</style>

{#if ['foam-finger', 'crm', 'revenue'].includes(icon)}
  <svg class={wrapperClasses} viewBox="0 0 60 60">
    <defs>
      <polygon
        id="path-1"
        points="0 8e-05 18.0288 8e-05 18.0288 23.41176 0 23.41176" />
    </defs>
    <g id="icon">
      <circle id="back" fill="#2487FF" cx="30" cy="30" r="30" />
      <path
        d="M30,60 C46.5685425,60 60,46.5685425 60,30 C60,13.4314575
        13.4314575,60 30,60 Z"
        id="tint"
        fill="#1D6CCC"
        opacity="0.5" />
      {#if icon === 'foam-finger'}
        <g transform="translate(20, 18)">
          <mask id="mask-2" fill="white">
            <use xlink:href="#path-1" />
          </mask>
          <g id="Clip-2" />
          <path
            d="M8.21624,11.84648 C8.32984,11.82888 8.43464,11.81848
            8.55464,11.84648 L8.58264,11.87448 L8.63864,11.87448
            L14.43464,12.63448 L14.49144,12.63448 C14.49144,12.63448
            14.77304,12.65208 15.16664,12.88808 C15.56104,13.12328
            15.98264,13.49608 16.15224,14.46328 C16.26104,15.08568
            16.11304,15.93688 16.03864,16.18008 L15.36424,18.43128
            C14.78024,20.32328 13.03864,21.61128 11.05864,21.61128
            L6.30264,21.61128 C3.80584,21.61128 1.80024,19.60568
            1.80024,17.10888 L1.80024,13.33768 C2.08584,13.44008
            2.38504,13.50648 2.70104,13.50648 C3.39064,13.50648 4.02424,13.23608
            4.50184,12.80328 C4.85384,13.12008 5.29064,13.35208 5.76824,13.45048
            C5.82824,13.64728 5.91624,13.83368 6.02184,14.01288
            C6.36984,14.59368 6.92984,15.00888 7.62584,15.25128
            L7.68184,15.28008 L7.70984,15.28008 L11.03064,16.15208
            L10.77704,16.60168 L12.35304,17.47448 L13.16904,15.98248
            L13.70344,14.96968 L12.60584,14.68888 L8.24424,13.56328
            C7.84344,13.42248 7.65784,13.23608 7.56904,13.08488
            C7.48104,12.93368 7.44264,12.78568 7.51304,12.49368
            C7.59784,12.13848 7.88264,11.90248 8.21624,11.84648 M2.70104,8.10408
            C3.21144,8.10408 3.60184,8.49448 3.60184,9.00488 L3.60184,10.80568
            C3.60184,11.31528 3.21144,11.70568 2.70104,11.70568
            C2.19144,11.70568 1.80024,11.31528 1.80024,10.80568 L1.80024,9.00488
            C1.80024,8.49448 2.19144,8.10408 2.70104,8.10408 M6.30264,7.20328
            C6.81304,7.20328 7.20344,7.59448 7.20344,8.10408 L7.20344,10.29848
            C6.62344,10.55928 6.14504,11.02008 5.88104,11.62168
            C5.58904,11.47368 5.40264,11.16408 5.40264,10.80568 L5.40264,8.10408
            C5.40264,7.59448 5.79304,7.20328 6.30264,7.20328 M9.90504,6.30328
            C10.41464,6.30328 10.80504,6.69368 10.80504,7.20328
            L10.80504,10.32728 L9.00424,10.10168 L9.00424,7.20328
            C9.00424,6.69368 9.39544,6.30328 9.90504,6.30328 M13.50664,1.80088
            C14.01704,1.80088 14.40664,2.19128 14.40664,2.70088
            L14.40664,10.80568 L12.60584,10.58008 L12.60584,2.70088
            C12.60584,2.19128 12.99704,1.80088 13.50664,1.80088 M13.50664,8e-05
            C12.02584,8e-05 10.80504,1.22088 10.80504,2.70088 L10.80504,4.67128
            C10.52024,4.56888 10.22184,4.50248 9.90504,4.50248 C8.94824,4.50248
            8.10744,5.00888 7.62584,5.76808 C7.23224,5.54328 6.78504,5.40248
            6.30264,5.40248 C5.34664,5.40248 4.50584,5.90968 4.02424,6.66888
            C3.63064,6.44408 3.18344,6.30328 2.70104,6.30328 C1.22024,6.30328
            -0.00056,7.52408 -0.00056,9.00488 L-0.00056,17.10888
            C-0.00056,20.58008 2.83224,23.41208 6.30264,23.41208
            L11.05864,23.41208 C13.82024,23.41208 16.26744,21.60728
            17.08024,18.96568 L17.78424,16.71448 L17.78424,16.68648
            C17.93464,16.17688 18.14264,15.23048 17.95304,14.15368
            C17.70984,12.77528 16.92984,11.89608 16.20824,11.42488
            L16.20824,2.70088 C16.20824,1.22088 14.98744,8e-05 13.50664,8e-05"
            id="Fill-1"
            fill="#FFFFFF"
            mask="url(#mask-2)" />
        </g>
      {:else if icon === 'crm'}
        <g transform="translate(15, 18)" fill="#FFFFFF">
          <path
            d="M7.61434105,-0.000227368421 L7.28162526,0.498467368
            L0.365835789,11.1385516 L-0.000227368421,11.7039411
            L0.365835789,12.2693305 L7.28162526,22.9094147
            L7.61434105,23.4081095 L22.5766989,23.4081095 L22.9094147,22.9094147
            L29.8259621,12.2693305 L30.1912674,11.7039411 L29.8259621,11.1385516
            L22.9094147,0.498467368 L22.5766989,-0.000227368421
            L7.61434105,-0.000227368421 Z M2.52735158,11.7039411
            L8.74436211,2.12794105 L21.44592,2.12794105 L27.6644463,11.7039411
            L21.44592,21.2806989 L8.74436211,21.2806989 L2.52735158,11.7039411 Z"
            id="Fill-1" />
          <g id="Group-8" transform="translate(6.821053, 9.008261)">
            <path
              d="M1.64243368,4.83915789 C1.24529684,4.63149474
              0.932286316,4.34122105 0.707191579,3.96757895
              C0.480581053,3.59317895 0.367654737,3.17027368
              0.367654737,2.69583158 C0.367654737,2.22138947
              0.480581053,1.79772632 0.707191579,1.42408421 C0.932286316,1.0512
              1.24529684,0.760168421 1.64243368,0.552505263
              C2.04032842,0.344084211 2.48748632,0.240252632
              2.98466526,0.240252632 C3.44091789,0.240252632
              3.84790737,0.320589474 4.20563368,0.481263158 C4.56336,0.642694737
              4.85818105,0.875368421 5.09161263,1.17928421
              L4.09119158,2.07208421 C3.80091789,1.70526316
              3.45834947,1.52185263 3.06500211,1.52185263 C2.73380211,1.52185263
              2.46778105,1.62795789 2.26693895,1.84016842 C2.06533895,2.05313684
              1.96529684,2.33810526 1.96529684,2.69583158 C1.96529684,3.05431579
              2.06533895,3.33928421 2.26693895,3.55149474 C2.46778105,3.76370526
              2.73380211,3.87056842 3.06500211,3.87056842 C3.45834947,3.87056842
              3.80091789,3.6864 4.09119158,3.31957895 L5.09161263,4.21162105
              C4.85818105,4.51553684 4.56336,4.74896842 4.20563368,4.90964211
              C3.84790737,5.07107368 3.44091789,5.15141053 2.98466526,5.15141053
              C2.48748632,5.15141053 2.04032842,5.04682105 1.64243368,4.83915789"
              id="Fill-2" />
            <path
              d="M8.19117474,1.71284211 C8.08279579,1.61658947
              7.92288,1.56884211 7.70763789,1.56884211 L7.13088,1.56884211
              L7.13088,2.66930526 L7.70763789,2.66930526 C7.92288,2.66930526
              8.08279579,2.6208 8.19117474,2.52454737 C8.29803789,2.42829474
              8.35184842,2.29338947 8.35184842,2.11831579 C8.35184842,1.94475789
              8.29803789,1.80909474 8.19117474,1.71284211 L8.19117474,1.71284211
              Z M7.56060632,3.86298947 L7.13088,3.86298947 L7.13088,5.04454737
              L5.54763789,5.04454737 L5.54763789,0.347115789
              L7.80768,0.347115789 C8.24195368,0.347115789
              8.61938526,0.419115789 8.94224842,0.562357895 C9.26435368,0.7056
              9.51294316,0.910989474 9.68725895,1.17928421
              C9.86157474,1.44757895 9.94797474,1.76058947 9.94797474,2.11831579
              C9.94797474,2.45482105 9.87294316,2.74661053 9.72136421,2.99747368
              C9.56826947,3.24757895 9.34848,3.44690526 9.06275368,3.59469474
              L10.0495326,5.04454737 L8.35866947,5.04454737
              L7.56060632,3.86298947 Z"
              id="Fill-4" />
            <polygon
              id="Fill-6"
              points="14.7390821 5.04416842 14.72544 2.90387368 13.7060716
              4.62126316 13.0080505 4.62126316 11.9879242 2.97663158 11.9879242
              5.04416842 10.5456505 5.04416842 10.5456505 0.347494737 11.8477137
              0.347494737 13.3771453 2.85006316 14.8664084 0.347494737
              16.1684716 0.347494737 16.1813558 5.04416842" />
          </g>
        </g>
      {:else if icon === 'revenue'}
        <g transform="translate(18, 20)" fill="#FFFFFF">
          <path
            d="M3.42857143,0 L3.42857143,1.81640623 C2.24142857,2.08040623
            1.49665183,2.88238397 1.49665183,4.00781246 C1.49665183,5.12638397
            2.15337051,5.82269229 3.49051337,6.10212086 L4.44642857,6.30636171
            C5.34642857,6.500076 5.71205357,6.76916486 5.71205357,7.23716486
            C5.71205357,7.79173629 5.1473304,8.17801371 4.3493304,8.17801371
            C3.48447317,8.17801371 2.88364286,7.79686629 2.8125,7.20200914
            L1.32924103,7.20200914 C1.37552674,8.33429486 2.16771429,9.10829486
            3.42857143,9.34486629 L3.42857143,11.1428571 L5.14285714,11.1428571
            L5.14285714,9.34151829 C6.456,9.09208971 7.24218754,8.26954029
            7.24218754,7.04296886 C7.24218754,5.90468314 6.6079554,5.26899086
            5.13281246,4.95870531 L4.2472098,4.76953123 C3.38749551,4.5869598
            3.03850449,4.32812949 3.03850449,3.87555806 C3.03850449,3.31584377
            3.54617409,2.95982143 4.31417409,2.95982143 C5.0564598,2.95982143
            5.5957098,3.34549551 5.66685266,3.92578123 L7.11160714,3.92578123
            C7.07389286,2.86035266 6.3,2.0719152 5.14285714,1.81305806
            L5.14285714,0 L3.42857143,0 Z M11.1428571,0 L11.1428571,1.81640623
            C9.95571429,2.08040623 9.21093771,2.88238397 9.21093771,4.00781246
            C9.21093771,5.12638397 9.86765657,5.82269229 11.2047994,6.10212086
            L12.1607143,6.30636171 C13.0607143,6.500076 13.4263389,6.76916486
            13.4263389,7.23716486 C13.4263397,7.79173629 12.8616163,8.17801371
            12.0636163,8.17801371 C11.1987591,8.17801371 10.5979286,7.79686629
            10.5267857,7.20200914 L9.04352657,7.20200914 C9.08981229,8.33429486
            9.882,9.10829486 11.1428571,9.34486629 L11.1428571,11.1428571
            L12.8571429,11.1428571 L12.8571429,9.34151829 C14.1702857,9.09208886
            14.9564734,8.27035714 14.9564734,7.04464286 C14.9564734,5.90635714
            14.3222409,5.26899086 12.847098,4.95870531 L11.9614954,4.77120531
            C11.1017811,4.58863397 10.7527903,4.32812949 10.7527903,3.87555806
            C10.7527903,3.31584377 11.2604597,2.95982143 12.0284597,2.95982143
            C12.7707454,2.95982143 13.3099954,3.34635266 13.3811383,3.92578123
            L14.8258929,3.92578123 C14.7881786,2.86035266 14.0142857,2.0719152
            12.8571429,1.81305806 L12.8571429,0 L11.1428571,0 Z M18.8571429,0
            L18.8571429,1.81640623 C17.67,2.08040623 16.9252234,2.88238397
            16.9252234,4.00781246 C16.9252234,5.12638397 17.5819423,5.82269229
            18.9190851,6.10212086 L19.875,6.30636171 C20.775,6.500076
            21.1406246,6.76916486 21.1406246,7.23716486 C21.1406246,7.79173629
            20.575902,8.17801371 19.777902,8.17801371 C18.9130449,8.17801371
            18.3122143,7.79686629 18.2410714,7.20200914 L16.7578123,7.20200914
            C16.804098,8.33429486 17.5962857,9.10829486 18.8571429,9.34486629
            L18.8571429,11.1428571 L20.5714286,11.1428571 L20.5714286,9.34151829
            C21.8845714,9.09208886 22.6707591,8.27035714 22.6707591,7.04464286
            C22.6707591,5.90635714 22.0365266,5.26899086 20.5613837,4.95870531
            L19.6757811,4.77120531 C18.8160669,4.58863397 18.467076,4.32812949
            18.467076,3.87555806 C18.467076,3.31584377 18.9747454,2.95982143
            19.7427454,2.95982143 C20.4850311,2.95982143 21.0242811,3.34635266
            21.095424,3.92578123 L22.5401786,3.92578123 C22.5024643,2.86035266
            21.7285714,2.0719152 20.5714286,1.81305806 L20.5714286,0
            L18.8571429,0 Z M20.5714286,13.7142857 L20.5714286,16.2857143
            L16.9101566,16.2857143 C16.6044708,15.7562487 16.039944,15.4296525
            15.4285714,15.4285714 C14.8166006,15.4290555 14.2512976,15.7557317
            13.9453123,16.2857143 L10.0530137,16.2857143 C9.74732791,15.7562487
            9.18280114,15.4296525 8.57142857,15.4285714 C7.95945778,15.4290556
            7.3941549,15.7557318 7.0881696,16.2857143 L3.42857143,16.2857143
            C3.35517926,16.2852895 3.28203639,16.2942917 3.21093754,16.3125
            C2.909488,15.7680304 2.33663426,15.4297007 1.71428571,15.4285714
            C0.767511857,15.4285714 0,16.1960833 0,17.1428571 C0,18.089631
            0.767511857,18.8571429 1.71428571,18.8571429 C2.33688105,18.8569588
            2.91043406,18.5192353 3.21261163,17.9748883 C3.28324067,17.9923907
            3.35581079,18.0008292 3.42857143,18 L7.08984377,18
            C7.39552951,18.5294654 7.96005613,18.8560616 8.57142857,18.8571429
            C9.18339942,18.8566587 9.74870239,18.5299826 10.0546877,18
            L13.9469863,18 C14.2526721,18.5294656 14.8171989,18.8560618
            15.4285714,18.8571429 C16.0405423,18.8566587 16.6058452,18.5299826
            16.9118306,18 L20.5714286,18 L20.5714286,20.5714286 L24,17.1428571
            L20.5714286,13.7142857 Z"
            id="Shape" />
        </g>
      {/if}
    </g>
  </svg>
{:else if ['phone', 'address'].includes(icon)}
  <svg class={wrapperClasses} viewBox="0 0 36 36">
    <circle id="back" fill="#2487FF" cx="18" cy="18" r="18" />
    <path
      d="M18,36 C27.9411255,36 36,27.9411255 36,18 C36,8.0588745 8.0588745,36
      18,36 Z"
      id="tint"
      fill="#1D6CCC"
      opacity="0.5" />
    <g transform="translate(10, 10)" fill="#FFFFFF">
      {#if icon === 'phone'}
        <path
          d="M11.9826087,10.3575652 C11.7001739,10.192 11.3530435,10.1954783
          11.072,10.3624348 L9.64869565,11.2104348 C9.33008696,11.4003478
          8.93217391,11.378087 8.64,11.149913 C8.13495652,10.7554783
          7.32173913,10.0918261 6.61426087,9.38434783 C5.90678261,8.67686957
          5.24313043,7.86365217 4.84869565,7.3586087 C4.62052174,7.06643478
          4.59826087,6.66852174 4.78817391,6.34991304 L5.63617391,4.9266087
          C5.80382609,4.64556522 5.80521739,4.29565217 5.63965217,4.01321739
          L3.55130435,0.445913043 C3.34886957,0.100869565
          2.94678261,-0.0688695652 2.55791304,0.0264347826
          C2.18017391,0.11826087 1.68973913,0.34226087 1.17565217,0.857043478
          C-0.434086957,2.46678261 -1.28904348,5.18191304 4.76452174,11.2354783
          C10.818087,17.2890435 13.5325217,16.4347826 15.1429565,14.8243478
          C15.6584348,14.3088696 15.8817391,13.8177391 15.9742609,13.4393043
          C16.0681739,13.0511304 15.9012174,12.6518261 15.5568696,12.450087
          C14.6970435,11.9471304 12.8424348,10.8612174 11.9826087,10.3575652 Z"
          id="Path" />
      {:else if icon === 'address'}
        <path
          d="M2.96296296,5.98005261e-17 C2.74925279,-0.0030223938
          2.5504703,0.109259435 2.4427332,0.293850578 C2.3349961,0.47844172
          2.3349961,0.706743465 2.4427332,0.891334608 C2.5504703,1.07592575
          2.74925279,1.18820758 2.96296296,1.18518519 L10.0740741,1.18518519
          C10.2877842,1.18820758 10.4865667,1.07592575 10.5943038,0.891334608
          C10.7020409,0.706743465 10.7020409,0.47844172 10.5943038,0.293850578
          C10.4865667,0.109259435 10.2877842,-0.0030223938
          10.0740741,5.98005261e-17 L2.96296296,5.98005261e-17 Z
          M1.18518519,2.37031086 C0.971475012,2.36734798 0.772692525,2.47962981
          0.664955424,2.66422095 C0.557218324,2.84881209 0.557218324,3.07711384
          0.664955424,3.26170498 C0.772692525,3.44629612 0.971475012,3.55857795
          1.18518519,3.55555556 L1.18518519,14.2222222 L4.74074074,14.2222222
          L4.74074074,11.2592593 L5.33333333,11.2592593 L5.33333333,10.0740741
          L4.74074074,10.0740741 L4.74074074,7.7037037 C4.74074074,6.72177778
          5.53659259,5.92592593 6.51851852,5.92592593 L11.8518519,5.92592593
          L11.8518519,3.55555556 C12.065562,3.55857795 12.2643445,3.44629612
          12.3720816,3.26170498 C12.4798187,3.07711384 12.4798187,2.84881209
          12.3720816,2.66422095 C12.2643445,2.47962981 12.065562,2.36734798
          11.8518519,2.37031086 L1.18518519,2.37031086 Z M2.37037037,4.14814815
          L3.55555556,4.14814815 L3.55555556,5.33333333 L2.37037037,5.33333333
          L2.37037037,4.14814815 Z M4.74074074,4.14814815 L5.92592593,4.14814815
          L5.92592593,5.33333333 L4.74074074,5.33333333 L4.74074074,4.14814815 Z
          M7.11111111,4.14814815 L8.2962963,4.14814815 L8.2962963,5.33333333
          L7.11111111,5.33333333 L7.11111111,4.14814815 Z M9.48148148,4.14814815
          L10.6666667,4.14814815 L10.6666667,5.33333333 L9.48148148,5.33333333
          L9.48148148,4.14814815 Z M2.37037037,6.51851852 L3.55555556,6.51851852
          L3.55555556,7.7037037 L2.37037037,7.7037037 L2.37037037,6.51851852 Z
          M6.51851852,7.1110516 C6.30480835,7.10808872 6.10602586,7.22037055
          5.99828876,7.40496169 C5.89055166,7.58955283 5.89055166,7.81785458
          5.99828876,8.00244572 C6.10602586,8.18703686 6.30480835,8.29931869
          6.51851852,8.2962963 L6.51851852,16 L10.0740741,16
          L10.0740741,13.6296296 L11.2592593,13.6296296 L11.2592593,16
          L14.8148148,16 L14.8148148,8.2962963 C15.028525,8.29931869
          15.2273075,8.18703686 15.3350446,8.00244572 C15.4427817,7.81785458
          15.4427817,7.58955283 15.3350446,7.40496169 C15.2273075,7.22037055
          15.028525,7.10808872 14.8148148,7.1110516 L6.51851852,7.1110516 Z
          M2.37037037,8.88888889 L3.55555556,8.88888889 L3.55555556,10.0740741
          L2.37037037,10.0740741 L2.37037037,8.88888889 Z M7.7037037,8.88888889
          L8.88888889,8.88888889 L8.88888889,10.0740741 L7.7037037,10.0740741
          L7.7037037,8.88888889 Z M10.0740741,8.88888889 L11.2592593,8.88888889
          L11.2592593,10.0740741 L10.0740741,10.0740741 L10.0740741,8.88888889 Z
          M12.4444444,8.88888889 L13.6296296,8.88888889 L13.6296296,10.0740741
          L12.4444444,10.0740741 L12.4444444,8.88888889 Z M2.37037037,11.2592593
          L3.55555556,11.2592593 L3.55555556,12.4444444 L2.37037037,12.4444444
          L2.37037037,11.2592593 Z M7.7037037,11.2592593 L8.88888889,11.2592593
          L8.88888889,12.4444444 L7.7037037,12.4444444 L7.7037037,11.2592593 Z
          M10.0740741,11.2592593 L11.2592593,11.2592593 L11.2592593,12.4444444
          L10.0740741,12.4444444 L10.0740741,11.2592593 Z M12.4444444,11.2592593
          L13.6296296,11.2592593 L13.6296296,12.4444444 L12.4444444,12.4444444
          L12.4444444,11.2592593 Z M7.7037037,13.6296296 L8.88888889,13.6296296
          L8.88888889,14.8148148 L7.7037037,14.8148148 L7.7037037,13.6296296 Z
          M12.4444444,13.6296296 L13.6296296,13.6296296 L13.6296296,14.8148148
          L12.4444444,14.8148148 L12.4444444,13.6296296 Z"
          id="Shape" />
      {/if}
    </g>
  </svg>
{:else if icon === 'line-chart'}
  <svg class={wrapperClasses} viewBox="0 0 36 40">
    <circle id="back" fill="#2487FF" cx="18" cy="18" r="18" />
    <path
      d="M18,36 C27.9411255,36 36,27.9411255 36,18 C36,8.0588745 8.0588745,36
      18,36 Z"
      id="tint"
      fill="#1D6CCC"
      opacity="0.5" />
    <g
      id="icons8-line_chart"
      transform="translate(10, 10)"
      fill="#FFFFFF"
      fill-rule="nonzero">
      <path
        d="M13.9130435,0 C13.1446473,0 12.5217391,0.622908174
        12.5217391,1.39130435 C12.5222882,1.54825954 12.5493917,1.70399034
        12.6019019,1.85190219 L9.99456557,4.89402157 C9.91035568,4.87801829
        9.82484705,4.86983136 9.73913043,4.86956522 C9.52563466,4.86971308
        9.31503669,4.91899199 9.12364104,5.01358678 L6.95244591,3.56657607
        C6.95473973,3.53718828 6.95609903,3.50773501 6.95652174,3.47826087
        C6.95652174,2.7098647 6.33361356,2.08695652 5.56521739,2.08695652
        C4.83012374,2.08776137 4.2224961,2.66027622 4.17798887,3.3940217
        L2.15625002,4.40489113 C1.9293382,4.25475865 1.66338603,4.17445344
        1.39130435,4.17391304 C0.622908174,4.17391304 0,4.79682122 0,5.56521739
        C0,6.33361356 0.622908174,6.95652174 1.39130435,6.95652174
        C2.12639784,6.95571675 2.7340253,6.38320201 2.77853259,5.6494567
        L4.800272,4.63858713 C5.02718374,4.78871956 5.29313581,4.86902477
        5.56521739,4.86956522 C5.77871317,4.86941735 5.98931113,4.82013844
        6.18070678,4.72554365 L8.35190191,6.17255443 C8.34960809,6.2019422
        8.3482488,6.23139544 8.34782609,6.26086957 C8.34782609,7.02926574
        8.97073426,7.65217391 9.73913043,7.65217391 C10.5075266,7.65217391
        11.1304348,7.02926574 11.1304348,6.26086957 C11.1298856,6.10391447
        11.1027821,5.94818376 11.050272,5.800272 L13.6576083,2.75815221
        C13.7418182,2.77415553 13.8273269,2.78234251 13.9130435,2.7826087
        C14.6814397,2.7826087 15.3043478,2.15970052 15.3043478,1.39130435
        C15.3043478,0.622908174 14.6814397,0 13.9130435,0 Z
        M5.56521739,8.34782609 C4.79682122,8.34782609 4.17391304,8.97073426
        4.17391304,9.73913043 C4.17446218,9.89608553 4.20156568,10.0518162
        4.25407583,10.199728 L1.64673913,13.2418477 C1.56252936,13.2258444
        1.47702085,13.2176575 1.39130435,13.2173913 C0.622908174,13.2173913
        0,13.8402995 0,14.6086957 C0,15.3770918 0.622908174,16 1.39130435,16
        C2.15970052,16 2.7826087,15.3770918 2.7826087,14.6086957
        C2.78205947,14.4517405 2.75495587,14.2960098 2.70244563,14.1480981
        L5.30978226,11.1059784 C5.39399215,11.1219817 5.47950077,11.1301686
        5.56521739,11.1304348 C5.68640257,11.1298219 5.80698831,11.1133785
        5.92391304,11.0815221 L8.39538017,13.5529892 C8.36386507,13.670414
        8.34787753,13.7914631 8.34782609,13.9130435 C8.34782609,14.6814397
        8.97073426,15.3043478 9.73913043,15.3043478 C10.5075266,15.3043478
        11.1304348,14.6814397 11.1304348,13.9130435 C11.1300121,13.8835694
        11.1286528,13.8541161 11.126359,13.8247283 L13.2975541,12.3777176
        C13.4889497,12.4723124 13.6995477,12.5215913 13.9130435,12.5217391
        C14.6814397,12.5217391 15.3043478,11.898831 15.3043478,11.1304348
        C15.3043478,10.3620386 14.6814397,9.73913043 13.9130435,9.73913043
        C13.1446473,9.73913043 12.5217391,10.3620386 12.5217391,11.1304348
        C12.5221618,11.1599089 12.5235211,11.1893621 12.525815,11.2187499
        L10.3546198,12.6657607 C10.1632242,12.5711659 9.95262621,12.521887
        9.73913043,12.5217391 C9.61794526,12.5223521 9.49735952,12.5387954
        9.38043478,12.5706518 L6.90896765,10.0991847 C6.94048275,9.98175991
        6.95647029,9.86071077 6.95652174,9.73913043 C6.95652174,8.97073426
        6.33361356,8.34782609 5.56521739,8.34782609 Z"
        id="Shape" />
    </g>
  </svg>
{/if}
