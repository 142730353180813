<script>
  import TopLink from "../common/TopLink.svelte";
  import Nav from "../common/Nav.svelte";
  import FullWidthNavBackers from "./FullWidthNavBackers.svelte";
  import MobileNavBackers from "./MobileNavBackers.svelte";

  let clientWidth;
</script>

<style>
  .wrapper-navbanner {
    position: relative;
    height: 400px;
    width: 100vw;
    padding-top: 40px;
    margin-top: -40px;
  }

  .hero {
    position: relative;
    z-index: 1;
    margin-top: 48px;
    padding: 0 162px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media only screen and (max-width: 1248px) {
    .hero {
      padding: 0 72px;
    }
  }

  .hero-content {
    width: 45%;
  }
  @media only screen and (max-width: 1032px) {
    .hero-content {
      width: 55%;
    }
  }

  .hero-title {
    margin-top: 48px;
    margin-bottom: 48px;
    font-size: 48px;
    font-weight: 700;
  }
  @media only screen and (max-width: 1032px) {
    .hero-title {
      margin-top: 0px;
      margin-bottom: 24px;
    }
  }

  .hero-subtitle {
    margin-bottom: 48px;
    font-size: 18px;
    font-weight: 400;
  }

  .hero-cta {
    display: flex;
    align-items: center;
    width: 70%;
  }
  :global(.hero-cta .cta-link) {
    flex-shrink: 0;
    width: 130px;
    margin-right: 12px;
    padding: 0;
  }

  .cta-button {
    width: 100%;
    border-radius: 5px;
    outline: none;
    border: 2px solid #fff;
    height: 60px;
    color: #fff;
    background-color: #2487ff;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    cursor: pointer;
  }
  .cta-button:active {
    opacity: 0.9;
  }
  .cta-description {
    color: #222;
    opacity: 0.3;
    font-size: 14px;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    .wrapper-navbanner {
      height: auto;
      padding-top: 0;
      margin-top: 0;
    }

    .hero {
      margin-top: 32px;
      margin-bottom: 40px;
      padding: 0 20px;
    }

    .hero-content {
      width: 100%;
    }

    .hero-title {
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 40px;
      line-height: 48px;
      font-weight: 700;
    }

    .hero-cta {
      flex-direction: column;
      width: 100%;
    }

    :global(.hero-cta .cta-link) {
      width: 100%;
      margin-right: 0;
    }

    .cta-button {
      width: 100%;
      margin-bottom: 20px;
    }

    .mobile-backers {
      margin-bottom: 40px;
    }
  }
</style>

<div class="wrapper-navbanner" bind:clientWidth>
  {#if clientWidth > 882}
    <FullWidthNavBackers />
  {/if}
  <Nav {clientWidth} />
  <div class="hero">
    <div class="hero-content">
      <h1 class="hero-title">Leading college athletics into the digital age</h1>
      <h4 class="hero-subtitle">
        Drive new revenues to your athletic department and modernize your
        current donor’s experience with Nickel DXM™
      </h4>
      <div class="hero-cta">
        <TopLink class="cta-link" href="/start">
          <button class="cta-button">SEE HOW</button>
        </TopLink>
        <div class="cta-description">
          Answer a few questions and we’ll do the rest
        </div>
      </div>
    </div>
  </div>
  {#if clientWidth <= 882}
    <div class="mobile-backers">
      <MobileNavBackers />
    </div>
  {/if}
</div>
