<script>
  import TopLink from "../common/TopLink.svelte";
  import NickelLogo from "./NickelLogo.svelte";

  export let width = null;
  export let lightBackground = false;
  export let clientWidth;
  let menuOpen = false;

  const toggleMenu = () => (menuOpen = !menuOpen);

  $: startButtonClasses = `nav-button primary${
    lightBackground ? " light-bg" : ""
  }`;
  $: contactButtonClasses = `nav-button${lightBackground ? " light-bg" : ""}`;
  $: logInButtonClasses = `nav-button ancillary${
    lightBackground ? " light-bg" : ""
  }`;

  $: explicitWidth = width
    ? `width: ${width}px; padding-left: 0px; padding-right: 0px;`
    : "";

  $: style = clientWidth > 882 ? explicitWidth : "";

  $: mobileMenuClasses = `mobile-menu${menuOpen ? " active" : ""}`;
</script>

<style>
  .nav {
    position: relative;
    z-index: 2;
    height: 40px;
    width: 100%;
    margin-top: 40px;
    padding: 0 162px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media only screen and (max-width: 1248px) {
    .nav {
      padding: 0 72px;
    }
  }

  :global(.logo-link) {
    display: flex;
    align-items: center;
  }

  :global(.nav-button) {
    height: 40px;
    width: 130px;
    margin: 0 6px;
    padding: 0;
    border-radius: 5px;
    outline: none;
    border: 2px solid #fff;
    color: #fff;
    background-color: transparent;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    cursor: pointer;
    text-decoration: none;
  }
  :global(.nav-button:active) {
    opacity: 0.9;
  }
  :global(.nav-button.light-bg) {
    border: 2px solid #2487ff;
    color: #2487ff;
  }
  @media only screen and (max-width: 1248px) {
    :global(.nav-button.primary) {
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }
  }

  :global(.nav-button.primary) {
    color: #2487ff;
    background-color: #fff;
  }
  :global(.nav-button.primary.light-bg) {
    color: #fff;
    background-color: #2487ff;
  }

  :global(.nav-button.ancillary) {
    border-color: transparent;
    width: 80px;
    font-weight: 700;
  }
  :global(.nav-button.nav-button.ancillary.light-bg) {
    color: #000;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    .nav {
      width: 100%;
      margin-top: 20px;
      padding: 0 20px;
      box-sizing: border-box;
    }

    .menu-button {
      height: 21px;
      width: 34px;
      stroke: #222;
      fill: #222;
      stroke-linecap: round;
      stroke-width: 3;
    }
    .menu-button:active {
      opacity: 0.5;
    }

    .mobile-menu {
      z-index: 100;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      opacity: 0;
      pointer-events: none;
      transition: opacity 300ms ease-in-out;
    }
    .mobile-menu.active {
      opacity: 1;
      pointer-events: initial;
    }

    .close-button {
      position: fixed;
      top: 20px;
      right: 20px;
      height: 36px;
      width: 36px;
      fill: #191919;
      stroke-width: 1;
      fill-rule: evenodd;
    }
    .close-button:active {
      opacity: 0.7;
    }

    .mobile-buttons {
      width: 100%;
      padding: 0px 20px;
      margin-top: 120px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    :global(.link-button) {
      display: block;
      width: 100%;
    }

    .nav-button {
      height: 60px;
      width: 100%;
      margin: 18px 0 0;
    }
  }
  @media only screen and (max-width: 372px) {
    .nav {
      padding: 0 10px;
    }
  }
</style>

<div class="nav" {style}>
  <TopLink class="logo-link" href="/">
    <NickelLogo height={30} />
  </TopLink>
  {#if clientWidth > 882}
    <div class="buttons">
      <TopLink class="link-button" href="/start">
        <button class={startButtonClasses}>START HERE</button>
      </TopLink>
      <TopLink class="link-button" href="/contact">
        <button class={contactButtonClasses}>CONTACT US</button>
      </TopLink>
      <a class="link-button" href="https://app.nickeldxm.com">
        <button class={logInButtonClasses}>Log in &#8250;</button>
      </a>
    </div>
  {:else}
    <svg class="menu-button" viewbox="0 0 34 21" on:click={toggleMenu}>
      <line x1="1.5" y1="1.5" x2="32.5" y2="1.5" />
      <line x1="1.5" y1="10.5" x2="32.5" y2="10.5" />
      <line x1="1.5" y1="19.5" x2="32.5" y2="19.5" />
    </svg>
    <div class={mobileMenuClasses}>
      <svg class="close-button" viewBox="0 0 36 36" on:click={toggleMenu}>
        <path
          d="M18,0 C8.0595,0 0,8.0595 0,18 C0,27.9405 8.0595,36 18,36
          C27.9405,36 36,27.9405 36,18 C36,8.0595 27.9405,0 18,0 Z
          M25.0605,22.9395 C25.647,23.526 25.647,24.474 25.0605,25.0605
          C24.768,25.353 24.384,25.5 24,25.5 C23.616,25.5 23.232,25.353
          22.9395,25.0605 L18,20.121 L13.0605,25.0605 C12.768,25.353 12.384,25.5
          12,25.5 C11.616,25.5 11.232,25.353 10.9395,25.0605 C10.353,24.474
          10.353,23.526 10.9395,22.9395 L15.879,18 L10.9395,13.0605
          C10.353,12.474 10.353,11.526 10.9395,10.9395 C11.526,10.353
          12.474,10.353 13.0605,10.9395 L18,15.879 L22.9395,10.9395
          C23.526,10.353 24.474,10.353 25.0605,10.9395 C25.647,11.526
          25.647,12.474 25.0605,13.0605 L20.121,18 L25.0605,22.9395 Z" />
      </svg>
      <div class="mobile-buttons">
        <a class="link-button" href="https://app.nickeldxm.com">
          <button class="nav-button ancillary light-bg">LOG IN &#8250;</button>
        </a>
        <TopLink class="link-button" href="/start">
          <button class="nav-button primary light-bg">START HERE</button>
        </TopLink>
        <TopLink class="link-button" href="/contact">
          <button class="nav-button secondary light-bg">CONTACT US</button>
        </TopLink>
      </div>
    </div>
  {/if}
</div>
