<script>
  export let icon = "instagram";
</script>

<style>
  a {
    padding-left: 16px;
  }
  a:first-of-type {
    padding-left: 0px;
  }

  svg {
    height: 24px;
    width: 24px;
    opacity: 0.3;
    fill: #fff;
    cursor: pointer;
  }
  svg:active {
    opacity: 0.2;
  }
</style>

{#if icon === 'instagram'}
  <a href="https://instagram.com/thenickelteam" target="_blank">
    <svg viewBox="0 0 24 24">
      <path
        d="M11.9962143,0.047529505 C8.7382619,0.047529505
        8.32969048,0.0613116832 7.05021429,0.11957703 C5.77330952,0.177699802
        4.90130952,0.380107723 4.13821429,0.676091881 C3.34940476,0.982008713
        2.68035714,1.39138693 2.01345238,2.05697109 C1.3465,2.72260277
        0.936357143,3.39027802 0.629785714,4.17752554 C0.333214286,4.9391097
        0.130452381,5.80938297 0.0721666667,7.08375921 C0.0137857143,8.36074931
        2.38095238e-05,8.76841663 2.38095238e-05,12.0199651
        C2.38095238e-05,15.2715137 0.0137857143,15.6792285
        0.0721666667,16.9562186 C0.130452381,18.2305473 0.333214286,19.1008681
        0.629785714,19.8624048 C0.936357143,20.6496523 1.3465,21.317375
        2.01345238,21.9829592 C2.68035714,22.6485909 3.34940476,23.0579216
        4.13821429,23.3639335 C4.90130952,23.6598701 5.77330952,23.862278
        7.05021429,23.9204483 C8.32969048,23.9786661 8.7382619,23.9924483
        11.9962143,23.9924483 C15.2542143,23.9924483 15.6626905,23.9786661
        16.9422143,23.9204483 C18.219119,23.862278 19.091119,23.6598701
        19.8542143,23.3639335 C20.6430714,23.0579216 21.3120714,22.6485909
        21.9789762,21.9829592 C22.6459286,21.317375 23.0560714,20.6496998
        23.3626429,19.8624048 C23.6592143,19.1008681 23.8620238,18.2305473
        23.9202619,16.9562186 C23.9786429,15.6792285 23.9924524,15.2715137
        23.9924524,12.0199651 C23.9924524,8.76846416 23.9786429,8.36074931
        23.9202619,7.08375921 C23.8620238,5.80938297 23.6592143,4.9391097
        23.3626429,4.17752554 C23.0560714,3.39027802 22.6459286,2.72260277
        21.9789762,2.05697109 C21.3120714,1.39138693 20.6430714,0.982008713
        19.8542143,0.676091881 C19.091119,0.380107723 18.219119,0.177699802
        16.9422143,0.11957703 C15.6626905,0.0613116832 15.2542143,0.047529505
        11.9962143,0.047529505 M11.9962143,2.20472554 C15.1993571,2.20472554
        15.5787381,2.21689188 16.8436905,2.27449188 C18.0133095,2.32776713
        18.6485,2.52280871 19.0712143,2.68676911 C19.6311667,2.90395723
        20.0308333,3.16339485 20.4505952,3.58232554 C20.8703571,4.00130376
        21.1303095,4.40013149 21.3479762,4.95897505 C21.5122143,5.38085228
        21.7076429,6.01478495 21.7610238,7.18208792 C21.8186905,8.44453545
        21.8309286,8.82321267 21.8309286,12.0199651 C21.8309286,15.2167651
        21.8186905,15.5954424 21.7610238,16.8578424 C21.7076429,18.0251929
        21.5122143,18.6591255 21.3479762,19.0810028 C21.1303095,19.6397988
        20.8703571,20.0386741 20.4505952,20.4576048 C20.0308333,20.876583
        19.6311667,21.1360206 19.0712143,21.3532087 C18.6485,21.5171216
        18.0133095,21.7122107 16.8436905,21.7654384 C15.5789286,21.8230384
        15.1995952,21.8352523 11.9962143,21.8352523 C8.79283333,21.8352523
        8.4135,21.8230384 7.1487381,21.7654384 C5.97911905,21.7122107
        5.34388095,21.5171216 4.92116667,21.3532087 C4.3612619,21.1360206
        3.96159524,20.876583 3.54183333,20.4576048 C3.12207143,20.0386741
        2.86207143,19.6397988 2.64445238,19.0810028 C2.48021429,18.6591255
        2.28478571,18.0251929 2.23140476,16.8578899 C2.17369048,15.5954424
        2.1615,15.2167651 2.1615,12.0199651 C2.1615,8.82321267
        2.17369048,8.44453545 2.23140476,7.18208792 C2.28478571,6.01478495
        2.48021429,5.38085228 2.64445238,4.95897505 C2.86207143,4.40013149
        3.12207143,4.00130376 3.54183333,3.58237307 C3.96159524,3.16339485
        4.3612619,2.90395723 4.92116667,2.68676911 C5.34388095,2.52280871
        5.97911905,2.32776713 7.14869048,2.27449188 C8.41369048,2.21689188
        8.79307143,2.20472554 11.9962143,2.20472554"
        id="Fill-1" />
      <path
        d="M11.9962143,16.0108087 C9.78778571,16.0108087 7.99745238,14.2240206
        7.99745238,12.0199651 C7.99745238,9.8159097 9.78778571,8.02916911
        11.9962143,8.02916911 C14.2046429,8.02916911 15.9949762,9.8159097
        15.9949762,12.0199651 C15.9949762,14.2240206 14.2046429,16.0108087
        11.9962143,16.0108087 M11.9962143,5.87197307 C8.59402381,5.87197307
        5.83597619,8.62451168 5.83597619,12.0199651 C5.83597619,15.4154661
        8.59402381,18.1680048 11.9962143,18.1680048 C15.3984048,18.1680048
        18.1564048,15.4154661 18.1564048,12.0199651 C18.1564048,8.62451168
        15.3984048,5.87197307 11.9962143,5.87197307"
        id="Fill-4" />
      <path
        d="M19.8393619,5.62906455 C19.8393619,6.42253782 19.1948857,7.06573782
        18.3998381,7.06573782 C17.6047905,7.06573782 16.9602667,6.42253782
        16.9602667,5.62906455 C16.9602667,4.83559129 17.6047905,4.19239129
        18.3998381,4.19239129 C19.1948857,4.19239129 19.8393619,4.83559129
        19.8393619,5.62906455"
        id="Fill-6" />
    </svg>
  </a>
{:else if icon === 'facebook'}
  <a href="https://facebook.com/nickelteam" target="_blank">
    <svg viewBox="0 0 24 24">
      <path
        d="M24,12.0733717 C24,5.40542771 18.6274219,0 12,0 C5.37257813,0
        0,5.40542771 0,12.0733717 C0,18.0995405 4.38822656,23.0943321 10.125,24
        L10.125,15.5633307 L7.078125,15.5633307 L7.078125,12.0733717
        L10.125,12.0733717 L10.125,9.41345698 C10.125,6.3875682
        11.9165156,4.71616081 14.6575781,4.71616081 C15.9704766,4.71616081
        17.34375,4.95196885 17.34375,4.95196885 L17.34375,7.92315016
        L15.8305781,7.92315016 C14.3398828,7.92315016 13.875,8.85381375
        13.875,9.80860051 L13.875,12.0733717 L17.203125,12.0733717
        L16.6710937,15.5633307 L13.875,15.5633307 L13.875,24
        C19.6117734,23.0943321 24,18.0995405 24,12.0733717" />
    </svg>
  </a>
{:else if icon === 'twitter'}
  <a href="https://twitter.com/nickelteam" target="_blank">
    <svg viewBox="0 0 24 20">
      <path
        d="M7.547856,19.9194216 C16.604784,19.9194216 21.557616,12.2565784
        21.557616,5.61197059 C21.557616,5.39422549 21.553296,5.17746078
        21.5436,4.96187255 C22.504944,4.25216667 23.340624,3.36657843
        23.999952,2.3582451 C23.117712,2.75844118 22.168176,3.0282451
        21.172272,3.14991176 C22.188816,2.52746078 22.969392,1.54236275
        23.337456,0.368245098 C22.385808,0.94422549 21.332496,1.3632451
        20.210928,1.58873529 C19.312368,0.611382353 18.032688,9.80392157e-06
        16.616688,9.80392157e-06 C13.8972,9.80392157e-06 11.691984,2.25206863
        11.691984,5.0282451 C11.691984,5.42295098 11.73528,5.80657843
        11.81976,6.17481373 C7.727568,5.96471569 4.098672,3.96363725
        1.670448,0.920990196 C1.247184,1.66393137 1.003536,2.52746078
        1.003536,3.44844118 C1.003536,5.19304902 1.872912,6.73314706
        3.194736,7.63412745 C2.387088,7.60873529 1.628208,7.38206863
        0.96456,7.0050098 C0.963504,7.0259902 0.963504,7.04706863
        0.963504,7.06912745 C0.963504,9.50481373 2.660976,11.537951
        4.913904,11.9990294 C4.500336,12.1139314 4.065168,12.1759902
        3.615888,12.1759902 C3.298608,12.1759902 2.990064,12.1438333
        2.690256,12.0852059 C3.31704,14.083049 5.134704,15.5368725
        7.290192,15.5778529 C5.604528,16.9266765 3.481584,17.730402
        1.174608,17.730402 C0.777264,17.730402 0.385392,17.7071667
        -4.8e-05,17.6606961 C2.179248,19.0869706 4.76664,19.9194216
        7.547856,19.9194216" />
    </svg>
  </a>
{/if}
