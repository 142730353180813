<script>
  export let icon = "foam-finger";
  export let height;

  $: svgHeight = height ? `${height}px` : "100%";
  $: style = `height: ${svgHeight};`;
</script>

<style>
  svg {
    height: 100%;
    opacity: 0.3;
    fill: #000;
    stroke: #000;
  }

  .white {
    fill: #fff;
  }

  .american {
    transform: scale(-1, -1);
  }
</style>

{#if icon === 'acc'}
  <!-- <svg {style} viewBox="0 0 250 71.8"> -->
  <svg {style} viewBox="0 -20.104 250 112.008">
    <path
      d="M96.1,13.9l-6,20.6H69.4L96.1,13.9z
      M207.4,0c-3,0-18.1,0.4-22,12l-8.8,26.8c-2.6,10.1,4.2,16.4,13.5,16.4h39.3l4.2-12.8
      h-33.1c-5.2,0-8.9-2.1-7.6-6.8l6-18.4c1.2-2.7,3.8-4.3,7.1-4.3h24.7L250,0H207.4z
      M144.1,0c-3,0-18.1,0.4-22,12l-8.8,26.8
      c-2.6,10.1,4.2,16.4,13.5,16.4h39.3l4.2-12.8h-33.1c-5.2,0-8.9-2.1-7.6-6.8l6-18.4c1.2-2.7,3.8-4.3,7.1-4.3h24.7L186.8,0H144.1
      L144.1,0z
      M92.6,0L21.4,55.2h21.1l11.7-8.9l35.1-8.7l-5.1,17.6l16.3,0L115.8,0H92.6z" />
    <polyline points="12,62.4 0,71.8 224.2,71.8 227.3,62.4 " />
  </svg>
{:else if icon === 'big-10'}
  <!-- <svg {style} viewBox="0 0 250 92.8"> -->
  <svg {style} viewBox="0 -14.848 250 122.496">
    <path d="M147.7,68.8h-10.8V0H96v23.4h10.8v45.4H96v23.4h51.6V68.8" />
    <path
      d="M242,31.4v-8.1C242,10.5,231.6,0,218.7,0h-45c-12.9,0-23.3,10.4-23.3,23.3v45.5c0,12.9,10.4,23.3,23.3,23.3l0,0h45
      c12.9,0,23.3-10.4,23.3-23.3V37.8h-37.9v16.7h7.9v11.2c0,1.7-1.4,3.1-3.1,3.1c0,0,0,0,0,0h-25.4c-1.7,0-3.1-1.4-3.1-3.1V26.5
      c0-1.7,1.4-3.1,3.1-3.1c0,0,0,0,0,0h25.4c1.7,0,3.1,1.4,3.1,3.1c0,0,0,0,0,0v5L242,31.4" />
    <path
      d="M75.8,45.1C86.2,48,93.3,57.4,93.3,68.2c0,13.3-10.8,24-24,24H0V68.8h10.8V23.4H0V0h66.8v0
      c12.9,0.1,23.3,10.6,23.3,23.5c0,9.4-5.7,17.8-14.3,21.4L75.8,45.1z
      M40.8,36.7h16.8c0.8,0,1.5-0.7,1.5-1.6V25 c0-0.9-0.7-1.6-1.6-1.6H40.8V36.7z
      M60,55c0-0.9-0.7-1.5-1.5-1.5H40.8v15.4h17.6c0.9,0,1.5-0.7,1.5-1.5c0,0,0,0,0,0V55z" />
    <path
      d="M245.8,84.2c2.3,0,4.2,1.8,4.2,4.1c0,0,0,0.1,0,0.1c0,2.3-1.8,4.3-4.2,4.3c0,0-0.1,0-0.1,0c-2.3,0-4.2-1.8-4.3-4.1
      c0,0,0-0.1,0-0.1C241.5,86.1,243.4,84.2,245.8,84.2L245.8,84.2z
      M249.3,88.5c0-2-1.5-3.6-3.5-3.6c0,0-0.1,0-0.1,0
      c-2,0-3.6,1.6-3.6,3.6c0,2,1.5,3.6,3.5,3.6c0,0,0.1,0,0.1,0C247.8,92.1,249.3,90.5,249.3,88.5L249.3,88.5z
      M244.1,86.1h1.7
      c1.1,0,1.7,0.5,1.7,1.4c0,0.7-0.4,1.1-0.9,1.3l1,1.9h-0.9l-0.9-1.8H245v1.8h-0.9V86.1z
      M245.8,88.3c0.6,0,0.9-0.2,0.9-0.7 c0-0.5-0.3-0.7-0.9-0.7H245v1.4H245.8z" />
  </svg>
{:else if icon === 'big-12'}
  <!-- <svg {style} viewBox="0 0 250 150.6"> -->
  <svg {style} viewBox="0 -12.048 250 174.696">
    <g transform="matrix(2.3619 0 0 2.3619 -.30917 -.052865)">
      <path
        class="st0"
        d="M69.7,43.4H70c1.5,0.1,2.2,0.2,3.7,0.3V20.2c-1.5,0.1-2.5,0.2-4,0.3V43.4z" />
      <path
        class="st0"
        d="M55.4,21.1c-3.8,4.1-5.7,6-9.5,10.1c4.1,4.7,6.1,6.9,10.2,11.7c0.6,0,1,0,1.6,0V21C56.8,21,56.4,21,55.4,21.1
        L55.4,21.1z" />
      <path
        class="st0"
        d="M105.4,62c-0.9-2.9-1.9-5.9-2.8-9.1c-1.1-3.6-1.7-5.5-2.9-9c-0.3-1-0.6-1.9-1-2.9l-0.2-0.6l-0.6-0.1
        c-0.4-0.1-0.9-0.2-1.4-0.2c-0.6-0.1-1.1-0.2-1.7-0.3c-0.6-0.1-1.2-0.2-1.7-0.3c-0.4-0.1-0.8-0.2-1.2-0.2V24.5
        c0.4-0.1,0.7-0.1,1.1-0.2c0.5-0.1,1-0.2,1.7-0.3c0.6-0.1,1.1-0.2,1.7-0.3c0.4-0.1,0.9-0.1,1.3-0.2l0.6-0.1l0.2-0.6l0.2-0.5
        c0.3-0.8,0.5-1.5,0.8-2.3c1.2-3.4,1.7-5.3,2.9-8.9c0.4-1.4,0.8-2.8,1.2-4.1c0.5-1.6,1-3.3,1.5-5l0.5-1.7l-1.7,0.3
        c-0.4,0.1-1.2,0.3-2.4,0.6C94.6,2.8,73,7.9,53.1,7.9C26.5,7.9,2.5,0.7,2.3,0.6L0.3,0l0,0L1,1.9c1,3,1.9,6.1,2.9,9.1
        c1.1,3.6,1.7,5.4,2.9,8.9c0.3,1,0.6,1.9,1,2.9l0.2,0.6l0.6,0.1c0.4,0.1,0.8,0.1,1.2,0.2c0.6,0.1,1.2,0.2,1.8,0.3
        c1.4,0.3,2.6,0.5,3.8,0.7c1.7,2.6,3.1,4.7,4.7,6.8c-1.8,2.5-3.2,4.7-5.2,7.8c-1.1,0.2-2.1,0.4-3.6,0.6c-0.5,0.1-1.1,0.2-1.7,0.3
        c-0.5,0.1-1,0.2-1.4,0.2l-0.6,0.1l-0.2,0.6c-0.3,1-0.6,1.9-1,2.9c-1.2,3.5-1.8,5.4-2.9,9c-0.9,2.9-1.8,5.9-2.8,9.1l-0.5,1.7
        l1.7-0.3c2.1-0.4,4.6-0.9,7.4-1.6c10.1-2.3,25.3-5.7,43.6-5.7H53c18.3,0,41.9,5.1,51.2,7.3l1.8,0.4L105.4,62z
        M53.1,49.3
        c-4.8,0-9.7,0.1-14.7,0.5l0,0v-6.5c2.3-0.2,2.7-0.2,5-0.3c-2.6-3.1-3.9-4.6-6.5-7.8c-2.6,3.3-3.8,5-6.4,8.7
        c2.3-0.2,2.8-0.3,5.1-0.5v6.8c-9.2,1.3-17.1,2.7-25.9,4.9c1.1-3.7,1.6-5.4,2.7-8.9c2.4-0.4,3.6-0.6,6.1-0.9
        c3.8-6.1,5.7-8.8,9.5-13.9c-3.7-4.7-5.5-7.2-9.1-12.8c-2.5-0.4-3.8-0.5-6.3-1c-1.1-3.4-1.6-4.9-2.7-8.5c7.3,2,16.7,3.7,25.9,4.9
        v6.5c-2-0.2-2.2-0.3-4.2-0.5c2.2,3.3,3.3,4.9,5.5,8c2.4-3,3.6-4.4,6-7.3c-2.3-0.1-2.6-0.1-4.9-0.2v-6.5
        c20.4,1.6,38.7-0.5,58.3-5.4c-1.1,3.7-1.7,5.5-2.8,8.9c-3.2,0.5-4.8,0.8-8,1.2V45c3.3,0.4,4.9,0.7,8,1.2c1.1,3.5,1.7,5.3,2.8,9
        C81.9,51.5,67.9,49.4,53.1,49.3z" />
      <path
        d="M55.4,21.1c-3.8,4.1-5.7,6-9.5,10.1c4.1,4.7,6.1,6.9,10.2,11.7c0.6,0,1,0,1.6,0V21C56.8,21,56.4,21,55.4,21.1L55.4,21.1z" />
      <path
        d="M101.6,53.3L101.6,53.3c-1.1-3.6-1.7-5.4-2.9-8.9c-0.3-1-0.6-1.9-1-2.9c-1-0.2-2-0.3-3-0.5c-1.5-0.3-2.5-0.5-3.7-0.7V23.6
        c1.2-0.2,2.2-0.4,3.7-0.7c1-0.2,2-0.3,3-0.5c0.3-1,0.6-1.9,1-2.9c1.2-3.5,1.8-5.3,2.9-8.9c1-3.1,1.8-6.1,2.8-9.1
        C101.4,2.1,76.2,9,53.1,9C27.2,9,3.4,2.1,2,1.6c1,3,1.9,6.1,2.9,9.1c1.1,3.6,1.7,5.4,2.9,8.9c0.3,0.9,0.6,1.9,1,2.9
        c1,0.2,2,0.3,3,0.5c1.7,0.3,2.9,0.5,4.3,0.7c2.1,3.1,3.5,5.3,5.4,7.8c-2.1,2.8-3.6,5.2-5.9,8.8c-1.3,0.2-2.4,0.4-4,0.7
        c-1,0.2-2.1,0.3-3.1,0.5c-0.3,1-0.6,1.9-1,2.9c-1.1,3.4-1.7,5.3-2.9,8.9c-1,3.1-1.9,6.1-2.8,9.1C11.1,60.7,29.4,55,53,55
        c20.1,0,46.3,6.1,51.4,7.3C103.5,59.4,102.5,56.3,101.6,53.3z
        M53.1,49.3c-4.8,0-9.7,0.1-14.7,0.5l0,0v-6.5c2.3-0.2,2.7-0.2,5-0.3
        c-2.6-3.1-3.9-4.6-6.5-7.8c-2.6,3.3-3.8,5-6.4,8.7c2.3-0.2,2.8-0.3,5.1-0.5v6.8c-9.2,1.3-17.1,2.7-25.9,4.9
        c1.1-3.7,1.6-5.4,2.7-8.9c2.4-0.4,3.6-0.6,6.1-0.9c3.8-6.1,5.7-8.8,9.5-13.9c-3.7-4.7-5.5-7.2-9.1-12.8c-2.5-0.4-3.8-0.5-6.3-1
        c-1.1-3.4-1.6-4.9-2.7-8.5c7.3,2,16.7,3.7,25.9,4.9v6.5c-2-0.2-2.2-0.3-4.2-0.5c2.2,3.3,3.3,4.9,5.5,8c2.4-3,3.6-4.4,6-7.3
        c-2.3-0.1-2.6-0.1-4.9-0.2v-6.5c20.4,1.6,38.7-0.5,58.3-5.4c-1.1,3.7-1.7,5.5-2.8,8.9c-3.2,0.5-4.8,0.8-8,1.2V45
        c3.3,0.4,4.9,0.7,8,1.2c1.1,3.5,1.7,5.3,2.8,9C81.9,51.5,67.9,49.4,53.1,49.3z" />
      <path
        d="M69.7,43.4H70c1.5,0.1,2.2,0.2,3.7,0.3V20.2c-1.5,0.1-2.5,0.2-4,0.3V43.4z" />
    </g>
  </svg>
{:else if icon === 'sec'}
  <svg {style} viewBox="0 0 250 250">
    <path
      d="M125,0L125,0C56.1,0,0,56.1,0,125c0,68.9,56.1,125,125,125c68.9,0,125-56.1,125-125C250,56.1,193.9,0,125,0z
      M125,242.2
      C60.3,242.2,7.8,189.8,7.8,125C7.8,60.3,60.3,7.8,125,7.8c64.7,0,117.2,52.5,117.2,117.2C242.2,189.8,189.7,242.2,125,242.2z" />
    <path
      d="M124.2,21.4c-7.5,0-14.8,0.8-21.9,2.3v202.6c7,1.5,14.4,2.3,21.9,2.3c9.7,0,19.1-1.3,28-3.8v-32.3
      c-6.2,2.6-12.8,4.3-19.7,5.1v-53.6h16.2v-37.8h-16.2V60.3c7,0.9,13.6,2.9,19.7,5.8V25.2C143.3,22.7,133.9,21.4,124.2,21.4
      L124.2,21.4z
      M90.9,26.9C58.1,38.1,32.7,65.2,23.9,99l42.5,47.6v33.8c-8.2-8.5-14.5-18.8-18.2-30.3l-20,13.6
      c11.2,27.8,34.2,49.7,62.8,59.4v-88.2L53.6,94.7c4.4-10.3,11-19.4,19.3-26.8v24.5h18L90.9,26.9L90.9,26.9z
      M164.1,29.7v190.9
      c28.5-11.9,50.6-36.2,59.4-66.2L203.1,139c-2.3,12.7-7.5,24.5-15.1,34.4V76.9c7.5,9.9,12.8,21.6,15.1,34.4l20.5-15.4
      C214.7,65.9,192.6,41.6,164.1,29.7L164.1,29.7z" />
  </svg>
{:else if icon === 'pac-12'}
  <svg {style} viewBox="0 0 198.5 250">
    <g transform="scale(.25)">
      <path
        d="M332.6,244.3l-0.4,0.7c-27.5,50-58.8,107.4-88.1,161.2C275,382.4,393.5,294.1,397,290.2
        c39.7,32.3,118.1,89.9,154.2,117.2c-26.4-48.5-54.6-100.1-80.1-146.6c-2.9-5.3-5.8-10.6-8.7-15.8l-0.4-0.7l-0.8,0
        c-22.2-1-55.5-1.6-63.9-1.7c-8.4,0.1-41.7,0.7-63.9,1.7L332.6,244.3" />
      <path
        d="M278.6,825.5c32.8,35.2,70.8,70.9,112.6,104.9l5.4,4.2l0,0l0,0l5.4-4.2c43-34.9,78.1-68.3,111.6-104.5
        c-34.3,24.7-71.4,36.2-116.3,36.2C353.2,862,311.3,847.8,278.6,825.5" />
      <path
        d="M431.6,188.8l-34.3-62.9L363,188.8c0,0,21.1-1.2,34.3-1.3C410.5,187.5,431.6,188.8,431.6,188.8" />
      <path
        d="M416.5,324.7c-5.8-8.6,53.3,83.3,168.8,145.6c-10.8-19.8-22.3-41-34.1-62.7
        C497,386.7,453.3,356.3,416.5,324.7" />
      <path
        transform="translate(-.035156 -11.227)"
        d="M402.7,973.4l-5.7,4.4l0,0l0,0l-5.7-4.4C-49.8,610.8,11.7,164.5,45.6,86.9C46.3,86.4,190,34,396.9,34h0.1
        c206.9,0,350.6,52.4,351.3,52.9C782.3,164.5,843.7,610.8,402.7,973.4
        M768.9,66.5C768.1,65.9,616,11.2,397,11.2h-0.1
        c-219,0-371.1,54.7-371.9,55.3c-35.9,81.1-101,561,365.9,940.1l6,4.6l0,0l0,0l6-4.6C869.9,627.5,804.8,147.6,768.9,66.5" />
      <path
        d="M730.1,93.6C729.5,93.1,593,44,396.6,44h-0.1C200,44,63.6,93.1,62.9,93.6C36.6,153.1-7.1,446.7,206,737.7
        c-11.5-26.1-17.9-55-17.9-85.5c0-115.8,92.1-209.6,205.8-209.6c32.4,0,63.1,7.7,90.4,21.3c8.8,4.1,17,8.7,24.5,13.7
        c-0.3-0.1-0.5-0.2,0.1,0.1c0.7,0.3,0.3,0.1,0,0c-56.7-75.6-164-126.1-265.2-70.4c-26,47.9-50.4,92.8-69,126.9
        c-16,29.6-29.2,53.5-33.1,60.7c-14.7-29.5-25.7-57.4-27.5-62.3L367.7,67.7c1.8-0.1,7.7-0.2,17.6-0.2l12,0l12,0
        c9.9,0,15.8,0.1,17.6,0.2c30,52.5,215.6,395,247.1,453.1c0.1,0.2,0.2,0.4,0.3,0.6c2,3.8,3.4,6.3,3.9,7.3v0l13.1,24
        C782.3,331.7,750.8,140.3,730.1,93.6" />
      <path
        d="M731.2,93.2c-0.6-0.5-137.4-49.7-334.2-49.7h-0.1C200,43.5,63.3,92.7,62.7,93.2
        c-32.3,72.9-90.8,497.2,328.9,838l5.4,4.2l0,0l0,0l5.4-4.2C822.1,590.4,763.6,166.1,731.2,93.2" />
      <path
        d="M731.2,93.2c-0.6-0.5-137.4-49.7-334.2-49.7h-0.1C200,43.5,63.3,92.7,62.7,93.2
        c-32.3,72.9-90.8,497.2,328.9,838l5.4,4.2l0,0l0,0l5.4-4.2C822.1,590.4,763.6,166.1,731.2,93.2" />
      <path
        class="white"
        d="M401.9,930.3l-5.4,4.2l0,0l0,0l-5.4-4.2c-41.8-33.9-79.8-69.7-112.6-104.9c32.7,22.3,74.7,36.5,118.7,36.5
        c45,0,82-11.5,116.3-36.2C480.1,862,444.9,895.4,401.9,930.3L401.9,930.3z
        M363,188.8l34.3-62.9l34.3,62.9c0,0-21.1-1.2-34.3-1.3
        C384.1,187.5,363,188.8,363,188.8L363,188.8z
        M397,290.2c-3.4,3.9-122,92.2-152.8,115.9c29.2-53.8,60.6-111.2,88.1-161.2l0.4-0.7
        l0.8,0c22.3-1,55.5-1.6,63.9-1.7c8.4,0.1,41.7,0.7,63.9,1.7l0.8,0l0.4,0.7c2.8,5.2,5.7,10.5,8.7,15.8
        c37,67.4,79.5,145.5,114.3,209.5C469.8,408,410.7,316.1,416.5,324.7c36.9,31.7,80.7,62.1,135,83
        C515.5,380.5,436.7,322.6,397,290.2L397,290.2z
        M63,93.6C63.6,93.1,200,44,396.5,44h0.1C593,44,729.5,93.1,730.1,93.6
        c20.7,46.7,52.2,238.1-38.8,459.1l-13.1-24v0c-0.5-1-1.9-3.5-3.9-7.3c-0.1-0.2-0.2-0.4-0.3-0.6
        c-31.5-58.1-217.1-400.6-247.1-453.1c-1.8-0.1-7.7-0.2-17.6-0.2l-12,0l-12,0c-9.9,0-15.8,0.1-17.6,0.2L113.9,532.5
        c1.8,4.9,12.8,32.8,27.5,62.3c4-7.2,17.1-31.1,33.1-60.7c18.5-34.1,42.9-79,69-126.9c101.2-55.7,208.5-5.2,265.2,70.4
        c0.4,0.2,0.7,0.4,0,0c-0.6-0.3-0.4-0.2-0.1-0.1c-7.5-5.1-15.7-9.7-24.5-13.7c-27.3-13.6-57.9-21.3-90.4-21.3
        c-113.6,0-205.8,93.9-205.8,209.6c0,30.5,6.4,59.4,17.9,85.5C-7.1,446.7,36.6,153.1,63,93.6L63,93.6z
        M748.3,75.7
        c-0.6-0.5-144.4-52.9-351.3-52.9h-0.1C190,22.8,46.3,75.2,45.6,75.7c-34,77.6-95.4,523.8,345.7,886.5l5.7,4.4l0,0l0,0l5.7-4.4
        C843.7,599.6,782.3,153.3,748.3,75.7" />
      <path
        class="white"
        d="M117.4,218.1c2.1-33,3.7-45.7,6.9-63.5l0.2-0.9l0.9-0.2c47.4-11.7,73.6-16.3,105.2-20.6l2.8-0.4l-1.4,2.5
        c-13,23.5-18.5,33.6-26.1,47.7c-3,5.6-6.3,11.7-10.6,19.6l-0.3,0.6l-0.7,0.1c-0.5,0.1-49.8,9.4-75.1,16.6l-1.9,0.5L117.4,218.1z
        M90,107.7c-7.1,2.1-9.8,2.9-10.9,3.3C49,215.6,58.3,360.1,102.4,500c5.3-9.4,23.5-42.8,36.8-67.7c-21.5-82.6-22-155.3-22-155.8
        v-1.1l1-0.3c0.5-0.2,48.2-13.7,111.9-22.6c4.7-9.3,25.7-47.8,50-92.1c17.7-32.3,35.9-65.7,50.7-93C271.8,70.7,183.2,80.3,92,107
        L90,107.7" />
      <path
        class="white"
        d="M730.2,302.1c0.4-4.7,0.6-8.2,0.9-10.8c-18-5.7-36.8-11.5-52.4-15.3c0,0.3,0,0.6,0,1
        c-0.2,4.8-0.6,17.7-5.3,55.4l-0.5,4.2l-2.1-3.7c-13.5-24-24.6-45-37.6-69.4c-16.3-30.8-36.7-69.2-69.7-128.5l-1.4-2.5l2.8,0.4
        c32.5,5,63.2,9.8,107.8,21.4l0.9,0.2l0.2,0.9c4.1,20.4,5.9,45.8,5.9,62.3c18.9,5.4,45.5,13.3,52.7,15.5
        c-1.6-70.2-15.8-117.2-17.6-122.5c-41.3-13.1-128.2-35.9-251.3-43.1C523.9,175.6,594.3,305.7,640.9,392
        c26.8,49.6,47.1,87,52.6,96.7C723.4,390.6,728.2,328.7,730.2,302.1" />
      <path
        d="M549.1,727.7l-136,0l127.5-129.5v-53.4c0,0-46.4-25.7-98.9-25.7c-52.5,0-91.3,25.7-91.3,25.7V600h41.3l0-28.6
        c0,0,7.3-12.8,50-12.8c42.7,0,54.9,15.1,54.9,15.1l0,11.8L350.5,727.9v38.9h166.8C530,752.2,549.1,727.7,549.1,727.7" />
      <path d="M274.5,766.7H314V524.8c0,0-19.8,2.9-39.5,19.9L274.5,766.7" />
    </g>
  </svg>
{:else if icon === 'mountain-west'}
  <svg {style} viewBox="0 0 342 402">
    <path
      d="M190.844287,0 L190.844287,401.393589 L283.719048,364.720891
      L302.304914,311.186495 L323.496667,349.235299 L323.427911,349.551486
      L380.953161,327.836463 L374.510262,314.776125 L374.510262,87.8453301
      L380.952809,74.7881603 L342.191915,59.5489298 L342.191915,266.633531
      L323.530358,270.221613 L323.530358,52.2302277 L279.875946,35.017992
      L279.875946,278.602838 L254.036055,283.56597 L254.036055,24.8861913
      L190.846294,0.000140778111 L190.844287,0 Z M58.261613,51.8767339
      L58.1928573,52.1929215 L79.38461,90.2065309 L92.7308903,52.2633106
      L58.2615074,51.8783528 L58.261613,51.8767339 Z M127.654526,117.866473
      L101.814634,122.829606 L101.814634,366.372218 L127.654526,359.195702
      L127.654526,117.866473 L127.654526,117.866473 Z M57.5610301,131.311839
      L39.4976089,134.755694 L39.4976089,341.8051 L58.159166,337.83199
      L58.159166,131.38089 L57.5609597,131.312156 L57.5610301,131.311839 Z"
      stroke-width="3"
      fill="#FFF" />
    <path
      d="M190.844287,0 L97.9695254,36.6726979 L98.113937,36.8514157
      L79.3837299,90.2063198 L58.1919772,52.1575158 L58.2607329,51.875678
      L0.735483507,73.5907017 L7.17838185,86.644352 L7.17838185,313.546992
      L0.735483507,326.607681 L39.4963768,341.806086 L39.4963768,134.756679
      L58.1579338,131.202736 L58.1579338,349.169485 L101.812346,366.375034
      L101.812346,122.832421 L127.652237,117.869289 L127.652237,376.549068
      L190.841999,401.396404 L190.841999,0.00281556222 L190.844287,0 Z
      M279.874644,35.0150356 L254.034752,42.1989426 L254.034752,283.563014
      L279.874644,278.599882 L279.874644,35.0150356 L279.874644,35.0150356 Z
      M342.191317,59.548437 L323.52976,63.5975675 L323.52976,270.22112
      L342.191317,266.633038 L342.191317,59.548437 L342.191317,59.548437 Z
      M302.303858,311.189662 L288.998768,349.125844 L323.426961,349.552014
      L323.495716,349.235827 L302.303964,311.187023 L302.303858,311.189662 Z" />
  </svg>
{:else if icon === 'american'}
  <svg {style} class="american" viewBox="95 190 177 193">
    <path
      class="white"
      d="M170.1,233.7h-6l2.1,5.7h35.1l1.9-5.7h-6.1v-47.6h78.8v47.6h-12l-34.8,104.5h9.1v47.9H130.4v-47.9h8
      l-34.7-104.5H91.4v-47.6h78.7L170.1,233.7L170.1,233.7z" />
    <path
      d="M166.6,189.7H94.9v40.5h11.3l37.1,111.6h-9.4v40.8h100.9v-40.8h-10.5l37.1-111.6h11v-40.5h-71.7v40.5h7.4l-4.3,12.8h-40.1
      l-4.7-12.8h7.5L166.6,189.7L166.6,189.7z" />
    <path
      class="white"
      d="M158.1,250.1h51.1l8.9-27.1h-10v-26h57.1v26h-8.9l-41.8,126.1l13.1,0v26h-86.3v-26H153l-41.8-126.1h-8.9v-26
      h56.9v26h-10L158.1,250.1L158.1,250.1z" />
    <path
      d="M188.6,261.4L175.2,282l-26.6-5.2l18.2,20.1l-12.3,22l24-9.5l28,42.9l-13-49.8l28.8-14.9l-30.4-2.1L188.6,261.4
      L188.6,261.4z" />
    <path
      d="M144.9,371.6v-18.9h13l-9.2-27.7l28.5-11.2l37.8,57.8L144.9,371.6L144.9,371.6z
      M215.3,371.6l-4.9-18.9h13.8v18.9 C224.1,371.6,215.3,371.6,215.3,371.6z
      M210.1,351.6l-12.3-47.3l33.7-17.4L210.1,351.6z
      M148.1,323.3l-34.4-103.8h-7.9v-18.9h49.8
      v18.9h-11.3l11.3,34.1h33.8l-15.8,24.5l-34.6-6.8l23.6,26.1L148.1,323.3L148.1,323.3z
      M232.2,284.8l-37.1-2.5l-4-28.6h20.7
      l11.3-34.1h-11.3v-18.9h50.1v18.9h-7.9L232.2,284.8L232.2,284.8z" />
  </svg>
{/if}
