<script>
  export let vendor = "";
</script>

<style>
  svg {
    max-width: 221px;
  }

  .neulion {
    fill: #ec174c;
  }

  .paciolan-1 {
    fill: #eb0029;
  }
  .paciolan-2 {
    fill: #040708;
  }

  .other {
    height: 40px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #2487ff;
    border-radius: 5px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
</style>

{#if vendor === 'Ticketmaster'}
  <svg viewBox="0 0 239 50">
    <g
      id="nickel-logo-ticketmaster-color"
      transform="translate(9, 5)"
      fill="#026CDF">
      <path
        d="M135.617647,26.0294118 L135.617647,26.0294118 C133.764706,28.3235294
        132,29.5588235 129.088235,29.5588235 C125.470588,29.5588235
        122.911765,27.5294118 122.911765,23.8235294 C122.911765,17.2058824
        130.235294,16.7647059 135.176471,16.7647059 L137.029412,16.7647059
        C137.117647,16.2352941 137.117647,15.6176471 137.117647,15.0882353
        C137.117647,13.0588235 134.911765,12.5294118 133.058824,12.5294118
        C130.764706,12.5294118 128.558824,13.0588235 126.441176,14.1176471
        L127.235294,9.97058824 C129.441176,9.17647059 131.735294,8.73529412
        134.029412,8.73529412 C138.088235,8.73529412 141.794118,10.1470588
        141.794118,14.9117647 C141.794118,17.6470588 139.764706,25.5
        139.323529,29.2058824 L134.911765,29.2058824 L135.617647,26.0294118
        L135.617647,26.0294118 Z M130.588235,25.7647059 C134.470588,25.7647059
        135.705882,23.0294118 136.5,19.8529412 L134.647059,19.8529412
        C132.176471,19.8529412 127.941176,20.2941176 127.941176,23.6470588
        C127.941176,25.0588235 129.264706,25.7647059 130.588235,25.7647059 Z" />
      <path
        d="M0.794117647,9.08823529 L4.58823529,9.08823529 L5.47058824,4.94117647
        L11.0294118,3.08823529 L9.79411765,9.08823529 L14.4705882,9.08823529
        L13.6764706,12.8823529 L9,12.8823529 L7.05882353,21.6176471
        C6.97058824,22.0588235 6.79411765,22.8529412 6.79411765,23.5588235
        C6.79411765,24.7941176 7.58823529,25.5 8.82352941,25.5 C9.79411765,25.5
        10.6764706,25.3235294 11.2941176,25.0588235 L10.4117647,29.1176471
        C9.52941176,29.2941176 8.55882353,29.5588235 7.58823529,29.5588235
        C4.05882353,29.5588235 1.58823529,28.1470588 1.58823529,24.3529412
        C1.58823529,22.7647059 1.94117647,21.2647059 2.29411765,19.6764706
        L3.70588235,12.8823529 L0,12.8823529 L0.794117647,9.08823529 Z" />
      <path
        d="M16.5882353,9.08823529 L21.7941176,9.08823529 L17.5588235,29.0294118
        L12.3529412,29.0294118 L16.5882353,9.08823529 L16.5882353,9.08823529 Z
        M18.4411765,0.529411765 L23.6470588,0.529411765 L22.5882353,5.47058824
        L17.3823529,5.47058824 L18.4411765,0.529411765 Z" />
      <path
        d="M37.5,13.6764706 C36.5294118,12.9705882 35.1176471,12.7058824
        33.7058824,12.7058824 C29.5588235,12.7058824 27.2647059,16.5882353
        27.2647059,20.3823529 C27.2647059,22.9411765 28.1470588,25.5882353
        31.6764706,25.5882353 C32.9117647,25.5882353 34.5882353,25.2352941
        35.9117647,24.6176471 L35.3823529,28.7647059 C33.7941176,29.5588235
        32.0294118,29.7352941 30.3529412,29.7352941 C25.2352941,29.7352941
        21.8823529,26.2941176 21.8823529,21.1764706 C21.8823529,14.2058824
        26.5588235,8.91176471 33.7058824,8.91176471 C35.6470588,8.91176471
        37.5,9.26470588 38.9117647,9.70588235 L37.5,13.6764706 Z" />
      <polygon
        points="43.1470588 0 48.4411765 0 44.9117647 16.4117647 45 16.4117647
        52.3235294 9.08823529 59.0294118 9.08823529 49.5 18.1764706 55.4117647
        29.1176471 49.5882353 29.1176471 44.4705882 18.5294118 44.3823529
        18.5294118 42.0882353 29.1176471 37.0588235 29.1176471" />
      <path
        d="M77.7352941,9.08823529 L81.5294118,9.08823529 L82.4117647,4.94117647
        L87.9705882,3.08823529 L86.7352941,9.08823529 L91.4117647,9.08823529
        L90.6176471,12.8823529 L85.9411765,12.8823529 L84.0882353,21.6176471
        C83.9117647,22.0588235 83.8235294,22.8529412 83.8235294,23.6470588
        C83.8235294,24.8823529 84.6176471,25.5882353 85.8529412,25.5882353
        C86.8235294,25.5882353 87.7058824,25.4117647 88.3235294,25.1470588
        L87.4411765,29.2058824 C86.5588235,29.3823529 85.5882353,29.6470588
        84.6176471,29.6470588 C81.0882353,29.6470588 78.6176471,28.2352941
        78.6176471,24.4411765 C78.6176471,22.8529412 78.9705882,21.3529412
        79.3235294,19.7647059 L80.7352941,12.9705882 L76.9411765,12.9705882
        L77.7352941,9.08823529 Z" />
      <path
        d="M76.0588235,16.2352941 C76.0588235,11.1176471 72.7941176,8.64705882
        67.9411765,8.64705882 C61.2352941,8.64705882 56.4705882,14.6470588
        56.4705882,20.8235294 C56.4705882,26.8235294 60.3529412,29.5588235
        66.0882353,29.5588235 C68.2058824,29.5588235 70.4117647,29.0294118
        72.4411765,28.4117647 L73.1470588,24.2647059 C71.2058824,25.1470588
        69.0882353,25.6764706 66.9705882,25.6764706 C63.6176471,25.6764706
        61.6764706,24.4411765 61.4117647,21.3529412 L61.4117647,21.3529412
        C61.4117647,21.1764706 61.4117647,21 61.4117647,20.8235294
        C61.4117647,20.7352941 61.4117647,20.7352941 61.4117647,20.7352941
        L61.4117647,20.6470588 L61.4117647,20.6470588 C61.4117647,19.2352941
        61.7647059,17.9117647 62.2058824,16.6764706 L62.2058824,16.6764706
        C63.1764706,14.1176471 64.5882353,12.3529412 67.6764706,12.3529412
        C69.8823529,12.3529412 71.0294118,13.5882353 71.0294118,15.7058824
        C71.0294118,16.1470588 70.9411765,16.5882353 70.9411765,17.1176471
        L63.8823529,17.1176471 C63.4411765,18.7058824 63.3529412,19.7647059
        63.3529412,20.7352941 L75.6176471,20.7352941 C75.7941176,19.2352941
        76.0588235,17.7352941 76.0588235,16.2352941 Z" />
      <path
        d="M93.6176471,11.2941176 C93.7058824,10.5 93.9705882,9.70588235
        94.0588235,9.08823529 L98.9117647,9.08823529 L98.4705882,11.8235294
        L98.5588235,11.8235294 C99.9705882,9.79411765 102.264706,8.64705882
        104.735294,8.64705882 C107.117647,8.64705882 109.588235,10.0588235
        109.852941,12.4411765 L109.941176,12.4411765 C111.264706,9.88235294
        114.176471,8.64705882 117,8.64705882 C120,8.64705882
        122.558824,11.1176471 122.558824,14.1176471 C122.558824,15.8823529
        122.117647,17.9117647 121.676471,19.5882353 L119.470588,29.1176471
        L114.264706,29.1176471 L116.294118,19.4117647 C116.558824,18.1764706
        117,16.3235294 117,15.1764706 C117,13.7647059 115.852941,12.7941176
        114.529412,12.7941176 C110.911765,12.7941176 110.029412,17.0294118
        109.411765,19.6764706 L107.382353,29.1176471 L102.176471,29.1176471
        L104.205882,19.4117647 C104.470588,18.1764706 104.911765,16.3235294
        104.911765,15.1764706 C104.911765,13.7647059 103.764706,12.7941176
        102.441176,12.7941176 C98.9117647,12.7941176 97.9411765,17.0294118
        97.3235294,19.6764706 L95.2058824,29.1176471 L90,29.1176471
        L93.6176471,11.2941176 Z" />
      <path
        d="M157.411765,13.1470588 C156.882353,12.7941176 155.382353,12.3529412
        153.970588,12.3529412 C152.382353,12.3529412 150.441176,12.7941176
        150.441176,14.3823529 C150.441176,16.8529412 157.235294,17.8235294
        157.235294,22.7647059 C157.235294,27.9705882 152.735294,29.5588235
        148.235294,29.5588235 C146.205882,29.5588235 144,29.2058824
        142.147059,28.3235294 L143.294118,24.1764706 C144.705882,24.9705882
        146.205882,25.7647059 148.235294,25.7647059 C150,25.7647059
        151.852941,25.2352941 151.852941,23.5588235 C151.852941,20.3823529
        145.058824,20.0294118 145.058824,15 C145.058824,10.3235294
        149.558824,8.64705882 153.705882,8.64705882 C155.558824,8.64705882
        157.5,9 158.558824,9.26470588 L157.411765,13.1470588 Z" />
      <path
        d="M160.676471,9.08823529 L164.470588,9.08823529 L165.352941,4.94117647
        L170.911765,3.08823529 L169.676471,9.08823529 L174.352941,9.08823529
        L173.558824,12.8823529 L168.882353,12.8823529 L167.029412,21.6176471
        C166.941176,22.0588235 166.764706,22.8529412 166.764706,23.5588235
        C166.764706,24.7941176 167.558824,25.5 168.794118,25.5 C169.764706,25.5
        170.647059,25.3235294 171.264706,25.0588235 L170.382353,29.1176471
        C169.5,29.2941176 168.529412,29.5588235 167.558824,29.5588235
        C164.029412,29.5588235 161.558824,28.1470588 161.558824,24.3529412
        C161.558824,22.7647059 161.911765,21.2647059 162.176471,19.6764706
        L163.676471,12.8823529 L159.882353,12.8823529 L160.676471,9.08823529 Z" />
      <path
        d="M195.970588,11.8235294 C196.058824,10.9411765 196.323529,10.0588235
        196.411765,9.08823529 L201.352941,9.08823529 L200.647059,12.8823529
        L200.735294,12.8823529 C201.705882,10.6764706 204.264706,8.64705882
        206.647059,8.64705882 C207.352941,8.64705882 208.058824,8.73529412
        208.676471,8.82352941 L207.617647,13.9411765 C207.088235,13.7647059
        206.294118,13.5882353 205.588235,13.5882353 C201.882353,13.5882353
        200.117647,16.9411765 199.411765,20.1176471 L197.558824,29.1176471
        L192.352941,29.1176471 L195.970588,11.8235294 Z" />
      <path
        d="M192.441176,16.2352941 C192.441176,11.1176471 189.176471,8.64705882
        184.323529,8.64705882 C177.794118,8.64705882 172.941176,14.6470588
        172.941176,20.8235294 C172.941176,26.8235294 176.823529,29.5588235
        182.558824,29.5588235 C184.676471,29.5588235 186.882353,29.0294118
        188.911765,28.4117647 L189.617647,24.2647059 C187.676471,25.1470588
        185.558824,25.6764706 183.441176,25.6764706 C180.088235,25.6764706
        178.147059,24.4411765 177.882353,21.3529412 L177.882353,21.3529412
        C177.882353,21.1764706 177.882353,21 177.882353,20.8235294
        C177.882353,20.7352941 177.882353,20.7352941 177.882353,20.7352941
        L177.882353,20.6470588 L177.882353,20.6470588 C177.882353,19.2352941
        178.235294,17.9117647 178.676471,16.6764706 L178.676471,16.6764706
        C179.647059,14.1176471 181.058824,12.3529412 184.147059,12.3529412
        C186.352941,12.3529412 187.5,13.5882353 187.5,15.7058824
        C187.5,16.1470588 187.411765,16.5882353 187.411765,17.1176471
        L180.352941,17.1176471 C179.911765,18.7058824 179.823529,19.7647059
        179.823529,20.7352941 L192.088235,20.7352941 C192.176471,19.2352941
        192.441176,17.7352941 192.441176,16.2352941 Z" />
      <path
        d="M216.088235,7.94117647 C218.558824,7.94117647 220.588235,9.97058824
        220.588235,12.4411765 C220.588235,14.9117647 218.558824,16.9411765
        216.088235,16.9411765 C213.617647,16.9411765 211.588235,14.9117647
        211.588235,12.4411765 C211.588235,9.97058824 213.529412,7.94117647
        216.088235,7.94117647 Z M216.088235,16.2352941 C218.117647,16.2352941
        219.705882,14.5588235 219.705882,12.4411765 C219.705882,10.3235294
        218.117647,8.64705882 216.088235,8.64705882 C214.058824,8.64705882
        212.470588,10.3235294 212.470588,12.4411765 C212.470588,14.5588235
        213.970588,16.2352941 216.088235,16.2352941 Z M214.323529,9.79411765
        L216.352941,9.79411765 C217.588235,9.79411765 218.205882,10.3235294
        218.205882,11.2941176 C218.205882,12.0882353 217.676471,12.7058824
        216.794118,12.7058824 L218.294118,15 L217.323529,15
        L215.911765,12.7058824 L215.205882,12.7058824 L215.205882,15
        L214.323529,15 L214.323529,9.79411765 Z M215.205882,12.0882353
        L216.176471,12.0882353 C216.882353,12.0882353 217.235294,12
        217.235294,11.2941176 C217.235294,10.6764706 216.705882,10.5
        216.176471,10.5 L215.205882,10.5 L215.205882,12.0882353 Z" />
    </g>
  </svg>
{:else if vendor === 'Neulion'}
  <svg viewBox="0 220 652 220">
    <g>
      <path class="neulion" d="M124.6,373.2c2.7,0.4,4.5,0,5.7-1L124.6,373.2z" />
      <path class="neulion" d="M119,375.4c0.7-0.2,1.2-0.4,1.7-0.7L119,375.4z" />
      <path
        class="neulion"
        d="M202.9,364.9l0-0.8c-1.7,0.1-3.2,0.5-4.7,1L202.9,364.9z" />
      <path
        class="neulion"
        d="M117.9,293.7c-1,1.8-2.1,3.7-2.5,5.8c-0.2,1.1-0.3,1.8,0.8,2.4c1,0.6,3,0.5,3.8-0.6c1.1-1.6-0.1-5.3,0.6-8.6
        c0.4-1.6,0.6-3.1,0.6-3.1C119.9,289.5,118.3,293,117.9,293.7" />
      <path
        class="neulion"
        d="M153.3,298c0.4,0.1,0.1-4.6-5.9-4.4c0,0,3.9-5.3,2.7-6.4c-0.4-0.4-3.1,3.7-4.6,3.6c-1.5-0.1-3.4-1.2-4.2-0.9
        c-0.7,0.3-6.7,3-8.3,3s0.4,2.1,1.3,2.5c0.9,0.4,1.2,1.5,0.3,3.4c-0.9,1.9-2.4,6.4-0.4,7.1c1.9,0.7,2.4-4,2.4-4s2.4,2.1,4.3,1.2
        c1.9-0.9,5.2-3.4,6.2-3.9C148.1,298.7,152.9,297.8,153.3,298" />
      <path
        class="neulion"
        d="M188.4,366.9c4.3,0.5,7.1-0.7,9.8-1.7l-9.4,0.6C188.7,366.1,188.5,366.5,188.4,366.9" />
      <path
        class="neulion"
        d="M120.7,374.7l3.8-1.5c-0.5-0.1-1.1-0.2-1.6-0.4C122.9,372.9,122.1,373.9,120.7,374.7" />
      <path
        class="neulion"
        d="M210,324.8c0,0-1.5-11.6-5-16.9l3.3-1.5c0,0-5.9-13.4-18.1-14.8c0,0,4.2-14.2-3.9-13
        c-8,1.2-10.7,15.1-15.7,12.8c-5-2.4,12.2-24.9,22.2-16.3c0,0,5,4.4,0.3,11.9l8,2.7l-2.7-9.2c0,0,2.9-9.4-2.4-14.2
        c-3.6-3.3-8.9-1.5-8.9-1.5l-4.4-3.3l-0.3-7.4l-10.4-1.2c0,0-2.4-10.4-3.6-11c-1.2-0.6-9.5-0.9-9.5-0.9l-5.3-5.9
        c0,0-9.2,4.7-10.4,4.4c-1.2-0.3-2.1-1.2-2.1-1.2s-5.3,4.7-6.2,4.2c-0.9-0.6-6.5-6.5-7.7-5.9c-1.2,0.6-1.5,6.5-2.7,5.9
        c-1.2-0.6-8.9-6.8-10.1-6.2c-1.2,0.6-0.6,2.7-1.8,2.4c-1.2-0.3-6.8-1.8-7.7-0.9c-0.9,0.9-1.2,7.7-2.4,7.7c-1.2,0-9.8-3-11.3-1.8
        c-1.5,1.2-0.6,6.2-1.8,6.5c-1.2,0.3-6.5-0.6-7.4,0.3c-0.9,0.9,0.6,6.2-0.6,5.9c-1.2-0.3-12.6-3.6-17.2,5.6
        c-2.4,4.7,0.3,9.8,0.3,9.8s-3.9,1.2-4.1,2.4c-0.3,1.2,3,4.5,2.7,5.6c-0.3,1.2-9.8,5.3-10.1,6.8c-0.3,1.5,1.8,3,1.2,3.9
        c-0.6,0.9-12.8,13.4-12.2,15.4l3.3,1.8c1.3,0.7-3,3.9-2.1,4.7c0.9,0.9,5.9,3.3,5.6,5c-0.3,1.8-11.8,24.4-9.5,26.7
        c0.9,0.9,4.4,3,4.4,3l1.2,5l3.9,1.8c0.6,1.2,0.9,25.9,0.9,27.6c0,0.6,3.3-0.3,3.6,0.9c0.3,1.2,2.4,13.9,2.4,13.9
        c0.5,0.5,5.5,0.4,7.5,0.8c9.7-4.2,19.7-7.8,29.9-10.6c2.4-1.4,5.6-2.7,10.1-3.6c0,0-0.3,0.5-0.7,1.2c15.9-3.8,33.2-10.1,49.1-11.2
        c1.3-2,2.3-3.2,3-4.7c0.6-1.4,1.1-3,1.4-5.8l2.4,1.5c0,0,4.3-6.8-2.2-20.2l-3,1.8c0,0,1.2-11.4-5.3-17.1c-6.5-5.6-7.9-8.9-7.9-8.9
        s-2.1,1.6,3.9,9.5c5.9,7.9,3.7,19.4-5.6,21.8c-9.3,2.4-20,2.2-23-17.9c0,0,2.5-4.3,4.6-5.2c2.1-0.9,12-1.9,13.1-12.9
        c0,0-1.3,3-2.5,2.4c-1.2-0.6-4.3-5.3-7.9-4.1c-3.6,1.2-8,3.4-11,2.8c-2.6-0.5-5.2-1.7-7-2.5c-2.1-0.9-4.7,1.3-4.7,1.3
        s-3.4-9.8,8.3-13.4c0.7-0.2-3.1-1.2-3.1-1.2s-1.5-4.7,0-6.5c1.5-1.8,2.4-1.8,2.4-2.7c0-2.2-17.6-4.3-17.4-5.5
        c0.3-1.2,4.3-10.1,16-14.4c0,0,5.5-2.5,14.8,8.3c0,0,0.7,1.6,4.4-0.4c3.7-2.1,12.7-6.7,21.2-2.1c5.5,3,7.3,3.6,9.6,6.5
        c2.4,3,3.7,9.9,2.5,13.8c-1.2,3.9-6.1,11.7-7,14.7c-0.9,3-1.3,7.1-0.4,6.8c0.9-0.3,4.4-3.2,6.4-4c2.7-1,5.6,0.6,6.5,4.9
        c0.9,4.3-2.2,7.4-2.2,7.4s0.1,7.4-0.7,10.4c-0.9,3,1.2,7.6,1.2,7.6s1.9-3.9,2.8-3.6c0.9,0.3,6.2,6.5,6.2,6.5s0.3-11.3,1.8-12
        c1.5-0.7,8.5,0,8.5,0s-3.1-8.7-2.5-9.8c0.6-1,3.1-1.5,3.1-1.5l-6.1-8.6c0,0,6.7-17.8,23.1-6.8l-3.7,0.7c0,0,3.9,3.7,5.2,8.7
        c0,0-8.8-3.1-13.9-1.2c0,0,5.8,3.9,7.9,8.5c0,0-4,1.2-5.2,1.9c0,0,10.1,8.2,9.8,22c0,0-3.3-0.6-4.6-0.4c0,0,3.3,7.3,1.5,12.3
        c0,0-4.4-5-8-4.7c0,0,3.5,0.4,0.6,8.9l9.4-0.6c1.5-0.5,2.9-1,4.7-1c0.8,0,1.7,0,2.6,0.2c1.5-7.1-1-1.2-0.5-10.1l2.4,0.6
        c0,0,2.7-21.1-0.9-30H210z
        M68.7,269.2c-4.7,2.5-1.3-3.1-1.3-3.1s8.2-11.1,19.1,2.7c0,0,5.4,5.6,0.7,8.2c-4,2.2-6-5-7.3-6.7
        C78.1,268.1,75,265.8,68.7,269.2
        M113.9,349.1c1,0.4,4.4,5.2,8.9,6.4c4.4,1.2,11.1,1,11.1,1s4.3,9.2-1.3,8.9c0,0,0.5,4.6-2.3,6.8
        c-1.2,0.9-3,1.4-5.7,1l-3.8,1.5c-0.5,0.3-1.1,0.5-1.7,0.7c-2.1,0.5-5,0.1-8.5-3.1c0,0-9.9,5.9-12-3c0,0-9,6-5.5-8.2
        c0.9-3.6,1.5-5.5,1.5-5.5s0.2-1.9,4-2.7c3.9-0.7,10.1-2.4,11.6-3.3C111.5,348.8,112.8,348.7,113.9,349.1
        M106.2,327.2
        c0,0,0.4,4.6,5.3,5.6c0,0-1,15.7-16,15.4c-15-0.3-12.8-22,1.5-28.5C97,319.8,96.4,325.7,106.2,327.2
        M96.1,301.4v0.6
        c0,0-4.4,7.1,0,13.3c0,0-8.6,7.7-12.5,3.3c-3.9-4.4-0.3-11,3-14.2c3.3-3.3,3.3-5,3.3-5S94.3,297.5,96.1,301.4
        M160.2,275l0.2-4.7
        l-5.8,2.9l1.3-7.6l-5.3,3.6c0.7-4.9,2.9-8.7,2.9-8.7c-9.1,1.6-14.2,11.1-14.2,11.1c-2.2-1.6-2.9-8.2-2.9-8.2l-3.6,6.9
        c0.2-2.4-2.2-8.5-2.2-8.5l-3.8,3.8c-0.2-4-2.4-5.1-2.4-5.1c-0.4,3.1-5.1,9.3-5.1,9.3c-0.4-6.9-5.1-15.4-5.1-15.4l3.6,0.4
        c0.7-2.4-1.3-7.3-1.3-7.3c6,1.6,10.5,7.1,10.5,7.1l5.1-7.1c0.2,3.3,6.2,5.3,6.2,5.3c2.2-3.6,6.2-4,6.2-4c-4,2.9-2,11.6-2,11.6
        c5.6-9.8,13.6-14.5,13.6-14.5l0.9,4.9c2.7-3.1,7.8-3.8,7.8-3.8c-3.6,2.7-2.4,7.8-2.4,7.8l5.1-2.9c-2.9,2-3.3,8.5-3.3,8.5
        c5.3-4.4,13.6-2.7,13.6-2.7c-2.7,0.4-3.8,4.2-3.8,4.2c5.6-0.2,6.9,3.8,6.9,3.8c-4.7,2.2-6.7,6.5-6.7,6.5l2-7.1
        C170.4,266.1,160.2,275,160.2,275" />
      <path
        class="neulion"
        d="M414.4,407.9c0,0-95-49.7-201.9-49.7c-126.6,0-191.2,50-191.2,50h50.9c16.4-11.3,81-35,140.2-36.8
        c64.8-1.9,126.2,12.4,169.8,25.2L414.4,407.9z" />
      <polygon
        class="neulion"
        points="262.6,262.6 285.5,262.6 285.5,349.7 259.4,349.7 245.9,296
        245.7,296 245.7,349.7 223.5,349.7 223.5,262.6 249.9,262.6 262.4,315.8
        262.6,315.8 " />
      <path
        class="neulion"
        d="M315.9,329.2c0,3.5,0.2,8.3,5.1,8.3c4.6,0,5.1-4.3,5.1-11h21.5c0,9-2.4,15.2-7.1,19.1
        c-4.6,3.9-11.3,5.7-19.4,5.7c-26.1,0-28.9-16.1-28.9-34.7c0-18.5,2.8-34.6,28.9-34.6c26.9,0,27.2,16.8,27.2,37.4v1.1h-32.4V329.2z
        M326.1,304.2c0-6.5-1.5-8.3-5.2-8.3c-3.4,0-5,1.8-5,8.3v3.9h10.2V304.2z" />
      <path
        class="neulion"
        d="M386.3,342.1h-0.2c-3.5,6.5-9.5,9.3-17.4,9.3c-8.4,0-16.1-4.6-16.1-16.7v-51h23.6v43.6c0,5,1.6,6.5,4.6,6.5
        c3.2,0,4.8-2.7,4.8-6.5v-43.6h23.6v65.9h-22.9V342.1z" />
      <polygon
        class="neulion"
        points="417.3,262.6 441.7,262.6 441.7,329.9 468.2,329.9 468.2,349.7
        417.3,349.7 " />
      <path
        class="neulion"
        d="M472.9,261.7h23.6v16.8h-23.6V261.7z
        M472.9,283.7h23.6v65.9h-23.6V283.7z" />
      <path
        class="neulion"
        d="M559.8,316.6c0,18.7-2.8,34.7-28.9,34.7c-26.1,0-28.9-16.1-28.9-34.7c0-18.5,2.8-34.6,28.9-34.6
        C557,282,559.8,298.1,559.8,316.6
        M530.9,336c4.3,0,5.2-4,5.2-19.4c0-15.4-1-19.4-5.2-19.4c-4.3,0-5.2,4-5.2,19.4
        C525.6,332,526.6,336,530.9,336" />
      <path
        class="neulion"
        d="M597.7,306c0-5-1.6-6.5-4.6-6.5c-3.2,0-4.8,2.7-4.8,6.5v43.6h-23.6v-65.9h22.9v7.6h0.2
        c3.5-6.5,9.5-9.3,17.4-9.3c8.4,0,16.1,4.6,16.1,16.7v51h-23.6V306z" />
      <path
        class="neulion"
        d="M472.9,278.5h23.6v-16.8h-23.6V278.5z
        M605.2,282c-7.9,0-13.9,2.8-17.4,9.3h-0.2v-7.6h-22.9v35.5
        c8.2,1.8,16.1,3.7,23.6,5.7V306c0-3.8,1.6-6.5,4.8-6.5c3,0,4.6,1.5,4.6,6.5v21.6c8.3,2.5,16.2,5.1,23.6,7.9v-36.9
        C621.3,286.7,613.7,282,605.2,282
        M530.9,282c-22.6,0-27.7,12.1-28.7,27.3c8,0.9,15.8,1.8,23.5,2.9c0.2-11.8,1.4-15,5.2-15
        c4,0,5.1,3.5,5.2,16.6c8.1,1.3,16,2.8,23.7,4.3c0-0.5,0-1.1,0-1.6C559.8,298.1,557,282,530.9,282
        M417.3,305.2c1.5,0,3,0,4.5,0 c6.7,0,13.3,0.1,19.9,0.3v-42.8h-24.4V305.2z
        M472.9,306.8c8,0.5,15.9,1.2,23.6,1.9v-25h-23.6V306.8z M321.1,282
        c-26,0-28.9,16-28.9,34.5c17.5-3.3,36.3-6,55.9-7.9C347.3,293.3,343.2,282,321.1,282
        M326.1,308.1h-10.2v-3.9c0-6.5,1.6-8.3,5-8.3
        c3.8,0,5.2,1.8,5.2,8.3V308.1z
        M385.6,306c7.8-0.4,15.7-0.6,23.6-0.7v-21.5h-23.6V306z
        M262.6,315.8h-0.2l-12.4-53.1h-26.5v72.5
        c7-2.6,14.4-5.1,22.2-7.4V296h0.2l7.4,29.5c10.1-2.8,20.9-5.4,32.2-7.6v-55.2h-22.9V315.8z
        M352.6,308.2c7.8-0.7,15.6-1.3,23.6-1.8 v-22.7h-23.6V308.2z" />
      <polygon
        class="neulion"
        points="598.1,268 598.1,256.3 593.7,256.3 593.7,254.7 604.3,254.7
        604.3,256.3 599.9,256.3 599.9,268 " />
      <path
        class="neulion"
        d="M606,268v-13.3h2.7l3.2,9.5c0.3,0.9,0.5,1.5,0.6,2c0.2-0.5,0.4-1.2,0.7-2.1l3.2-9.3h2.4V268h-1.7v-11.2
        l-3.9,11.2h-1.6l-3.9-11.4V268H606z" />
    </g>
  </svg>
{:else if vendor === 'Paciolan'}
  <svg viewBox="0 -10 250 76.2">
    <path
      class="paciolan-1"
      d="M28.6,6.7H15.7l-3.7,17.4h12.5c3,0,6-2.1,6.5-4.6l1.8-8.7c0.2-1,0-1.9-0.6-2.7C31.5,7.3,30.1,6.7,28.6,6.7z
      M7.5,46.2H0l9.6-46h19.9c3.8,0,7,1.4,9,3.8c1.7,2,2.3,4.6,1.7,7.3l-1.8,8.7c-1.2,5.9-7.9,10.7-14.9,10.7H10.7L7.5,46.2z
      M63.3,39.6H50.4c-1.7,0-3.1-0.5-3.8-1.5c-0.5-0.7-0.7-1.5-0.5-2.4c0.5-2.4,3.3-4.3,6.4-4.3H65L63.3,39.6z
      M63.5,12.1H45.3
      l-1.4,6.5h18.5c1.6,0,2.9,0.6,3.7,1.5c0.6,0.8,0.8,1.7,0.6,2.7l-0.4,2.1H53.5c-7.1,0-13.6,4.6-14.8,10.4c-0.5,2.6,0,5.1,1.6,7
      c2,2.4,5.3,3.8,9.2,3.8h19.9l4.8-23c0.6-2.7,0-5.2-1.7-7.3C70.5,13.5,67.2,12.1,63.5,12.1L63.5,12.1z" />
    <path
      class="paciolan-2"
      d="M201.8,39.6H189c-1.7,0-3.1-0.5-3.8-1.5c-0.5-0.7-0.7-1.5-0.5-2.4c0.5-2.4,3.3-4.3,6.4-4.3h12.5L201.8,39.6z
      M202,12.1h-18.2l-1.4,6.5H201c1.6,0,2.9,0.6,3.7,1.5c0.6,0.8,0.8,1.7,0.6,2.7l-0.4,2.1H192c-7.1,0-13.6,4.6-14.8,10.4
      c-0.5,2.6,0,5.1,1.6,7c2,2.4,5.3,3.8,9.2,3.8h19.9l4.8-23c0.6-2.7,0-5.2-1.7-7.3C209,13.5,205.7,12.1,202,12.1L202,12.1z" />
    <path
      class="paciolan-1"
      d="M86.7,21.7C86.7,21.7,86.7,21.6,86.7,21.7c0.3-0.7,0.7-1.3,1.3-1.8c1-0.8,2.3-1.3,3.6-1.3h15.8l1.4-6.8H92.2
      c-1.6,0-3.3,0.3-4.8,0.9c-1.5,0.5-2.8,1.3-4.1,2.3c-2.1,1.7-3.5,3.9-4,6.1l-0.5,2.2l-1.7,8c0,0.2-0.1,0.4-0.1,0.5l-0.6,2.8
      l-0.5,2.2c-0.5,2.2,0,4.4,1.4,6.1c0.8,1,1.9,1.8,3.1,2.3c1.3,0.6,2.8,0.9,4.5,0.9h16.7l1.4-6.6H87.4c-1.3,0-2.4-0.5-3.1-1.3
      c-0.5-0.6-0.7-1.4-0.5-2.2l0,0l0.2-1.4h0l1.1-5.5h0l0.5-2.2L86.7,21.7z" />
    <polygon class="paciolan-1" points="117.5,0 115.8,8.3 146.5,3 " />
    <polygon
      class="paciolan-2"
      points="115.1,12.2 108,46.1 115.5,46.1 122.6,12.2 " />
    <polygon
      class="paciolan-2"
      points="179.3,0 171.8,0 162.2,46.1 169.7,46.1 " />
    <path
      class="paciolan-2"
      d="M249.7,23.6L247.3,35L245,46.1h0h-7.5l4.9-23.3c0.7-3.5-4-4.2-5.7-4.2h0c-0.7,0-2,0-2.9,0.1
      c-4.4,0.7-7.1,2.9-7.5,5.1l-4.6,22.2h-7.5l7.1-34.1h7.5l-0.3,1.3c0,0,0,0,0,0l-0.2,0.8c0.3-0.1,0.5-0.3,0.8-0.4
      c2.6-1.2,5.5-1.7,8.3-1.7h1.6c0.2,0,0.5,0,0.7,0c1.8,0.1,3.3,0.6,4.6,1.2c2.4,1.1,3.7,2.6,3.7,2.6
      C249.5,17.5,250.6,19.4,249.7,23.6
      M153,22.8c-0.8,4.1-1.7,8.2-2.6,12.3c-0.5,2.5-3.5,4.6-6.5,4.6c-2.5,0-5,0-7.5,0
      c-1.6,0-2.9-0.6-3.7-1.5c-0.6-0.8-0.8-1.7-0.6-2.7l0.6-3c0.6-3.1,1.3-6.2,1.9-9.2c0.5-2.5,3.5-4.6,6.5-4.6c2.5,0,5,0,7.5,0
      c1.6,0,2.9,0.6,3.7,1.5C153,20.9,153.2,21.8,153,22.8L153,22.8z
      M142.1,12c-7,0-13.7,4.8-14.9,10.8l-2.6,12.3
      c-0.6,2.7,0,5.2,1.7,7.3c2,2.4,5.3,3.8,9,3.8h2.2h5.3h1c0,0,0.1,0,0.1,0c6.6-0.5,12.6-5.1,13.8-10.7l2.6-12.3
      c0.6-2.7,0-5.2-1.7-7.3c-2-2.4-5.3-3.8-9-3.8H142.1z" />
  </svg>
{:else if vendor === 'Other'}
  <div class="other">Other</div>
{/if}
