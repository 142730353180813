<script>
  import TCIcon from "./TCIcon.svelte";
  import Hammer from "hammerjs";

  let clientWidth;

  let currentSlide = 0;

  setTimeout(() => {
    const swipeables = Array.from(document.querySelectorAll(".tc-box")).map(
      x => new Hammer(x)
    );
    swipeables.forEach(x => {
      x.on("swiperight", () => (currentSlide = Math.max(0, currentSlide - 1)));
      x.on("swipeleft", () => (currentSlide = Math.min(5, currentSlide + 1)));
    });
  });

  $: slideClass = ` slide-${currentSlide}`;
  $: boxContainerClasses = `tc-boxes-container${
    clientWidth > 882 ? "" : slideClass
  }`;
</script>

<style lang="scss">
  .wrapper-technologies {
    width: 100%;
    padding: 64px 162px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  @media only screen and (max-width: 1248px) {
    .wrapper-technologies {
      padding: 64px 72px 0;
    }
  }

  .tc-title {
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }

  .tc-subhead {
    margin: 0px 0px 96px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .tc-body {
    position: relative;
    z-index: 2;
    width: 100vw;
    margin: 0px -162px -2px;
  }

  .footer-blob {
    position: absolute;
    top: 0;
    left: 0;
    width: 112.5vw;
    margin-right: -12.5vw;
    fill: #fafafa;
  }

  .tc-boxes-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background: transparent;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 15%,
      #fafafa 15.01%,
      #fafafa 100%
    );
  }

  .tc-boxes {
    position: relative;
    padding: 80px 0;
    display: grid;
    grid-template-columns: repeat(3, 300px);
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
  @media only screen and (max-width: 1032px) {
    .tc-boxes {
      grid-template-columns: repeat(2, 300px);
    }
  }

  .tc-box {
    height: 196px;
    width: calc((100% - (2 * 24px)) / 3);
    min-width: 300px;
    padding: 36px 24px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .tc-box-body {
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    .wrapper-technologies {
      padding: 40px 20px 0;
    }

    .tc-subhead {
      margin: 0px 0px 40px;
    }

    .tc-boxes-container {
      width: 600vw;
      padding: 0 20px;
      justify-content: flex-start;
      transform: translate3d(0, 0, 0);
      transition: transform 300ms ease-in-out;
    }
    .slide-0 {
      transform: translate3d(0, 0, 0);
    }
    .slide-1 {
      transform: translate3d(calc(-100vw + 16px), 0, 0);
    }
    .slide-2 {
      transform: translate3d(calc(-200vw + 32px), 0, 0);
    }
    .slide-3 {
      transform: translate3d(calc(-300vw + 48px), 0, 0);
    }
    .slide-4 {
      transform: translate3d(calc(-400vw + 64px), 0, 0);
    }
    .slide-5 {
      transform: translate3d(calc(-500vw + 80px), 0, 0);
    }

    .tc-boxes {
      grid-template-columns: repeat(6, calc(100vw - 40px));
      grid-template-rows: 1fr;
      padding: 40px 0 80px;
    }

    .tc-box {
      width: 100%;
    }

    .slider-dots-container {
      position: relative;
      z-index: 2;
      width: 100vw;
      height: 16px;
      margin-top: -36px;
      margin-bottom: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .slider-dots {
      width: 146px;
      height: 100%;
      display: flex;
      justify-content: space-between;
    }

    .slider-dot {
      position: relative;
      height: 16px;
      width: 16px;
      box-sizing: border-box;
      border-radius: 50%;
      border: 2px solid #555;
      transition: background-color 300ms ease-in-out,
        border-color 300ms ease-in-out;
    }
    .selected {
      background-color: #222;
      border-color: #222;
    }
  }
</style>

<div class="wrapper-technologies" bind:clientWidth>
  <h2 class="tc-title">Complements the systems you use today</h2>
  <p class="tc-subhead">
    No need to rip and replace. Nickel DXM™ integrates with the tools you
    already have to provide additional value to existing technologies.
  </p>
  <div class="tc-body">
    <svg class="footer-blob" viewBox="0 0 1320 603">
      <g transform="translate(0, -1515)">
        <g id="technologies" transform="translate(0, 1375)">
          <g
            id="shape"
            transform="translate(660, 437.5) scale(1, -1) translate(-660,
            -437.5) translate(0, 132)">
            <path
              d="M0,-2.27373675e-13 L1320,-2.27373675e-13 C1540,268
              1540,446.666667 1320,536 C1100,625.333333 660,625.333333 0,536
              L0,-2.27373675e-13 Z" />
          </g>
        </g>
      </g>
    </svg>
    <div class={boxContainerClasses}>
      <div class="tc-boxes">
        <div class="tc-box">
          <TCIcon icon="paciolan" />
          <p class="tc-box-body">
            Leverage the power of your Paciolan data to drive new insights.
          </p>
        </div>
        <div class="tc-box">
          <TCIcon icon="salesforce" />
          <p class="tc-box-body">
            Push donor activity from Nickel DXM™ engagements directly to
            Salesforce.
          </p>
        </div>
        <div class="tc-box">
          <TCIcon icon="blackbaud" />
          <p class="tc-box-body">
            Join data from Blackbaud and other CRM tools into one place for deep
            analysis.
          </p>
        </div>
        <div class="tc-box">
          <TCIcon icon="ticketmaster" />
          <p class="tc-box-body">
            Use Ticketmaster? Awesome, let’s find new sales opportunities
            together!
          </p>
        </div>
        <div class="tc-box">
          <TCIcon icon="tailgate-guys" />
          <p class="tc-box-body">
            Use Nickel DXM™ to drive more engagement and sales to your on-campus
            service providers.
          </p>
        </div>
        <div class="tc-box">
          <TCIcon icon="database" />
          <p class="tc-box-body">
            Bookstore, concessions, ticket resellers; you name it - Nickel DXM™
            can consume, organize, and make sense of it.
          </p>
        </div>
      </div>
    </div>
    {#if clientWidth < 882}
      <div class="slider-dots-container">
        <div class="slider-dots">
          {#each [0, 1, 2, 3, 4, 5] as dotIndex}
            <div
              class={`slider-dot${dotIndex === currentSlide ? ' selected' : ''}`} />
          {/each}
        </div>
      </div>
    {/if}
  </div>
</div>
