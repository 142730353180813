<script>
  import NickelInput from "../common/NickelInput.svelte";

  export let firstName = "";
  export let setFirstName = e => (firstName = e.target.value);

  export let lastName = "";
  export let setLastName = e => (lastName = e.target.value);

  export let email = "";
  export let setEmail = e => (email = e.target.value);

  export let phone = "";
  export let setPhone = e => (phone = e.target.value);

  let emailError = "";
  let onEmailBlur = () => {
    if (email && !/^[^\s@]+@[^\s@]+\.[^\s@]*edu$/.test(email)) {
      emailError = "Must be .edu email address";
    } else {
      emailError = "";
    }
  };
</script>

<style>
  .form {
    flex: 0;
    width: 100%;
    margin-top: 20px;
    padding: 30px;
    box-sizing: border-box;
    background-color: #2487ff;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .input-line + .input-line {
    margin-top: 30px;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    .form {
      margin: 20px 10px 0;
    }
  }
  @media only screen and (max-width: 372px) {
    .form {
      padding: 30px 10px;
    }
  }
</style>

<div class="form">
  <div class="input-line">
    <NickelInput
      type="text"
      placeholder="First Name"
      value={firstName}
      onInput={setFirstName} />
  </div>
  <div class="input-line">
    <NickelInput
      type="email"
      placeholder="Last Name"
      value={lastName}
      onInput={setLastName} />
  </div>
  <div class="input-line">
    <NickelInput
      type="email"
      placeholder="University Email Address*"
      value={email}
      error={emailError}
      onInput={setEmail}
      onBlur={onEmailBlur} />
  </div>
  <div class="input-line">
    <NickelInput
      type="tel"
      placeholder="Phone Number (optional)"
      value={phone}
      onInput={setPhone} />
  </div>
</div>
