<script>
  import RadioOption from "./RadioOption.svelte";

  export let reasonSelections = [];
  export let toggleReasonSelection = () => null;
</script>

<style>
  .form {
    margin-top: 20px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    .form {
      margin: 20px 10px 0;
    }
  }
  @media only screen and (max-width: 372px) {
    .form {
      padding: 30px 10px;
    }
  }
</style>

<div class="form">
  <RadioOption
    title="NEW REVENUE STREAMS"
    copy="Solutions to help drive new revenue from a distributed fan base as
    well as your existing donor pool."
    icon="revenue"
    selected={reasonSelections.includes('NEW REVENUE STREAMS')}
    onClick={toggleReasonSelection('NEW REVENUE STREAMS')} />
  <RadioOption
    title="MODERNIZED DONOR EXPERIENCE MANAGEMENT"
    copy="Improve the donor experience by leveraging technology and data to
    provide more transparency, self-service and meaningful giving opportunities."
    icon="foam-finger"
    selected={reasonSelections.includes('MODERNIZED DONOR EXPERIENCE MANAGEMENT')}
    onClick={toggleReasonSelection('MODERNIZED DONOR EXPERIENCE MANAGEMENT')} />
  <RadioOption
    title="DATA DISCOVERY AND MANAGEMENT"
    copy="Integrate data from all of your systems to gain new insights, improve
    workflows and increase revenues from all areas of your athletic department."
    icon="revenue"
    selected={reasonSelections.includes('DATA DISCOVERY AND MANAGEMENT')}
    onClick={toggleReasonSelection('DATA DISCOVERY AND MANAGEMENT')} />
</div>
