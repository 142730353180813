<script>
  import ThankYou from "./ThankYou.svelte";
  import Form from "./Form.svelte";

  export let router = {};
  export let completed = false;

  const setCompleted = () => (completed = true);
</script>

<style>

</style>

{#if completed}
  <ThankYou />
{:else}
  <Form {setCompleted} />
{/if}
