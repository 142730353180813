<script>
  // import { GoogleMap } from "@beyonk/svelte-googlemaps";
  // import styles from "./darkMode.json";

  export let mobile = false;
  // let map;
  // $: center = mobile
  //   ? { lat: 40.352, lng: -111.7672942 }
  //   : { lat: 40.353, lng: -111.7682942 };
  // let nickel = { lat: 40.3520705, lng: -111.7682942 };
  // $: options = {
  //   center,
  //   zoom: 16,
  //   styles,
  //   disableDefaultUI: true
  // };
  // $: pinWidth = mobile ? 70 : 140;
  // $: pinHeight = mobile ? 86 : 172;
  // $: pinUrl = mobile ? "assets/mobilePin.png" : "assets/pin.png";

  // const addPin = () => {
  //   if (window.google && map && typeof map.getInternalMap === "function") {
  //     const google = window.google;

  //     const googleMap = map.getInternalMap();

  //     const customPin = {
  //       url: pinUrl,
  //       size: new google.maps.Size(pinWidth, pinHeight),
  //       origin: new google.maps.Point(0, 0),
  //       anchor: new google.maps.Point(pinWidth / 2, pinHeight)
  //     };

  //     const marker = new google.maps.Marker({
  //       position: nickel,
  //       map: googleMap,
  //       icon: customPin
  //     });
  //   }
  // };

  $: src = mobile ? "assets/map-mobile.png" : "assets/map.png";
</script>

<style>
  #map {
    width: 484px;
    height: 310px;
    margin-top: 40px;
    overflow: hidden;
    border-radius: 10px;
    background-color: #444;
  }

  #map img {
    width: 100%;
    height: auto;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    #map {
      width: 335px;
      height: 310px;
      margin-top: 40px;
    }
  }
</style>

<div id="map">
  <!-- <GoogleMap
    apiKey="AIzaSyDiac9heFujOQTpy9HxzCZ0bqREQ-9Ad5I"
    {options}
    on:ready={addPin}
    bind:this={map} /> -->
  <img {src} alt="Map image showing the location of Nickel headquarters." />
</div>
