<script>
  export let step = 1;

  $: stepOneClasses = step >= 1 ? "filled" : "";
  $: stepTwoClasses = step >= 2 ? "filled" : "";
  $: stepThreeClasses = step >= 3 ? "filled" : "";
  $: stepFourClasses = step >= 4 ? "filled" : "";
</script>

<style>
  svg {
    position: relative;
    z-index: 0;
    flex-shrink: 0;
    margin-top: 20px;
    margin-bottom: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    height: 20px;
    width: 170px;
    stroke: #2487ff;
    fill: transparent;
  }
  circle.filled {
    fill: #2487ff;
  }
</style>

<svg viewBox="0 0 170 20">
  <path d="M20,10 L50,10" stroke-width="2" stroke-linecap="square" />
  <path d="M70,10 L100,10" stroke-width="2" stroke-linecap="square" />
  <path d="M120,10 L150,10" stroke-width="2" stroke-linecap="square" />
  <circle class={stepOneClasses} stroke-width="2" cx="10" cy="10" r="9" />
  <circle class={stepTwoClasses} stroke-width="2" cx="60" cy="10" r="9" />
  <circle class={stepThreeClasses} stroke-width="2" cx="110" cy="10" r="9" />
  <circle class={stepFourClasses} stroke-width="2" cx="160" cy="10" r="9" />
</svg>
