<script>
  import { onMount, createEventDispatcher } from "svelte";
  import { Link } from "svero";

  let cssClass = "";
  export let href = "/";
  export { cssClass as class };

  const dispatch = createEventDispatcher();

  // this will enable `<TopLink on:click={...} />` calls
  function onClick(e) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    dispatch("click", e);
  }
</script>

<Link {href} class={cssClass} on:click={onClick}>
  <slot />
</Link>
