<script>
  import NavBanner from "./NavBanner.svelte";
  import ValueProps from "./ValueProps.svelte";
  import TrustIndicators from "./TrustIndicators.svelte";
  import Technologies from "./Technologies.svelte";
  import CTAPod from "./CTAPod.svelte";
  import Footer from "../common/Footer.svelte";

  export let router = {};
</script>

<style>

</style>

<div class="wrapper">
  <NavBanner />
  <ValueProps />
  <TrustIndicators />
  <Technologies />
  <CTAPod />
  <Footer />
</div>
