<script>
  export let label;
  export let selections = [];
  export let toggleSelection = () => null;

  $: selected = selections.includes(label);
</script>

<style>
  .checkbox-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .checkbox-option:first-of-type {
    margin-top: 12px;
  }
  .checkbox-option:last-of-type {
    margin-bottom: 0px;
  }

  .checkbox-square {
    height: 16px;
    width: 16px;
    margin-right: 10px;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.25);
    border-radius: 3px;
  }
  .checkbox-square.selected {
    background-color: #1fb589;
    border: none;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
  .checkbox-square.selected svg {
    stroke: #fff;
  }

  .checkbox-label {
    font-size: 16px;
    line-height: 24px;
  }
</style>

<div class="checkbox-option" on:click={toggleSelection(label)}>
  {#if selected}
    <div class="checkbox-square selected">
      <svg viewBox="0 0 26 22">
        <path
          transform="translate(5, 6)"
          d="M14.8032409,0.58680557 C14.6492861,0.59139291
          14.5031648,0.655736122 14.3958335,0.766203733 L5.33333333,9.828704
          L1.60416664,6.09953719 C1.45553422,5.94472955 1.23482371,5.88236947
          1.02715541,5.93650705 C0.819487113,5.99064463 0.657311402,6.1528203
          0.603173762,6.36048858 C0.549036123,6.56815687 0.611396136,6.78886739
          0.766203733,6.93749985 L4.914352,11.085648 C5.14577707,11.3169772
          5.52088959,11.3169772 5.75231467,11.085648 L15.2337961,1.60416664
          C15.4090616,1.43380139 15.4617599,1.17331277 15.3664981,0.948218378
          C15.2712364,0.723123987 15.047557,0.579600588 14.8032409,0.58680557 Z"
          id="Path" />
      </svg>
    </div>
  {:else}
    <div class="checkbox-square" />
  {/if}
  <div class="checkbox-label">{label}</div>
</div>
