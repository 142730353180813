<script>
  import CircleIcon from "../common/CircleIcon.svelte";
  import TicketingVendorLogo from "./TicketingVendorLogo.svelte";
  import CRMLogo from "./CRMLogo.svelte";

  export let reasonSelections = [];
  export let ticketingSelection = "";
  export let CRMSelections = [];
  export let dataWarehouseSelection = "";

  const iconLookup = {
    "NEW REVENUE STREAMS": "revenue",
    "MODERNIZED DONOR EXPERIENCE MANAGEMENT": "foam-finger",
    "DATA DISCOVERY AND MANAGEMENT": "line-chart"
  };

  const CRMSort = {
    Paciolan: 1,
    Salesforce: 2,
    Blackbaud: 3,
    Evertrue: 4,
    Other: 5
  };

  $: crms = CRMSelections.sort((a, b) => CRMSort[a] - CRMSort[b]);
  $: crmClasses = CRMSelections.length % 2 ? "crms" : "crms no-bottom-margin";
</script>

<style>
  .form {
    margin-top: 20px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .section {
    padding-bottom: 20px;
    border-bottom: 1px solid #d8d8d8;
  }
  .section:last-child {
    padding-bottom: 0px;
    border-bottom: none;
  }
  .section + .section {
    margin-top: 40px;
  }

  .reason-line {
    display: flex;
    margin-bottom: 20px;
  }

  .reason-icon {
    margin-right: 10px;
  }

  .title {
    margin: 20px 0px;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.5px;
  }

  .reason.title {
    margin: 0px;
    margin-top: 5px;
  }

  .crms {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .crms.no-bottom-margin {
    margin-bottom: 0px;
  }

  .data-warehouse-selection {
    width: 100%;
    height: 40px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #2487ff;
    border-radius: 5px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }

  /* MOBILE LAYOUT */
  @media only screen and (max-width: 882px) {
    .form {
      margin: 20px 10px 0;
    }
  }
  @media only screen and (max-width: 372px) {
    .form {
      padding: 30px 10px;
    }
  }
</style>

<div class="form">
  <div class="section">
    {#each reasonSelections as reason}
      <div class="reason-line">
        <div class="reason-icon">
          <CircleIcon icon={iconLookup[reason]} size="small" />
        </div>
        <h4 class="reason title">{reason}</h4>
      </div>
    {/each}
  </div>
  <div class="section">
    <h4 class="title">TICKETING VENDOR</h4>
    <TicketingVendorLogo vendor={ticketingSelection} />
    <!-- </div>
  <div class="section"> -->
    <h4 class="title">CRM(S) USED IN DEVELOPMENT</h4>
    <div class={crmClasses}>
      {#each crms as crm}
        <CRMLogo {crm} />
      {/each}
    </div>
  </div>
  <div class="section">
    <h4 class="title">DATA WAREHOUSE</h4>
    <div class="data-warehouse-selection">
      <p class="dropdown-selection">{dataWarehouseSelection}</p>
    </div>
  </div>
</div>
